import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    Button,
    Form,
    Popconfirm,
    message
} from 'antd';
import { UPDATE_LABEL } from '../../mutations';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';

const ToggleLabel = ({ refetch, selectedIds, type }) => {
    const [visible, setVisible] = useState(false);
    const [updateLabel] = useMutation(UPDATE_LABEL, {
        onCompleted: (data) => {
            message.success(type === 'disable' ? 'ルート名を非表示しました。' : 'ルート名を表示しました。');
            form.resetFields();
            refetch();
            setVisible(false);
        },
        onError: (error) => {
            console.log(error);
            setVisible(false);
        }
    });

    const handleClick = () => {
        selectedIds.forEach(id => {
            updateLabel({
                variables: {
                    input: {
                        id: id,
                        enabled: type === 'enable'
                    }
                }
            });
        });
    };

    const [form] = Form.useForm();

    return <>
        <Popconfirm
            title={ type === 'disable' ? '選択したルートを非表示にしてよろしいですか': '選択したルートを表示にしてよろしいですか'}
            visible={visible}
            onConfirm={handleClick}
            onCancel={() => setVisible(false)}
        >
            <Button
                onClick={() => setVisible(true)}
                icon={type === 'enable' ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
            >
                { type === 'enable' && '表示する' }
                { type === 'disable' && '非表示にする' }
            </Button>
        </Popconfirm>
    </>

};

export default ToggleLabel;
