import React from 'react';
import {
    Button,
} from 'antd';
import {
    EditOutlined
} from '@ant-design/icons';

const EditLabelingButton = ({ selectedRoutes, rows, setRows }) => {
    const handleClick = () => {
        setRows(rows.map(row => {
            if (selectedRoutes.includes(row.id)) {
                row.manualLabelFlag = false
            }
            return row;
        }));
    };

    return <Button disabled={selectedRoutes.length === 0} onClick={handleClick} icon={<EditOutlined />}>ラベルを編集</Button>
};

export default EditLabelingButton;
