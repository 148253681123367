import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Button,
    Card,
    Form,
    Divider,
    Space,
    Select,
    Row,
    Col,
    Typography,
    Table,
    Popconfirm,
    Progress,
    message
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { GET_STATIONS, GET_SCENARIOS, GET_SIMULATIONS } from '../../queries';
import { CREATE_SIMULATION, DELETE_SIMULATIONS } from '../../mutations';
import momentify from '../../Utilities/momentify';
import SimulationDetail from './SimulationDetail';
import moment from 'moment';

const Simulation = () => {
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const { data: scenariosData } = useQuery(GET_SCENARIOS, { fetchPolicy: 'no-cache' });
    const { data: simulationsData, refetch } = useQuery(GET_SIMULATIONS, { fetchPolicy: 'no-cache' });
    const [createSimulation] = useMutation(CREATE_SIMULATION);
    const [deleteSimulation] = useMutation(DELETE_SIMULATIONS);
    const [rows, setRows] = useState([]);
    const [form] = Form.useForm();
    const [stations, setStations] = useState([null]);
    const [scenarios, setScenarios] = useState([null]);
    const [situationCount, setSituationCount] = useState(1);
    const [showSimulationDetail, setShowSimulationDetail] = useState(false);
    const [presignedDownloadUrl, setPresignedDownloadUrl] = useState('');
    const [selectedSimulation, setSelectedSimulation] = useState({});

    useEffect(() => {

        const updateRows = () => {
            if (simulationsData) {
                const updatedRows = simulationsData.simulations.edges.map((simulation) => {
                    const { id, stationIds, scenarioIds, createdAt, presignedDownloadUrl, progress} = simulation.node;
                    return {
                        id,
                        key: id,
                        station: stationIds,
                        scenario: scenarioIds,
                        createdAt: createdAt,
                        presignedDownloadUrl: presignedDownloadUrl,
                        progress: progress
                    }
                }).sort((a, b) => {
                    return moment(b.createdAt) - moment(a.createdAt);
                })
                setRows(updatedRows);
            }
        };

        // Update rows initially
        updateRows()

        // Set up interval to refetch data every 5 seconds
        const intervalId = setInterval(() => {
            refetch(); // Refetch data every 5 seconds
            updateRows(); // Update rows based on the latest data
        }, 5000);

        // Clean up interval on component unmount
        return () => {
            clearInterval(intervalId);
        };

    }, [refetch, simulationsData])

    const handleExecuteSimulation = () => {
        createSimulation({
            variables: {
                input: {
                    stationIds: stations,
                    scenarioIds: scenarios
                }
            }
        }).then((data) => {
            message.success('シミュレーションを実行しました。処理が完了するまでしばらくお待ちください。');
            setStations([]);
            setScenarios([]);
            refetch()
        }
        ).catch((error) => {
            message.error('シミュレーションの実行に失敗しました。');
            setStations([]);
            setScenarios([]);
            console.error(error);
        })

    }

    const columns = [
        {
            title: <Typography.Text strong>日付</Typography.Text>,
            dataIndex: 'createdAt',
            render: (createdAt) => {
                return (
                    <Typography.Text>
                        {momentify(createdAt)}
                    </Typography.Text>
                )
            }
        },
        {
            title: <Typography.Text data-testid='table-col-station' strong>営業所</Typography.Text>,
            dataIndex: 'station',
            render: (station) => {
                return (<>
                            {station.map((s, i) => {
                                const station = stationsData?.stations?.edges?.find(station => parseInt(station.node.id) === parseInt(s));
                                return (<Typography.Text key={i}> 
                                    {station ? station.node.officialName : ''}
                                    <br/>
                                </Typography.Text>)
                            }
                        )}
                    </>
                )
            }
        },
        {
            title: <Typography.Text data-testid='table-col-scenario' strong>シナリオ</Typography.Text>,
            dataIndex: 'scenario',
            render: (scenario) => {
                return (<>
                    {scenario.map((s, i) => {
                        const scenario = scenariosData?.scenarios?.edges?.find(scenario => parseInt(scenario.node.id) === parseInt(s));
                            return <Typography.Text key={i}>{scenario ? scenario.node.name : ''}<br/></Typography.Text>
                        }
                    )}
                </>)
                        
            }
        },
        {
            title: <Typography.Text data-testid='table-col-status' strong>シミュレーション結果</Typography.Text>,
            dataIndex: 'presignedDownloadUrl',
            render: (presignedDownloadUrl, all) => {
                return presignedDownloadUrl ? (
                    <Button
                        type='link'
                        onClick={() => {
                            setShowSimulationDetail(true);
                            setSelectedSimulation(all)
                            setPresignedDownloadUrl(presignedDownloadUrl)
                        }}
                    >
                        スケジュールを表示する
                    </Button>
                ) : (
                    <Progress percent={all.progress} steps={8} status={"active"} />
                )
            }
        },
        {
            title: <Typography.Text></Typography.Text>,
            render: (record) => {
                return (
                    <Popconfirm
                        title='本当に削除しますか？'
                        okText='削除'
                        cancelText='キャンセル'
                        onConfirm={() => {
                            deleteSimulation({
                                variables: {
                                    input: {
                                        id: record.id
                                    }
                                }
                            }).then((data) => {
                                message.success('シミュレーションを削除しました。');
                                refetch()
                            }
                            ).catch((error) => {
                                message.error('シミュレーションの削除に失敗しました。');
                                console.error(error);
                            })
                        }}
                    >
                        <Button danger type='text' >削除</Button>
                        
                    </Popconfirm>
                )
            }
        }
    ]

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card bordered={false}>
                <Form 
                    style={{ width: '60vw' }}
                    form={form} 
                    layout='inline'
                    size='large'
                    onFinish={
                        () => {
                            handleExecuteSimulation(stations, scenarios);
                            form.resetFields();
                        }
                    }
                >
                    <Row gutter={[16,8]}>
                            {[...Array(situationCount)].map((_, index) => {
                                return (
                                    <Row key={index} data-testid='simulation-row'>
                                        <Col key={`station-${index}`} >
                                            <Form.Item
                                                name={`station-${index}`}
                                                label="営業所"
                                                value={stations[index]}
                                                rules={[{ required: true, message: '営業所を選択してください' }]}
                                                disabled={loadingStations}
                                            >
                                                <Select
                                                    data-testid={`station-input-${index}`}
                                                    showSearch
                                                    placeholder="選択してください"
                                                    style={{ width: 200 }}
                                                    onChange={e => {
                                                        setStations(prev => {
                                                            const newStations = [...prev];
                                                            newStations[index] = e;
                                                            return newStations;
                                                        });
                                                    }}
                                                >
                                                    {
                                                        stationsData?.stations?.edges?.map(
                                                            station => (
                                                                <Select.Option key={station.node.id} value={parseInt(station.node.id)} data-testid={`station-option`}>
                                                                    {station.node.officialName}
                                                                </Select.Option>
                                                            )
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col key={`scenario-${index}`}>
                                            <Form.Item
                                                data-testid={`scenario-input-${index}`}
                                                name={`scenario-${index}`}
                                                label="シナリオ"
                                                value={scenarios[index]}
                                                rules={[{ required: true, message: 'シナリオを選択してください' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="選択してください"
                                                    style={{ width: 200 }}
                                                    disabled={!scenarios}
                                                    onChange={e => {
                                                        setScenarios(prev => {
                                                            const newScenarios = [...prev];
                                                            newScenarios[index] = e;
                                                            return newScenarios;
                                                        });
                                                    }}
                                                >
                                                    {
                                                        scenariosData?.scenarios?.edges?.filter(s => !s.node.station || parseInt(s.node.station.id) === stations[index]).map(
                                                            scenario => (
                                                                <Select.Option key={scenario.node.id} value={parseInt(scenario.node.id)} data-testid='scenario-option'>
                                                                    {scenario.node.name}
                                                                </Select.Option>
                                                            )
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col key={`button${index}`}>
                                            <Button
                                                type="text"
                                                icon={<MinusCircleOutlined />}
                                                onClick={() => {
                                                    if (situationCount === 1) {
                                                        return;
                                                    }
                                                    setSituationCount(situationCount - 1);
                                                    const newStation = stations.filter((_, i) => i !== index)
                                                    const newScenario = scenarios.filter((_, i) => i !== index)
                                                    stations.forEach((_, i) => {
                                                        newStation[i] ? form.setFieldsValue({[`station-${i}`]: newStation[i]}) : form.resetFields([`station-${i}`])
                                                        newScenario[i] ? form.setFieldsValue({[`scenario-${i}`]: newScenario[i]}) : form.resetFields([`scenario-${i}`])
                                                    })
                                                    setScenarios(newScenario);
                                                    setStations(newStation);
                                                }}
                                            />
                                        </Col>
                                    </Row>  
                                )}
                            )}
                        <Col>
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    setSituationCount(situationCount + 1);
                                    setStations([...stations, null]);
                                    setScenarios([...scenarios, null]);
                                }}
                            >
                                追加
                            </Button>
                        </Col>
                        <Col>
                            <Form.Item shouldUpdate>
                                {
                                    ()=> 
                                    <Button 
                                        htmlType='submit'
                                        type='primary'
                                        disabled={
                                            !form.getFieldValue(`station-${situationCount - 1}`) ||
                                            !form.getFieldValue(`scenario-${situationCount - 1}`)
                                        }
                                    >
                                        実行
                                    </Button>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Divider style={{ margin: 0, padding: 0 }}/>
            <Card bordered={false} style={{padding: '0px 24px 0px 24px'  }}>
                {showSimulationDetail ?
                    <SimulationDetail
                        setShowSimulationDetail={setShowSimulationDetail}
                        presignedDownloadUrl={presignedDownloadUrl}
                        stationsData={stationsData}
                        selectedSimulation={selectedSimulation}
                        scenariosData={scenariosData}
                    /> 
                    :
                    <Table
                        columns={columns}
                        dataSource={rows}
                        pagination={false}
                        bordered
                    />
                }
            </Card>
        </Space>
    )
}

export default Simulation