import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Card, Spin, Form, Input, Button, Col, Row } from 'antd';
import { About } from './Documents/Documents';
import { ASK_QUESION } from '../../queries';

const AskQuestion = () => {
    const [input, setInput] = useState("");
    const [msgHistory, setMsgHistory] = useState([{role: "assistant", content: "質問を入力して下さい。例えば「トラックの運行状況を確認する方法を教えて」と入力して下さい。"}]);
    const [askAi, {data: answer, loading: loadingAnswer }] = useLazyQuery(ASK_QUESION);
    useEffect(() => {
        if (!loadingAnswer && answer) {
            if (answer?.askQuestion?.text?.error) return
            const content = answer?.askQuestion?.choices[0]?.message?.content
            
            setMsgHistory(prev => [...prev, {role: "assistant", content}])
            setInput("")
        }
    }, [loadingAnswer, answer])
    const onSubmit = () => {
        if (!input.length > 0) {
            return
        }
        setMsgHistory(prev => [...prev, {role: "user", content: input }])
        setInput("")
        askAi({   
                variables: { 
                    messages: JSON.stringify([
                        {role: "system", content: "以下の情報を元に回答して下さい: " + About},
                        ...msgHistory,
                        {role: "user", content: input }
                    ])
                }
            })
    }

    return (
        <Card title='AIに質問'>
            <Form onFinish={onSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
                {msgHistory.map(msg => {
                    const messageStyle = {
                        display: 'inline-block',
                        maxWidth: '80%',
                        padding: '10px',
                        borderRadius: '10px',
                        marginBottom: '10px',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    };

                    const userStyle = {
                        ...messageStyle,
                        backgroundColor: '#007bff',
                        float: 'right',
                        textAlign: 'right'
                    };

                    const assistantStyle = {
                        ...messageStyle,
                        backgroundColor: '#28a745',
                        float: 'left',
                        textAlign: 'left'

                    };

                    return (
                        <Row>
                            <Col span={24}>
                                <div key={msg.id} style={msg.role === 'user' ? userStyle : assistantStyle}>
                                    {msg.role === 'assistant' && <div>アシスタント</div>}
                                    <div>{msg.content}</div>
                                </div>
                            </Col>
                        </Row>

                    );
                })}
            </div>

                {loadingAnswer && <Spin/>}
                <div style={{ display: 'flex'}}>
                    <Input
                        type="text"
                        value={input}
                        onChange={event => setInput(event.target.value)}
                        disabled={loadingAnswer}
                        />
                    <Button type="primary" htmlType='submit' disabled={loadingAnswer}>送信</Button>
                </div>
            </Form>
        </Card>
    );
}

export default AskQuestion;