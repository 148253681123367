import React, { useState, useEffect } from "react";
import {
    Card,
    Typography,
    Table,
    Space,
    DatePicker,
    Row,
    Col,
    Tag,
    Select,
    Radio,
    Input,
} from "antd";
import { useQuery } from "@apollo/client";
import { GET_TRUCK_STATS } from "../../queries";
import { useStickyState } from "../../Utilities/useStickyState";
import { useParams } from "react-router-dom";

import * as moment from "moment";

const TruckStats = () => {
    const {
        referenceMonth: paramsMonth,
        dowFilter: paramsDow,
        station: paramsStation,
        threshold: paramsThresh,
    } = useParams();
    const [referenceMonth, setReferenceMonth] = useState( paramsMonth || moment().format("YYYY-MM"));
    const [dowFilter, setDowFilter] = useStickyState( "all", "truckStatsDowFilter", paramsDow);
    const [selectedStation, setSelectedStation] = useStickyState( "", "truckStatsSelectedStation", paramsStation);
    const [thresholdPercent, setThresholdPercent] = useStickyState( 90, "truckStatsThresholdPercent", paramsThresh);

    const [stations, setStations] = useState([]);
    const { data: truckStats, loading } = useQuery(GET_TRUCK_STATS, {
        variables: { referenceMonth: referenceMonth },
    });
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);

    useEffect(() => {
        if (!loading) {
            const filteredRows = originalRows.filter((row) => {
                return (
                    row.dowFilter === dowFilter &&
                    row.yearmonth === referenceMonth &&
                    row.station.includes(selectedStation) &&
                    row.emptyPercentage >= thresholdPercent / 100
                );
            });
            setRows(filteredRows);
        }
    }, [ referenceMonth, dowFilter, truckStats, loading, originalRows, selectedStation, thresholdPercent, ]);

    useEffect(() => {
        if (truckStats) {
            const stats = JSON.parse(truckStats.truckStats);

            const allTrucks = stats
                .map((truck) => ({
                    key: truck.vehicle_id + truck.yearmonth + truck.station_name + truck.dow_filter,
                    id: truck.vehicle_id,
                    yearmonth: truck.yearmonth,
                    dowFilter: truck.dow_filter,
                    station: truck.station_name,
                    vehicleId: truck.vehicle_id,
                    emptyDays: truck.empty_days,
                    operationalDays: truck.operational_days,
                    emptyPercentage: truck.empty_percentage,
                }))
                .sort((a, b) => a.vehicleId - b.vehicleId);

            const stations = [ ...new Set(allTrucks.map((truck) => truck.station)) ];

            setStations(stations);
            setRows(allTrucks);
            setOriginalRows(allTrucks);
        }
    }, [truckStats]);

    const columns = [
        {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: "vehicleId",
            sorter: (a, b) => a.vehicleId - b.vehicleId,
            width: 50,
        }, {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: "station",
            width: 100,
        }, {
            title: <Typography.Text strong>年月</Typography.Text>,
            dataIndex: "yearmonth",
            sorter: (a, b) =>
                a.yearmonth.replace("-", "") - b.yearmonth.replace("-", ""),
            width: 50,
        }, {
            title: <Typography.Text strong>曜日</Typography.Text>,
            dataIndex: "dowFilter",
            render: (text, record) => {
                return text === "all" ? (
                    <Tag>全て</Tag>
                ) : (
                    <Tag color="blue">平日のみ</Tag>
                );
            },
            width: 50,
        }, {
            title: <Typography.Text strong>空車日数</Typography.Text>,
            dataIndex: "emptyDays",
            align: "right",
            sorter: (a, b) => a.emptyDays - b.emptyDays,
            width: 50,
        }, {
            title: <Typography.Text strong>運行可能日数</Typography.Text>,
            dataIndex: "operationalDays",
            align: "right",
            sorter: (a, b) => a.operationalDays - b.operationalDays,
            width: 50,
        }, {
            title: <Typography.Text strong>空車率</Typography.Text>,
            dataIndex: "emptyPercentage",
            render: (text, record) => (
                <Typography.Text>{(text * 100).toFixed(2)}%</Typography.Text>
            ),
            align: "right",
            sorter: (a, b) => a.emptyPercentage - b.emptyPercentage,
            width: 50,
        },
    ];

    return (
        <>
            <Space size={24} direction="vertical" style={{ width: "100%" }}>
                <Card title={ <Typography.Title level={3}> トラック空車率 </Typography.Title> } >
                    <Row gutter={[16, 8]}>
                        <Col>
                            <DatePicker
                                onChange={v => setReferenceMonth(v?.format("YYYY-MM")) }
                                picker="month"
                                defaultValue={moment(referenceMonth, "YYYY-MM")}
                                disabledDate={(current) => { return ( current && current > moment().endOf("day")); }}
                            />
                        </Col>
                        <Col>
                            <Select
                                showSearch
                                allowClear
                                defaultValue={selectedStation}
                                placeholder="選択してください"
                                onChange={(e) => setSelectedStation(e || "")}
                                style={{ width: 200 }}
                            >
                                {stations.map((station) => (
                                    <Select.Option
                                        key={station}
                                        value={station}
                                    >
                                        {station}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <Radio.Group
                                onChange={(e) => setDowFilter(e.target.value)}
                                defaultValue={dowFilter}
                            >
                                <Radio.Button value="all">全て</Radio.Button>
                                <Radio.Button value="weekday">平日</Radio.Button>
                            </Radio.Group>
                        </Col>
                        <Col>
                            <Input
                                type="number"
                                min="0"
                                max="100"
                                defaultValue={thresholdPercent}
                                onChange={e => setThresholdPercent(parseFloat(e.target.value)) }
                                prefix="空車率"
                                suffix="%"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography.Title
                                style={{ marginLeft: "4px" }}
                                level={5}
                            >
                                {rows.length}台　表示中
                            </Typography.Title>
                        </Col>
                    </Row>
                </Card>
                <Table
                    columns={columns}
                    style={{ padding: "24px", background: "#fff" }}
                    dataSource={rows}
                />
            </Space>
        </>
    );
};

export default TruckStats;
