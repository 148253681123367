import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button, message } from 'antd';
import { DELETE_STATIONS } from '../../mutations';
import propTypes from 'prop-types';

const DeleteStationsButton = ({ selectedStationIds, handleCompleted }) => {
    const [deleteStations] = useMutation(DELETE_STATIONS, {
        onCompleted: (data) => handleCompleted(data.deleteStations.stations),
        onError: (error) => message.error("削除に失敗しました。")
    });
    const [visible, setVisible] = useState(false);

    return <Popconfirm
        title='選択した会社を削除してよろしいですか。'
        onConfirm={() => {
            deleteStations({ variables: { input: { ids: selectedStationIds } } })
            setVisible(false);
        }}
        open={visible}
        okText={'削除する'}
        onCancel={() => setVisible(false)}
    >
        <Button
            onClick={() => setVisible(true)}
            icon={<DeleteOutlined/>}>
            削除
        </Button>
    </Popconfirm>
};

DeleteStationsButton.propTypes = {
    handleCompleted: propTypes.func,
    selectedStationIds: propTypes.array
};

export default DeleteStationsButton;
