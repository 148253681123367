import React from 'react';
import { Table, Typography } from 'antd'

const SimulationStats = ({ stats }) => {
    const columns = [
        {
            title: '日付',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '自営業所で運行した台数',
            dataIndex: 'normalRoutes',
            key: 'normalRoutes',
            render: (text, record) => {
                return <>{record.normalRoutes || 0}</>
            }
        },
        {
            title: '他営業所で運行した台数',
            dataIndex: 'lendRoutes',
            key: 'lendRoutes',
            render: (text, record) => {
                return <>{record.lendRoutes || 0}</>
            }
        },
        {
            title: '空きトラック台数（シュミレーション）',
            dataIndex: 'emptyTruckSimulated',
            key: 'emptyTruckSimulated',
            render: (text, record) => {
                return <>{record.emptyTruckSimulated || 0}</>
            }
        },
        {
            title: '空きトラック台数（スケジュール）',
            dataIndex: 'emptyTruckScheduled',
            key: 'emptyTruckScheduled',
            render: (text, record) => {
                return <>{record.emptyTruckScheduled || 0}</>
            }

        }
    ];
    return (
        <>
            {
                stats && Object.entries(stats).map(([titleStr, obj]) => {
                    return <>
                        <Typography.Title style={{marginTop: '1rem'}} level={5}>{titleStr.split('-')[0]}　{titleStr.split('-')[1]}</Typography.Title>
                        <Table dataSource={obj} columns={columns} />
                    </>
                
                })
            }
        </>

    );
};

export default SimulationStats;