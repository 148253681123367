import React from 'react';
import { Tag } from 'antd';

export const taggify = (rowContent) => {
    let result;
    switch(rowContent) {
        //作成中 → 投稿中 →  依頼済み → 配達完了
        //draft -> posted -> requested -> delivered
        case 'draft':
            result = <Tag color='orange'>作成中</Tag>
            break;
        case 'posted':
            result = <Tag color='blue'>投稿中</Tag>
            break;
        case 'requested':
            result = <Tag color='green'>依頼済み</Tag>
            break;
        case 'delivered':
            result = <Tag color='purple'>配達完了</Tag>
            break;
        case 'archived':
            result = <Tag color='gray'>削除済み</Tag>
            break;
        default:
            break;
    }
    return result;
}
