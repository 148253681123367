import React from 'react';
import moment from 'moment'
import { Popover } from 'antd';
const momentify = datetime => <Popover
    content={new Date(datetime).toLocaleDateString(
        'ja-JP',
        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
    >
    { moment(datetime).locale('ja').format("YYYY-MM-DD") }
</Popover>;

export default momentify