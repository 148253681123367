import React from 'react';
import {
    Typography,
    Table,
    Spin,
    Card,
    Button,
    Empty
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import {  GET_ARCHIVED_FORECASTS } from '../../queries';
import { UPDATE_ROUTES_FORECAST } from '../../mutations';
import momentify from '../../Utilities/momentify';
import moment from 'moment'

const ArchivedForecasts = () => {


    const { data, refetch, error } = useQuery(GET_ARCHIVED_FORECASTS, {
        fetchPolicy: 'network-only',
    });

    const [restoreForecast] = useMutation(UPDATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            refetch()
        }
    })

    const handleRestore = (id) => {
		restoreForecast({
			variables: {
				input: {
					id: id,
					archived: false,
				},
			},
		});
	};

    const columns = [
        {
            title: <Typography.Text strong>運行日</Typography.Text>,
            dataIndex: 'opsDate',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: text => momentify(text)
        },
        {
            title: <Typography.Text strong>出発時刻</Typography.Text>,
            dataIndex: 'departureTime',
            render: text => moment(text).format('MM-DD HH:mm')
        },
        {
            title: <Typography.Text strong>到着時刻</Typography.Text>,
            dataIndex: 'arrivalTime',
            render: text => moment(text).format('MM-DD HH:mm')
        },
        {
            title: <Typography.Text strong>移動先</Typography.Text>,
            dataIndex: 'station',
            render: text => text.officialName
        }, {
            title: <Typography.Text strong>車種名</Typography.Text>,
            dataIndex: 'vehicleType',
            sorter: (a, b) => a.vehicleType && b.vehicleType && a.vehicleType.localeCompare(b.vehicleType, 'ja'),
        }, {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: 'vehicleId',
        }, {
            title: <Typography.Text strong>積載重量</Typography.Text>,
            dataIndex: 'maxTruckCapacity',
            sorter: (a, b) => a.maxTruckCapacity - b.maxTruckCapacity,
        }, {
            title: <Typography.Text strong>免許タイプ</Typography.Text>,
            dataIndex: 'driverLicenseClass',
            sorter: (a, b) => a.driverLicenseClass - b.driverLicenseClass,
        },
        {
            title: <Typography.Text strong>削除者</Typography.Text>,
            dataIndex: 'archivedBy',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: rowContent => rowContent && rowContent.lastName + ' ' + rowContent.firstName,
        },
        {
            title: <Typography.Text strong>削除日</Typography.Text>,
            dataIndex: 'archivedAt',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: rowContent => momentify(rowContent),
        },
        {
            title: <Typography.Text strong>復元</Typography.Text>,
            render: (text, record) => (
                <Button type='link' onClick={()=> handleRestore(record.id)}>restore</Button>
            )
        }
        
    ]

    if (error) {
        return <Empty style={{marginTop: "20vh"}}/>
    }

    if (!data) {
        return <Spin/>;
    }

    return <>
        <Card bordered={false} style={{marginTop: "1rem"}}>
            <Table
                columns={columns}
                style={{ padding: '0px 24px', background: '#fff' }}
                dataSource={data.archivedForecasts}
                scroll={{ x: 1300 }}
            />
        </Card>
    </>
};

export default ArchivedForecasts;
