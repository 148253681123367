import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const StationMap = ({ loadingElement, googleMapURL, mapElement, containerElement, stationData }) => {
    const mapProps = {
        center: {
            lat: 35.683229,
            lng: 139.771267
        },
        zoom: 9
    };

    return <GoogleMap
        bootstrapURLKeys={{ key: 'AIzaSyCFBoO1YyhEsPgDXAl5nuh_8kdUyLMO28w' }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
    >
        {
            stationData.stations.edges.map(
                station =>
                    <Marker
                        key={station.node.officialName}
                        position={
                            {
                                lat: station.node.longtitude,
                                lng: station.node.latitude
                            }
                        }
                        label={station.node.officialName}
                    />
            )
        }
    </GoogleMap>
};

export default withScriptjs(withGoogleMap(StationMap));
