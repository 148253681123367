import React from 'react';
import {
    Button,
    message,
} from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_REQUEST } from '../../mutations';

const UpdateRequestButton = ({ id, status, content, refetch, disabled }) => {
    const [updateRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (data) => {
            if (data.updateRequest.request.status === status) {
                refetch()
                message.success('リクエストが更新されました');
            } else {
                message.error('リクエストが更新されませんでした');
            }
        },
        onError: (error) => { console.log(error); }
    });

    return <Button type="primary" disabled={disabled} danger={content==="拒否"} onClick={() => updateRequest({ variables: { input: { id, status }}})} >{content} </Button>
    
};

export default UpdateRequestButton;
