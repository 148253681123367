import React, { useEffect } from 'react';
import { PATHS } from '../../constants';
import { NavLink } from 'react-router-dom';
import {Typography,
        Row,
        Col,
        Button,
        Alert
 } from 'antd';
import {
    CarOutlined,
    ShoppingOutlined,
    NotificationOutlined
} from '@ant-design/icons';
import { isMobile } from '../../Utilities/isMobile';
import { GET_REQUEST_NOTIFICATIONS } from '../../queries';
import { useQuery } from '@apollo/client';

const Home = () => {
    const { data: notificationData } = useQuery(GET_REQUEST_NOTIFICATIONS);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const MobileTopMenu = () => {
        return (<><Typography.Title level={5} style={{ margin: '1rem'}}>メニュー</Typography.Title>
        <Row className="mobile-home-menu">
            <Col span={24} style={{width: '90%'}}>
                <NavLink to={PATHS.truckPosting.split('/')[1]}><Button size='large' className="mobile-menu-list" icon={<CarOutlined />}>空車掲示板</Button></NavLink>
            </Col>
            <Col span={24}>
                <NavLink to={PATHS.freightPosting.split('/')[1]}><Button size='large' className="mobile-menu-list" icon={<ShoppingOutlined />}>荷物掲示板</Button></NavLink>
            </Col>
            <Col span={24}>
                <NavLink to={`/${PATHS.requestsList.split('/')[1]}`}><Button size='large' className="mobile-menu-list" icon={<NotificationOutlined />}>リクエスト一覧</Button></NavLink>
            </Col>
        </Row>
        </>);
    }
    const MobileNotifications = () => {
        return (<><Typography.Title level={5} style={{ margin: '1rem'}}>リクエスト通知</Typography.Title>
        <Row className="mobile-home-menu">
            <Col span={24}>
                {notificationData?.requestNotifications.length > 0 ? notificationData?.requestNotifications?.map(n => {
                    return <Alert message={`${n.sender?.lastName} ${n.sender?.firstName}さんからリクエストが来ています。`} type="info" showIcon style={{marginBottom: '.5rem'}}/>
                })
                : <Alert message="新しいリクエストはありません。" type="info" showIcon />}
            </Col>
        </Row>
        </>);
    }

    return (
        <>
            {isMobile() ? <MobileTopMenu/> : null}
            {isMobile() ? <MobileNotifications/> : null}
        </>
    );
}

export default Home;