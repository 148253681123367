import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";
import { Card } from "antd";

const Tutorial = () => {
    return (
        <div>
            <Card title="ダッシュボード" bordered={false} style={{margin: '1rem'}}>
                <Card title="ダッシュボードの確認方法" bordered={false}>
                    <YoutubeEmbed embedId="a1MQQGzgpiU" />
                </Card>
            </Card>
            <Card title="運行スケジュール" bordered={false} style={{margin: '1rem'}}>
                <Card title="運行スケジュールの確認方法" bordered={false}>
                    <YoutubeEmbed embedId="Gr_woqzCSEA" />
                </Card>
                <Card title="割付無しルートへのトラック割付" bordered={false}>
                    <YoutubeEmbed embedId="a790dE5sfSo" />
                </Card>
                <Card title="運行スケジュールから検索　新規リクエスト" bordered={false}>
                    <YoutubeEmbed embedId="XKw2x6YiakI" />
                </Card>
            </Card>
            <Card title="空きトラック検索" bordered={false} style={{margin: '1rem'}}>
                <Card title="空きトラック検索方法の説明" bordered={false}>
                    <YoutubeEmbed embedId="zkNgDFfh_fc" />
                </Card>
                <Card title="他営業所へのリクエスト送信" bordered={false}>
                    <YoutubeEmbed embedId="Qg1fxO9H8hM" />
                </Card>
            </Card>
            <Card title="空きトラック掲示板" bordered={false} style={{margin: '1rem'}}>
                <Card title="空きトラック掲示板へのポスト" bordered={false}>
                    <YoutubeEmbed embedId="R_CKrVWfLHg" />
                </Card>
                <Card title="空きトラック掲示板からのリクエスト" bordered={false}>
                    <YoutubeEmbed embedId="o1R7sbsiheA" />
                </Card>
            </Card>
            <Card title="リクエスト一覧" bordered={false} style={{margin: '1rem'}}>
                <Card title="リクエスト一覧の操作" bordered={false}>
                    <YoutubeEmbed embedId="cp7rOpjCUBM" />
                </Card>
            </Card>
            <Card title="ルート管理" bordered={false} style={{margin: '1rem'}}>
                <Card title="ルート名追加_表示、非表示の切り替え" bordered={false}>
                    <YoutubeEmbed embedId="jPVW9g7Y6nk" />
                </Card>
                <Card title="固定ルートの追加と削除" bordered={false}>
                    <YoutubeEmbed embedId="WA4rIZW9vtw" />
                </Card>
                <Card title="固定ルートの編集" bordered={false}>
                    <YoutubeEmbed embedId="8C8PladxN-U" />
                </Card>
                <Card title="シナリオの追加・複製・削除" bordered={false}>
                    <YoutubeEmbed embedId="riY1rnXWzr8" />
                </Card>
            </Card>
        </div>
    );
};

export default Tutorial;
