import React from 'react';
import { Typography, Form, Input, Button, Col } from 'antd';
import { UserOutlined} from '@ant-design/icons';
import { authenticationService } from '../../Utilities/authenticationService';
import './login.less';

const ForgotPassword = ({ history, location }) => {
    const { Title } = Typography;

    const onFinish = ({ email }) => {
        authenticationService.forgot_password(email).then(
            (res) => {
               history.push('/login')
            },
            (err) => {
                console.log(`Reset password error: ${err}`);
            }
        );
    }

    const [form] = Form.useForm();

    return (
        <div className='login-layout'>
            <Col span={4} className='login-wrapper'>
                <Title level={4}>パスワード再設定</Title>
                <Form form={form} name="resetPassword" onFinish={onFinish} initialValues={{ remember: true }}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'ユーザーIDを入力してください。'
                            }
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="ユーザーID" />
                    </Form.Item>
                    
                    <Form.Item shouldUpdate>
                        { () => (
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                disabled={
                                    !form.isFieldTouched('email')}
                            >
                                        送信
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Col>
        </div>
    );
};



export default ForgotPassword;
