import React, { useState } from 'react';
import { Alert, Button, Descriptions, Spin } from 'antd';
import PasswordChangeForm from './PasswordChangeForm';
import { useQuery } from '@apollo/client';
import { GET_USER } from '../../queries';

const AccountInformation = () => {
    const [passwordChangeForm, setPasswordChangeForm] = useState(false);
    const [confirmedPasswordChanged, setConfirmedPasswordChanged] = useState(false);
    const { data, loading } = useQuery(GET_USER, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return <Spin />;

    const fullName = `${data?.currentUser?.lastName} ${data?.currentUser?.firstName}`;
    const email = data?.currentUser?.email;
    const isConstructor = data?.currentUser?.company ? true : false;

    const returnToAccountInfo = () => {
        setPasswordChangeForm(false);
    };

    return <>
        { confirmedPasswordChanged &&
            <Alert message={'パスワードを変更しました。'} type='success' showIcon closable />
        }
        { passwordChangeForm ?
            <PasswordChangeForm handleCompleted={() => {
                    setPasswordChangeForm(false)
                    setConfirmedPasswordChanged(true);
                 }}
                 returnToAccountInfo={returnToAccountInfo}
            /> :
            <>
                <Descriptions layout='vertical'>
                    <Descriptions.Item label='メール' span={3}>{ email }</Descriptions.Item>
                    <Descriptions.Item label='氏名' span={3}>{ fullName }</Descriptions.Item>
                    { isConstructor ?
                        <Descriptions.Item label='会社名' span={3}>{ data?.currentUser?.company?.companyName }</Descriptions.Item>
                        : <Descriptions.Item label='営業所名' span={3}>{ data?.currentUser?.station?.officialName }</Descriptions.Item>
                    }
                </Descriptions>
                <Button onClick={() => setPasswordChangeForm(true)} type="primary">パスワード変更</Button>
            </>
        }
    </>;
};

export default AccountInformation;
