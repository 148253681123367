export const departurePref = ['北海道','青森県','岩手県','宮城県','秋田県','山形県','福島県','茨城県','栃木県','群馬県','埼玉県','千葉県','東京都','神奈川県','新潟県','富山県','石川県','福井県','山梨県','長野県','岐阜県','静岡県','愛知県','三重県','滋賀県','京都府','大阪府','兵庫県','奈良県','和歌山県','鳥取県','島根県','岡山県','広島県','山口県','徳島県','香川県','愛媛県','高知県','福岡県','佐賀県','長崎県','熊本県','大分県','宮崎県','鹿児島県','沖縄県']

export const truckWeight = ['軽','1t','2t','3t','4t','5t','6t','7t','8t','9t','10t','11t','12t','13t','14t','15t','トレーラー','他']

export const truckModel = ['平','平-低床','平-パワーゲート','平-エアサス','箱','箱-低床','箱-パワーゲート','箱-エアサス','ウイング','ウイング-低床','ウイング-パワーゲート','ウイング-エアサス','ユニック','冷凍','保冷','他']

export const freightMode = ['パレット', 'その他']

export const freightType = ['ケース', 'フレコン', '引越', '建材', '建設機械', '機械', '部材', '鋼材', '木材', '骨材', '製材', '鉄筋', '鉄骨', '断熱材', '電線（ドラム）', '電線（バラ）', 'アルミ', 'アルミサッシ', 'ガラス', 'ガラス（板）', '板', '配電盤', 'パイプ', '金型', '装飾金物', '植木', '金属類', '化学薬品', 'ペットボトル', '事務機器', 'OA機器', '空調機', '製品（ガラス）', '製品（木）', '製品（プラスチック）', '部品', '部品（自動車）', '部品（精密）', '部品（電気）', '部品（電子）', '機材', '雑貨', '雑貨（日用雑貨）', '車', 'バイク', 'バッテリー', '印刷物', '書籍', '箱', '木箱', '木枠', '缶', '缶（一斗缶）', '缶（ドラム缶）', 'ファイバードラム', 'ダンボール', 'プラケース', '発泡スチロール', '袋', '紙袋', '什器', '家具', '家具（食器棚）', '家具（スチール）', '家具（たんす）', '家具（ピアノ）', '食品', '食品（飲料水）', '食品（ジュース）', '食品（鮮魚）', '食品（生鮮）', '食品（青果）', '食品（チルド）', '食品（肉）', '食品（冷蔵）', '食品（冷凍）', '酒類', '長尺物', '背高物', '特大品', '重量物', 'ボンベ', 'タンク', '棒', '粉', 'バラ']

export const nTrucks = [1,2,3,4,5,6,7,8,9,10]

export const highwayOptions = ['別途支払う', '支払わない']