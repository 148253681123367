import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CREATE_REQUEST, CREATE_ROUTES_FORECAST, UPDATE_REQUEST, CREATE_POSTING } from '../../mutations'
import { GET_LABELS, GET_USERS, GET_STATIONS } from '../../queries';
import { authenticationService } from '../../Utilities/authenticationService'
import { DatePickerWithTimeInForm } from '../../Utilities/datepickerWithTimeInForm';
import {
    Tabs,
    message,
    Select,
    Form,
    Input,
    Button,
    Drawer,
    InputNumber
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { isMobile } from '../../Utilities/isMobile';
import moment from 'moment';

const HeatmapRequestModal = ({ 
    visible, 
    setVisible,
    truck,
    vehicleType,
    maxTruckCapacity,
    driverLicenseClass,
    paramStationId,
    vehicleId,
    employeeId,
    employeeName,
    startTime,
    endTime,
    route,
    showNewRequestTab = true,
    showPostingTab = true,
    refetch
}) => {
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const [stationId, setStationId] = useState(paramStationId);
    const [autoUpdated, setAutoUpdated] = useState(false);
    const { data: usersData } = useQuery(GET_USERS);
    const [labelId, setLabelId] = useState(route || null);
    const [toId, setToId] = useState();
    const [userId, setUserId] = useState();
    const [parkedLoad, setParkedLoad] = useState();
    const [remarks, setRemarks] = useState();
    const [price, setPrice] = useState();
    
    const [getLabelsData, { data: labelsData }] = useLazyQuery(GET_LABELS);
    
    const currentUser = authenticationService.currentUserValue
    const [fromId, setFromId] = useState(String(currentUser?.station_id));

    const unmountSelf = () => {
        refetch && refetch()
        setVisible(false)
    }

    const [updateRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (data) => {
            if (data.updateRequest.request?.overlaps?.length > 0) {
                message.error('選択したトラックは、リクエストされた時間帯にすでにスケジュールされています。リクエストを承認できません。');
            } else {
                message.success(autoUpdated ? 'リクエストを自動承認しました。' : 'リクエストが更新されました')
            }
            setAutoUpdated(false)
            unmountSelf()
        },
        onError: (error) => { console.log(error); }
    });

    const [createRequest] = useMutation(CREATE_REQUEST, {
        onCompleted: (data) => {
            message.success('リクエストを送信しました。');
            if(truck && truck[vehicleId][0]?.stationId === currentUser.station_id && parseInt(toId) === currentUser.station_id && parseInt(userId) === currentUser.id) {
                setAutoUpdated(true);
                updateRequest({ variables: { input: { id: data.createRequest.request.id, status: 'accepted' }}})
            } else {
                unmountSelf()
            }
        },
        onError: (error) => {
            message.error('リクエストを送信できませんでした。');
        }
    });

    const [createRoutesForecast] = useMutation(CREATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            createRequest({ variables: { input: {
                opsDate: data.createRoutesForecast.routesForecast.opsDate,
                fromId: fromId,
                forecastId: data.createRoutesForecast.routesForecast.id,
                userId,
                remarks,
                toId,
                status: 'sent',
                truckTableId: truck ? truck[vehicleId][0]?.id : null,
                vehicleId: data.createRoutesForecast.routesForecast.vehicleId,
                postingId: null,
                price
            }}});
        },
        onError: (error) => {
            message.error('リクエストを送信できませんでした。');
        }
    });

    const [createPosting] = useMutation(CREATE_POSTING, {
        onCompleted: () => {
            message.success('空きトラック掲示板に投稿しました。');
            unmountSelf()
        },
        onError: (error) => {
            if (error?.networkError?.result?.errors[0]?.message?.includes('overlaps with another forecast')) {
                message.error('選択したトラックは、指定時間に運行スケジュールがあるため、掲示板に投稿できません。');
            } else if (error?.networkError?.result?.errors[0]?.message?.includes('overlaps with another posting')) {
                message.error('その車両は既にポストされています');
            } else {
                message.error('空きトラック掲示板に投稿できませんでした。');
            }
        }
    });
    const onNewPosting = ({ stationId, vehicleId, arrivalTime, departureTime }) => {
        setToId(toId);
        setUserId(userId)
        createPosting({ variables: { input: {
            vehicleId: vehicleId,
            stationId: stationId,
            opsDate: moment(arrivalTime).format('YYYY-MM-DD'),
            startDate: departureTime.format('YYYY-MM-DDTHH:mm:00.000Z'),
            endDate: arrivalTime.format('YYYY-MM-DDTHH:mm:00.000Z'),
            status: "available"
        }}});
    };

    const onNewRequest = ({ remarks, toId, userId, departureTime, arrivalTime, price, employeeId, employeeName,vehicleId }) => {
        setToId(toId);
        setUserId(userId);
        setRemarks(remarks);
        setPrice(price);
        createRoutesForecast({ variables: { input: {
            opsDate: moment(departureTime).format('YYYY-MM-DD'),
            stationId: fromId,
            vehicleStationId: paramStationId || stationId,
            vehicleId: vehicleId,
            maxTruckCapacity: maxTruckCapacity,
            driverLicenseClass: driverLicenseClass,
            vehicleType: vehicleType,
            remarks,
            labelId,
            parkedLoad,
            departureTime,
            arrivalTime,
            pending: true,
            employeeId,
            employeeName
        }}});
    };

    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        if (currentUser?.station_id) {
            getLabelsData({ variables: { stationId: fromId || currentUser?.station_id } })
        }
    }, [getLabelsData, currentUser, visible, fromId]);

    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 9,
            }
        }
    };
    const NewRequestForm = () => {
        return (
        <Form
            name='request'
            form={form}
            onFinish={onNewRequest}
            colon={false}
            layout="vertical"
        >
            <Form.Item
                label="依頼元営業所"
                name='fromId'
                initialValue={fromId}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    showSearch
                    disabled={!currentUser?.admin}
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    onChange={(e) => setFromId(e)}
                >
                    {
                        !loadingStations && stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="依頼先営業所"
                name='toId'
                initialValue={stationId}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    showSearch
                    disabled
                    placeholder="選択してください"
                    style={{ width: 200 }}
                >
                    {
                        !loadingStations && stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="承認者"
                name='userId'
                rules={[{ required: true, message: '送り先を入力してください。' }]}
            >
                { usersData && <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    filterOption={(input,option)=> option.children.join('').includes(input)}
                >
                    {
                        usersData.users.edges.filter(user => user.node.station?.id === stationId).map(
                            user => {
                                return(
                                <Select.Option key={user.node.id} value={user.node.id}>{user.node.lastName} {user.node.firstName}</Select.Option>
                            )}
                        )
                    }
                </Select> }
            </Form.Item>
            <Form.Item
                label="ルート"
                name='label'
                rules={[{ required: true, message: 'ルートを入力してください。' }]}
                initialValue={route?.toString() || null}
            >
                <Select
                    placeholder="選択してください"
                    showSearch
                    onChange={setLabelId}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        labelsData && labelsData.mostFrequentlyUsedLabels.map(
                            label => (
                                label.enabled &&
                                    <Select.Option key={label.id} value={label.id}>{label.label.join('|')}</Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="配送者ID"
                name="employeeId"
                initialValue={employeeId}
                rules={[{ required: false, message: '正しい配送者IDを入力してください。' }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="配送者名"
                name="employeeName"
                initialValue={employeeName}
                rules={[{ required: false, message: '配送者名を入力してください。' }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="運賃"
                name='price'
                rules={[{ required: false, message: '運賃を入力してください。' }]}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                label="車両番号"
                name="vehicleId"
                initialValue={vehicleId}
                rules={[{ required: true, message: '車両番号を入力してください。' }]}
            >
                <Input/>
            </Form.Item>
            <DatePickerWithTimeInForm initialTime={startTime} label="出発時刻" name='departureTime'/>
            <DatePickerWithTimeInForm initialTime={endTime} label="到着時刻" name='arrivalTime'/>
            <Form.Item
                label="荷有り"
                name='parkedLoad'
                rules={[{ required: true, message: '荷物の有無を入力してください。' }]}
            >
                <Select 
                    placeholder="選択してください" onChange={setParkedLoad}>
                    {
                        ["空", "荷あり"].map(parkedLoadType => (
                                <Select.Option key={parkedLoadType} value={parkedLoadType}>
                                    {parkedLoadType}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>

            <Form.Item
                label="備考欄"
                name='remarks'
                rules={[{ required: false }]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item shouldUpdate {...tailFormItemLayout}>
                { () => (
                    <Button
                        disabled={
                            stationId === undefined ||
                            labelId === undefined ||
                            parkedLoad === undefined ||
                            form.getFieldValue('userId') === undefined ||
                            !form.getFieldValue('vehicleId')
                        }
                        type="primary"
                        htmlType='submit'
                    >
                        送信する
                    </Button>
                )}
            </Form.Item>
        </Form>
    )}
    const PostingTruckForm = () => {
        return (
            <Form
            name='request'
            form={newForm}
            onFinish={onNewPosting}
            colon={false}
            layout="vertical"
        >
            <Form.Item
                label="営業所"
                name='stationId'
                initialValue={stationId}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    onChange={
                        selectedId => {
                            setStationId(selectedId)
                        }
                    }
                    disabled={stationId}
                >
                    {
                        !loadingStations && stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="車両番号"
                name="vehicleId"
                initialValue={vehicleId}
                style={{ width: 200 }}
                rules={[{ required: true, message: '車両番号を入力してください。' }]}
            >
                <Input disabled={vehicleId}/>
            </Form.Item>
            <DatePickerWithTimeInForm initialTime={startTime} label="出発時刻" name='departureTime'/>
            <DatePickerWithTimeInForm label="到着時刻" name='arrivalTime'/>
            {vehicleId && <>
                <Form.Item
                    label="車種名"
                    name="vehicleType"
                    initialValue={vehicleType}
                    rules={[{ required: false, message: '車種名を入力してください。' }]}
                >
                    <Input disabled={vehicleType}/>
                </Form.Item>
                <Form.Item
                    label="積載重量"
                    name="maxTruckCapacity"
                    initialValue={maxTruckCapacity}
                    rules={[{ required: false, message: '積載重量を入力してください。' }]}
                >
                    <Input disabled={maxTruckCapacity}/>
                </Form.Item>
                <Form.Item
                    label="免許タイプ"
                    name="driverLicenseClass"
                    initialValue={driverLicenseClass}
                    rules={[{ required: false, message: '免許タイプを入力してください。' }]}
                >
                    <Input disabled={driverLicenseClass}/>
                </Form.Item>
            </>}
            <Form.Item shouldUpdate {...tailFormItemLayout}>
                { () => (
                    <Button
                        type="primary"
                        htmlType='submit'
                        disabled={
                            !newForm.getFieldValue('arrivalTime')||
                            !newForm.getFieldValue('departureTime') ||
                            !newForm.getFieldValue('vehicleId')
                        }

                    >
                        送信する
                    </Button>
                )}
            </Form.Item>
        </Form>
    )}

    return <>
        <Drawer
            width={isMobile() ? '100vw' : 400}
            open={visible}
            footer={null}
            closable={false}
        >
            <div
                style={{ 
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    width: '100%',
                    marginBottom: '-20px'
                }}
            >
                <Button
                    type='text'
                    onClick={() => setVisible(false)}
                    style={{ zIndex: 1 }}
                >    
                    <CloseOutlined />
                </Button>
            </div>
            
            <Tabs items={[
                showNewRequestTab && {
                    label: '新規リクエスト作成',
                    key: 'new',
                    children: (<NewRequestForm />)
                },
                showPostingTab && {
                    label: '空車掲示板に投稿する',
                    key: 'existing',
                    children: (<PostingTruckForm />)
                }
            ]}>
            </Tabs>
        </Drawer>
    </>
};

export default HeatmapRequestModal;
