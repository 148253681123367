import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Descriptions, Modal } from 'antd';

const NewStationModal = ({ stationInfo }) => {
    const [visible, setVisible] = useState(true);
    return (
        <Modal
            title="追加された営業所"
            open={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <Descriptions layout='horizontal'>
                <Descriptions.Item label='営業所'>{stationInfo.stationName}</Descriptions.Item>
                <Descriptions.Item label='営業所(official)'>{stationInfo.officialName}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

NewStationModal.propTypes = {
    stationInfo: propTypes.object
};

export default NewStationModal;
