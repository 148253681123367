import React from 'react';

export const dowDictionary = {
    0: '日曜日',
    1: '月曜日',
    2: '火曜日',
    3: '水曜日',
    4: '木曜日',
    5: '金曜日',
    6: '土曜日',
}

export const dowToString = (dayOfWeek) => {
   return dowDictionary[Number(dayOfWeek)];
};

export const DowRender = ({ dayOfWeek }) => (
    <>{ dowToString(dayOfWeek) }</>
);
