import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CREATE_REQUEST, CREATE_ROUTES_FORECAST, UPDATE_REQUEST, CREATE_TRUCK_POSTING_COMMENT } from '../../mutations';
import { GET_LABELS, GET_USERS, GET_ROUTES_FORECASTS, GET_TRUCK_POSTING_COMMENT } from '../../queries';
import { authenticationService } from '../../Utilities/authenticationService';
import { DatePickerWithTimeInForm } from '../../Utilities/datepickerWithTimeInForm';
import {
    message,
    Select,
    Form,
    Input,
    Button,
    Drawer,
    InputNumber,
    Typography,
    Avatar,
    Divider,
    Popconfirm
} from 'antd';
import { CloseOutlined, MessageOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isMobile } from '../../Utilities/isMobile';
import CommentDrawer from '../FreightPosting/CommentDrawer';
import CommentList from '../FreightPosting/CommentList';

const MobileDetailDrawer = ({ visible=false, setVisible, stationsData, handleDeletePosting, truck, dateRange, posting = {}, paramsVehicleId, refetchPostings, disableToId=false, buttonMode }) => {
    const currentUser = authenticationService.currentUserValue

    const searchedStartTime = useMemo(() => moment(dateRange[0]), [dateRange])
    const searchedEndTime = useMemo(() => moment(dateRange[1]), [dateRange])
    const [stationId, setStationId] = useState(currentUser?.station_id);
    const [autoUpdated, setAutoUpdated] = useState(false);
    const { data: userListData } = useQuery(GET_USERS);
    const [labelId, setLabelId] = useState();
    const [toId, setToId] = useState(truck.stationId);
    const [userId, setUserId] = useState();
    const [parkedLoad, setParkedLoad] = useState();
    const [remarks, setRemarks] = useState();
    const [price, setPrice] = useState();
    const [comments, setComments] = useState([]);
    const [commenterIds, setCommenterIds] = useState([]);
    const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
    const [selectedCommenterId, setSelectedCommenterId] = useState(null);
    
    const [getLabelsData, { data: labelsData }] = useLazyQuery(GET_LABELS);
    const { refetch: refetchComments } = useQuery(GET_TRUCK_POSTING_COMMENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(posting.id) },
        onCompleted: (data) => {
            const fromUserIds = data.posting?.truckPostingComments.map(comment => comment.fromUser.id)
            const toUserIds = data.posting?.truckPostingComments.map(comment => comment.toUser?.id)
            const commenters = [...new Set([...fromUserIds, ...toUserIds])].filter(commenter => String(commenter) !== String(posting?.userId))
            setCommenterIds(commenters)

            const commentArr = data.posting?.truckPostingComments.map(comment => ({
                id: comment.id,
                postingId: posting.id,
                fromId: comment.fromUser.id,
                toId: comment.toUser?.id,
                author: <Typography.Text strong>{`${comment.fromUser.lastName} ${comment.fromUser.firstName}`}</Typography.Text>,
                avatar: <Avatar>{comment.fromUser.lastName[0]}</Avatar>,
                content: (
                    <div key={comment.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>{comment.commentText}</p>
                        <span style={{ fontSize: '0.8em', color: '#888', alignSelf: parseInt(comment.fromUser.id) !== parseInt(currentUser.id) ? 'flex-start' : 'flex-end' }}>
                            {moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm')}
                        </span>
                    </div>
                ),
                datetime: moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm'),
            }));
            setComments(commentArr)        
        }
    });

    const [getRoutesForecastData] = useLazyQuery(
        GET_ROUTES_FORECASTS, {
            onCompleted: (data) => {
                const vals = form.getFieldsValue(true)
                const route_val_json = JSON.stringify(data.routesForecasts.edges.find(edge => edge.node.vehicleId === paramsVehicleId)?.node)
                form.setFieldsValue({...vals, routeForecast: route_val_json})
            },
            onError: (error) => { console.log(error); }
        }
    );

    
    const [updateRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (data) => {
            if (data.updateRequest.request?.overlaps?.length > 0) {
                message.error('選択したトラックは、リクエストされた時間帯にすでにスケジュールされています。リクエストを承認できません。');
            } else {
                message.success(autoUpdated ? 'リクエストを自動承認しました。' : 'リクエストが更新されました')
                refetchPostings()
            }
            setAutoUpdated(false)
        },
        onError: (error) => { console.log(error); }
    });

    const [createRequest] = useMutation(CREATE_REQUEST, {
        onCompleted: (data) => {
            message.success('リクエストを送信しました。');
            setVisible(false);
            if(parseInt(truck.stationId) === currentUser.station_id && parseInt(toId) === currentUser.station_id && parseInt(userId) === currentUser.id) {
                setAutoUpdated(true);
                updateRequest({ variables: { input: { id: data.createRequest.request.id, status: 'accepted' }}})
            }
        },
        onError: (error) => {
            message.error('リクエストを送信できませんでした。');
        }
    });

    const [createRoutesForecast] = useMutation(CREATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            createRequest({ variables: { input: {
                opsDate: data.createRoutesForecast.routesForecast.opsDate,
                fromId: stationId || currentUser?.station_id,
                forecastId: data.createRoutesForecast.routesForecast.id,
                userId,
                remarks,
                toId,
                status: 'sent',
                truckTableId: truck.id,
                postingId: posting?.id,
                price
            }}});
        },
        onError: (error) => {
            if (error?.networkError?.result?.errors[0]?.message?.includes('overlap')) {
                message.error('スケジュールが重複しています。')
            } else {
                message.error('リクエストを送信できませんでした。');
            }
        }

    });

    const [createTruckPostingComment] = useMutation(CREATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
            refetchComments();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    
    const onNewFinish = ({ remarks, userId, toId, fromId, departureTime, arrivalTime, price, employeeId, employeeName }) => {
        setToId(toId);
        setUserId(userId);
        setRemarks(remarks);
        setPrice(price)
        createRoutesForecast({ variables: { input: {
            opsDate: moment(departureTime).format('YYYY-MM-DD'),
            stationId: fromId,
            vehicleStationId: truck.stationId,
            vehicleId: truck.vehicleId,
            maxTruckCapacity: truck.maxTruckCapacity,
            driverLicenseClass: truck.driverLicenseClass,
            vehicleType: truck.vehicleType,
            remarks,
            labelId,
            parkedLoad,
            departureTime,
            arrivalTime,
            pending: true,
            postingId: posting?.id,
            employeeId,
            employeeName
        }}});
    };

    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [, forceUpdate] = useState();

    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        if (visible && stationId) {
            const searchString =
                paramsVehicleId === "NoTruck"
                    ? `most_recent_forecast = true AND
                             vehicle_id = NoTruck AND
                             departure_time = "${searchedStartTime}" AND
                             arrival_time = "${searchedEndTime}" AND
                             station_id = ${currentUser?.station_id || stationId}`
                    : `most_recent_forecast = true AND
                             departure_time >= "${searchedStartTime}" AND
                             arrival_time < "${moment(searchedEndTime).add( 1, "minute")}" AND
                             station_id = ${currentUser?.station_id || stationId}`;
            getRoutesForecastData({
                variables: {
                    search: searchString
                }
            });
        }
    }, [getRoutesForecastData, truck, visible, stationId, searchedStartTime, searchedEndTime, paramsVehicleId, currentUser]);

    useEffect(() => {
        getLabelsData({ variables: { stationId: parseInt(stationId) } })
    }, [getLabelsData, visible, stationId]);

    const formItemStyle = {border: 'none', borderBottom: '1px solid lightgray', width: '100%'}
    const labelStyle = {padding: '0'};

    const NewRequestSubmitButton = () => {
        return (
        <Form.Item shouldUpdate>
                { () => (
                    <Button
                        disabled={
                            stationId === undefined ||
                            labelId === undefined ||
                            parkedLoad === undefined
                        }
                        type="primary"
                        htmlType='submit'
                    >
                        リクエストを送信する
                    </Button>
                )}
            </Form.Item>
        )
    }
    
    return <>
        {buttonMode ? <Button icon={<MessageOutlined/>} onClick={() => setVisible(true)}/> : <Button type='link' onClick={() => setVisible(true)}>リクエスト</Button>}
        <Drawer
            width={isMobile() ? '100vw' : 450}
            open={visible}
            footer={null}
            closable={false}
        >
            <Form
                name='request'
                form={newForm}
                onFinish={onNewFinish}
                colon={false}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: '-30px'
                    }}
                >
                    <NewRequestSubmitButton />
                    <div>
                        <Popconfirm
                            title="投稿を削除しますか？"
                            okText="削除"
                            cancelText="キャンセル"
                            placement='bottomLeft'
                            onConfirm={() => handleDeletePosting(posting.id)}
                        >
                            <DeleteOutlined
                                style={{
                                    marginRight: 5,
                                    fontSize: 16,
                                    zIndex: 1,
                                }}
                            />
                        </Popconfirm>
                        <Button
                            type='text'
                            onClick={() => setVisible(false)}
                            style={{ zIndex: 1 }}
                        >
                            <CloseOutlined />
                        </Button>
                    </div>
                </div>
                <Divider style={{width: '112%', marginLeft: '-1.5rem'}} />
                <CommentList 
                    posting={posting}
                    commenterIds={commenterIds}
                    setSelectedCommenterId={setSelectedCommenterId}
                    setOpenCommentDrawer={setOpenCommentDrawer}
                    userListData={userListData}
                />
                
                <CommentDrawer
                    open={openCommentDrawer}
                    setOpen={setOpenCommentDrawer}
                    comments={comments.filter(comment => comment.toId === selectedCommenterId || comment.fromId === selectedCommenterId )}
                    selectedCommenterId={selectedCommenterId}
                    toUser={userListData?.users?.edges?.find(user => user.node.id === selectedCommenterId)?.node}
                    postingId={posting.id}
                    createComment={createTruckPostingComment}
                />
                <Form.Item
                    labelCol={{ style: labelStyle }}
                    label='依頼元営業所'
                    name='fromId'
                    initialValue={String(currentUser?.station_id)}
                    rules={[{ required: true, message: '営業所を入力してください。' }]}
                    style={formItemStyle}
                >
                    <Select
                        showSearch={!isMobile()}
                        placeholder="選択してください"
                        style={{ width: '100%' }}
                        className="custom-select"
                        disabled={!currentUser?.admin}
                        onChange={(fromId) => setStationId(fromId)}
                    >
                        {
                            stationsData.stations.edges.map(
                                station => (
                                    <Select.Option key={station.node.id} value={station.node.id}>
                                        {station.node.officialName}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label='依頼先営業所'
                    name='toId'
                    initialValue={truck.stationId}
                    rules={[{ required: true, message: '営業所を入力してください。' }]}
                    style={formItemStyle}
                    labelCol={{ style: labelStyle }}
                >
                    <Select
                        disabled={disableToId}
                        showSearch
                        placeholder="選択してください"
                        style={{ width: '100%' }}
                        className="custom-select"
                        onChange={
                            selectedId => {
                                setToId(selectedId)
                            }
                        }
                    >
                        {
                            stationsData.stations.edges.map(
                                station => (
                                    <Select.Option key={station.node.id} value={station.node.id}>
                                        {station.node.officialName}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label='承認者'
                    name='userId'
                    style={formItemStyle}
                    labelCol={{ style: labelStyle }}
                    rules={[{ required: true, message: '送り先を入力してください。' }]}
                >
                    { userListData && <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: '100%' }}
                        className="custom-select"
                        filterOption={(input,option)=> option.children.join('').includes(input)}
                    >
                        {
                            userListData.users.edges.filter(user => user.node.station?.id === toId).map(
                                user => (
                                    <Select.Option key={user.node.id} value={user.node.id}>{user.node.lastName} {user.node.firstName}</Select.Option>
                                )
                            )
                        }
                    </Select> }
                </Form.Item>
                <Form.Item
                    name='label'
                    label='ルート'
                    labelCol={{ style: labelStyle }}
                    style={formItemStyle}
                    rules={[{ required: true, message: 'ルートを入力してください。' }]}
                >
                    <Select
                        placeholder="選択してください"
                        showSearch
                        onChange={setLabelId}
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        className="custom-select"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            labelsData && labelsData.mostFrequentlyUsedLabels.map(
                                label => (
                                    label.enabled &&
                                        <Select.Option key={label.id} value={label.id}>{label.label.join('|')}</Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label='配送者ID'
                    name="employeeId"
                    labelCol={{ style: labelStyle }}
                    style={formItemStyle}
                    rules={[{ required: false, message: '正しい配送者IDを入力してください。' }]}
                >
                    <Input className="custom-input" placeholder='配送者IDを入力してください。'/>
                </Form.Item>
                <Form.Item
                    label='配送者名'
                    name="employeeName"
                    style={formItemStyle}
                    labelCol={{ style: labelStyle }}
                    rules={[{ required: false, message: '配送者名を入力してください。' }]}
                >
                    <Input className="custom-input" placeholder='配送者名を入力してください。'/>
                </Form.Item>
                <Form.Item
                    label='荷有り'
                    name='parkedLoad'
                    style={formItemStyle}
                    labelCol={{ style: labelStyle }}
                    className="custom-select"
                    rules={[{ required: true, message: '荷物の有無を入力してください。' }]}
                >
                    <Select
                        placeholder="選択してください" onChange={setParkedLoad}>
                        {
                            ["空", "荷あり"].map(parkedLoadType => (
                                    <Select.Option key={parkedLoadType} value={parkedLoadType}>
                                        {parkedLoadType}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label='運行日'
                    name='opsDate'
                    style={formItemStyle}
                    labelCol={{ style: labelStyle }}
                >
                    <Typography  className="custom-select">{truck.date}</Typography>
                </Form.Item>
                出発時間：<DatePickerWithTimeInForm style={formItemStyle} border={{border: 'none'}} name='departureTime' initialTime={dateRange[0]} disableDate={(current) => { return current?.isBefore(searchedStartTime) || current?.isAfter(searchedEndTime) }}/>
                到着時間：<DatePickerWithTimeInForm style={formItemStyle} border={{border: 'none'}} name='arrivalTime' initialTime={dateRange[1]} disableDate={(current) => { return current?.isBefore(searchedStartTime) || current?.isAfter(searchedEndTime) }}/>
                <Form.Item
                    label='車種'
                    name='vehicleType'
                    labelCol={{ style: labelStyle }}
                    style={formItemStyle}
                >
                    <Typography>{truck.vehicleType}</Typography>
                </Form.Item>
                <Form.Item
                    label='車両番号'
                    name='vehicleId'
                    labelCol={{ style: labelStyle }}
                    style={formItemStyle}
                >
                    <Typography>{truck.vehicleId}</Typography>
                </Form.Item>
                <Form.Item
                    label='運賃'
                    name='price'
                    style={formItemStyle}
                    labelCol={{ style: labelStyle }}
                    rules={[{ required: false, message: '運賃を入力してください。' }]}
                >
                    <InputNumber min={0} style={{ border: 'none', width: '100%' }} placeholder='運賃を入力してください'/>
                </Form.Item>
                <Form.Item
                    label='積載重量'
                    name='maxTruckCapacity'
                    labelCol={{ style: labelStyle }}
                    style={formItemStyle}
                >
                    <Typography>{truck.maxTruckCapacity}</Typography>
                </Form.Item>
                <Form.Item
                    label='免許タイプ'
                    name='driverLicenseClass'
                    labelCol={{ style: labelStyle }}
                    style={formItemStyle}
                >
                    <Typography>{truck.driverLicenseClass}</Typography>
                </Form.Item>
                <Form.Item
                    label='備考欄'
                    name='remarks'
                    style={formItemStyle}
                    labelCol={{ style: labelStyle }}
                    rules={[{ required: false }]}
                >
                    <Input.TextArea style={{ border: 'none' }} placeholder='備考を入力してください' />
                </Form.Item>
            </Form>       
        </Drawer>
    </>
};

export default MobileDetailDrawer;
