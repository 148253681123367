import React from 'react';
import { Avatar, Typography } from 'antd';

import { authenticationService } from '../../Utilities/authenticationService';

const CommentList = ({ posting, commenterIds, setOpenCommentDrawer, userListData, setSelectedCommenterId }) => {
    const currentUser = authenticationService.currentUserValue;
    return (
        <div>
            {posting?.userId !== String(currentUser.id) && commenterIds?.length > 0 && commenterIds?.includes(String(currentUser.id)) &&
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '1rem'}}>
                    <Avatar onClick={() => {setSelectedCommenterId(posting?.userId); setOpenCommentDrawer(true);}}>{posting?.postedBy?.name?.split(' ')[0][0]}</Avatar>
                    <Typography.Text>{posting?.postedBy?.name?.split(' ')[0]}</Typography.Text>
                </div>
            }
            {posting?.userId !== String(currentUser.id)  && !commenterIds?.includes(String(currentUser.id)) &&                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '1rem'}}>
                    <Avatar onClick={() => {setSelectedCommenterId(posting?.userId); setOpenCommentDrawer(true);}}>+</Avatar>
                    <Typography.Text>新規コメント</Typography.Text>
                </div>
            }
            {posting?.userId === String(currentUser.id) && commenterIds?.length > 0 && userListData &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', marginBottom: '1rem'}}>
                    {commenterIds.map((commenterId) => {
                        const commenter = userListData.users?.edges?.find(user => user.node.id === commenterId).node;
                        return (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '1rem'}}>
                                <Avatar onClick={() => {setSelectedCommenterId(commenterId); setOpenCommentDrawer(true);}}>{commenter.lastName[0]}</Avatar>
                                <Typography.Text>{`${commenter?.lastName}`}</Typography.Text>
                            </div>
                        )
                    })}
                </div>
            }
            {posting?.userId === String(currentUser.id) && commenterIds?.length === 0 && <Typography.Text>コメントはありません</Typography.Text>}
        </div>
    );
}

export default CommentList;