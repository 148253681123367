import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, message, Typography, Form, Input, Row, Space, Checkbox} from 'antd';
import { CREATE_COMPANY } from '../../mutations';
import propTypes from 'prop-types';


const AddCompanyModal = ({ handleCompleted, returnToTable }) => {
    const [createCompany] = useMutation(CREATE_COMPANY, {
        onCompleted: (data) => {
            handleCompleted(data.createCompany.company);
        },
        onError: () => {
            message.error('会社名が既に登録されているか、書式が間違っています。');
        }
    });

    const onFinish = ({ companyName, contractor=false, memo }) => {
        createCompany({ variables: { input: { companyName, contractor, memo } } });
    };

    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 9,
            }
        }
    };

    return <Form
        labelCol={ { span: 4 } }
        wrapperCol={ { span: 8 } }
        name='newCompany'
        form={form}
        style={{
            maxWidth: 600,
        }}
        onFinish={onFinish}
    >
        <Form.Item>
            <Typography.Title level={5}>会社名追加</Typography.Title>
        </Form.Item>
        <Form.Item
            label="会社名"
            name='companyName'
            rules={[{ required: true, message: '会社名を入力してください。' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="備考"
            name='memo'
            rules={[{ required: false }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="請負業者である"
            name='contractor'
            valuePropName='checked'
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
        >
            <Checkbox />
        </Form.Item>
        <Row>
            <Space>
                <Form.Item shouldUpdate {...tailFormItemLayout}>
                    { () => (
                        <Button
                            disabled={ !form.isFieldTouched('companyName') ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length}
                            type="primary"
                            htmlType='submit'
                        >
                            追加する
                        </Button>
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button onClick={returnToTable} >
                        キャンセル
                    </Button>
                </Form.Item>
            </Space>
        </Row>
    </Form>;
};

AddCompanyModal.propTypes = {
    handleCompleted: propTypes.func
};

export default AddCompanyModal;
