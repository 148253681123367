import React  from 'react';
import { Layout, Tabs, Card, Space } from 'antd';
import { UserOutlined, TeamOutlined, SettingOutlined, IdcardOutlined } from '@ant-design/icons';
import AccountInformation from './AccountInformation';
import UserManagement from './UserManagement';
import CompanyManagement from './CompanyManagement';
import StationManagement from './StationManagement';
import { isMobile } from '../../Utilities/isMobile';

const { Content } = Layout;

const UserSettings = () => {
    const currentUser = JSON.parse(localStorage.currentUser);
    const currentUserIsAdmin = currentUser?.company_admin || currentUser?.admin
    const currentUserIsStationManager = currentUser?.station_manager
    
    const items = [
        {
            key: 'accountInformation',
            label: <><UserOutlined/>アカウント</>,
            icon: <SettingOutlined />,
            children: <AccountInformation/>
        },
        (currentUserIsAdmin || currentUserIsStationManager) && {
            key: 'userManagement',
            label: <><TeamOutlined/>ユーザー管理</>,
            icon: <TeamOutlined />,
            children: <UserManagement/>
        },
        currentUserIsAdmin && {
            key: 'companyManagement',
            label: <><IdcardOutlined />会社管理</>,
            icon: <IdcardOutlined />,
            children: <CompanyManagement/>
        },
        currentUserIsAdmin && {
            key: 'stationManagement',
            label: <><IdcardOutlined />営業所管理</>,
            icon: <IdcardOutlined />,
            children: <StationManagement/>
        }
    ];
    const UserSettingsForMobile = () => {
        return <Space direction="vertical" style={{ width: "100%" }}>
            <Card bordered={false}>
                <Tabs defaultActiveKey={'accountInformation'} items={[items[0]]} />
            </Card>
        </Space>
    }

    return <Layout>
        {isMobile() ? <UserSettingsForMobile/> :
            <Space direction='vertical' style={{ width: '100%'}}>
                <Card bordered={false}>
                    <Content className='site-layout-background'>
                        <Tabs tabPosition='left' defaultActiveKey={'accountInformation'} items={items} />
                    </Content>
                </Card>
            </Space>
        }
    </Layout>;
};

export default UserSettings;
