import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_POSTING } from '../../../mutations';
import {
    message,
    Button,
    Modal,
    Typography
} from 'antd';
import * as moment from 'moment';

const PostingDrawer = ({ truck, dateRange, setRefresh }) => {
    const searchedStartTime = moment(dateRange[0])
    const searchedEndTime = moment(dateRange[1])
    const [visible, setVisible] = useState(false);

    const [createPosting] = useMutation(CREATE_POSTING, {
        onCompleted: () => {
            message.success('空きトラック掲示板に投稿しました。');
            setVisible(false);
            setRefresh(true);
        },
        onError: (error) => {
            message.error('その車両は既にポストされています');
        }
    });


    const handleOk = () => {
        createPosting({
            variables: {
                input: {
                    vehicleId: truck.vehicleId,
                    stationId: truck.stationId,
                    opsDate: searchedStartTime.format('YYYY-MM-DD'),
                    startDate: searchedStartTime.format("YYYY-MM-DDTHH:mm:ssZ"),
                    endDate: searchedEndTime.format("YYYY-MM-DDTHH:mm:ssZ"),
                    status: "available"
                }
            }
        });
    };

    return <>
        <Button type='link' onClick={() => setVisible(true)}>ポスト</Button>
        <Modal
            open={visible}
            onOk={handleOk}
            onCancel={() => setVisible(false)}
            title={"このトラックを空きトラック掲示板に投稿しますか？"}
        >
            
            <Typography>営業所: {truck.station}</Typography>
            <Typography>車両番号: {truck.vehicleId}</Typography>
            <Typography>運行時間： {searchedStartTime.format('YYYY-MM-DD H:mm')} - {searchedEndTime.format('YYYY-MM-DD H:mm') }</Typography>
            <Typography>車種名: {truck.vehicleType}</Typography>
            <Typography>積載重量: {truck.maxTruckCapacity}</Typography>
            <Typography></Typography>

            
        </Modal>
    </>
};

export default PostingDrawer;
