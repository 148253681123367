import { BehaviorSubject } from 'rxjs';
import { DENTIST_API_ROOT } from '../constants';
import { handleResponse } from './handleResponse';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
};

export const login = (email, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({ user: { email, password } })
    };

    return fetch(`${DENTIST_API_ROOT}/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        currentUserSubject.next(user);

        return user;
    });
};

export const forgot_password = (email) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch(`${DENTIST_API_ROOT}/forgot_password`, requestOptions)
}

export const reset_password = (password, password_confirmation, token) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({ password, password_confirmation, token })
    };

    return fetch(`${DENTIST_API_ROOT}/reset_password`, requestOptions)
}

export const authenticationService = {
    login,
    logout,
    forgot_password,
    reset_password,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value; }
};

export function authHeader() {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return { Authorization: currentUser.token };
    } else {
        return {};
    }
};
