import React, { useEffect } from 'react';
import { Drawer, Form, Select, InputNumber, Button, message } from 'antd';
import { SEND_TRABOX_FOR_POSTING } from '../../../mutations';
import { useMutation } from '@apollo/client';
import { departurePref, truckModel, truckWeight } from '../../FreightPosting/TraboxLabels'
import { DatePickerWithTimeInForm } from '../../../Utilities/datepickerWithTimeInForm';
import { isMobile } from '../../../Utilities/isMobile';
const TraboxDrawer = ({
    drawerOpen,
    setDrawerOpen,
    posting,
    refetch }) => {

    const [form] = Form.useForm();
    const [sendTrabox] = useMutation(SEND_TRABOX_FOR_POSTING, {
        onCompleted: () => {
            refetch();
            setDrawerOpen(false);
            message.success('トラボックスに投稿しました。')
        }
    });

    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 9,
            }
        }
    };
      
    const onFinish = ({orig, dest, wgtClass, vehicleType, freightCharge, startDate, endDate}) => {
        // compare start and end datetime and post an error if start datetime is later than end datetime
        if (startDate.isAfter(endDate)) {
            // Display error message if startDate is later than endDate
            message.error('出発時刻は到着時刻より早い必要があります。');
            return; // Stop the form submission
        }

        sendTrabox({
            variables: {
                input: {
                    id: posting.id,
                    orig,
                    dest,
                    wgtClass,
                    vehicleType,
                    freightCharge,
                    startDate: startDate.format('YYYY-MM-DD HH:mm'),
                    endDate: endDate.format('YYYY-MM-DD HH:mm')

                }
            }
        }).then(() => form.resetFields())
    }

    useEffect(() => {
        form.validateFields(['startDate', 'endDate'])
    }, [form, posting])

    return (
        <Drawer
            title="トラボックスに投稿する"
            placement="right"
            open={drawerOpen}
            width={isMobile() ? '100vw' : 400}
            onClose={() => setDrawerOpen(false)}
        >
            <Form
                form={form}
                name="request"
                onFinish={onFinish}
                initialValues={{ remember: true }}
                colon={false}
                layout="vertical"
            >
                <DatePickerWithTimeInForm label="出発時刻" name='startDate' initialTime={posting?.startDate} tenMinRule={true} />
                <DatePickerWithTimeInForm label="到着時刻" name='endDate' initialTime={posting?.endDate} tenMinRule={true} />
                <Form.Item label="出発地" name='orig' rules={[{ required: true }]}>
                    <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        allowClear
                        options={departurePref.map(pref => ({ label: pref,
                             value: pref }))}
                    />
                </Form.Item>
                <Form.Item label="到着地" name='dest' rules={[{ required: true }]}>
                    <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        allowClear
                        options={departurePref.map(pref => ({ label: pref,
                             value: pref }))}
                    />
                </Form.Item>
                <Form.Item label="積載重量" name='wgtClass' rules={[{ required: true }]}>
                    <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        allowClear
                        options={truckWeight.map(weight => ({ label: weight, value: weight }))}
                    />
                </Form.Item>
                <Form.Item label="車種" name='vehicleType' rules={[{ required: true }]}>
                    <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 200 }}
                        allowClear
                        options={truckModel.map(model => ({ label: model, value: model }))}
                    />
                </Form.Item>
                <Form.Item label="料金" name='freightCharge' rules={[{ required: true }]}>
                    <InputNumber />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">投稿する</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default TraboxDrawer;