import { useLazyQuery, useQuery } from '@apollo/client';
import { Spin, Typography } from 'antd';
import * as moment from 'moment';
import 'moment/locale/ja';
import React, { useEffect, useMemo, useState } from 'react';
import { GET_HEATMAP_FORECASTS, GET_FREIGHT_POSTINGS } from '../../queries';
import "./HeatMap.css";
import { dowToString }  from '../../Utilities/dayOfWeek';
import { colors, getVehicleName } from './NewHeatmapSchedule';
moment.locale('ja');

const PdfPreview = ({ stationsData, stations = [], stationNames, date, detailFilter }) => {
    const [getRoutesData, { data: routesData, loading }] = useLazyQuery(GET_HEATMAP_FORECASTS, { fetchPolicy: 'no-cache' });
    const [stationTrucks, setStationTrucks] = useState(null);

    const { data: freightPostingData } = useQuery(GET_FREIGHT_POSTINGS)

    useEffect(() => {
        if (stations.length > 0 && date !== '') {
            getRoutesData({
                variables:  {
                    fromDate: moment(date).startOf('day').format('YYYY-MM-DD') + 'T00:00:00+09:00',
                    toDate: moment().add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00+09:00',
                    stationIds: stations
                }
            });
        }
    }, [getRoutesData, stations, date])

    useEffect(() => {
        if (!routesData) {
            return
        }

        // filter routesData by date, only keep routes that are on the date
        const filteredRoutes = routesData.heatmapForecasts.filter(route => {
            const arrival = moment(route.arrivalTime).format('YYYY-MM-DD')
            const departure = moment(route.departureTime).format('YYYY-MM-DD')
            const dateStr = moment(date).format('YYYY-MM-DD')
            return arrival === dateStr || departure === dateStr
        })

        const stationTrucks = {} // { stationId: [truck1, truck2, ...], stationId2: [truck1, truck2, ...] } trucks are the objects above
        let noTruckCounter = 1
        stations.forEach(stationsId => {
            const trucks = {} // { truckId: [route1, route2, ...], truckId2: [route1, route2, ...] }
            let filteredData = filteredRoutes.filter(route => route.stationId === parseInt(stationsId) || route.vehicleStationId === parseInt(stationsId))
            if (detailFilter?.vehicleType) {
                filteredData = filteredData.filter(route => route.vehicleType === detailFilter.vehicleType)
            }
            if (detailFilter?.maxTruckCapacityStart || detailFilter?.maxTruckCapacityEnd) {
                const start = detailFilter?.maxTruckCapacityStart || 0
                const end = detailFilter?.maxTruckCapacityEnd || 100000
                filteredData = filteredData.filter(route => route.maxTruckCapacity >= start && route.maxTruckCapacity <= end)
            }
            if (detailFilter?.labels?.length > 0) {
                filteredData = filteredData.filter(route => route.label.label.some(label => detailFilter.labels.includes(label)))
            }
            if (detailFilter?.parkedLoad) {
                filteredData = filteredData.filter(route => route.parkedLoad === detailFilter.parkedLoad)
            }
            if (detailFilter?.truckSize) {
                filteredData = filteredData.filter(route => getVehicleName(route.driverLicenseClass) === detailFilter.truckSize)
            }

            filteredData.forEach(f => {
                const forecast = {...f}
                // add color property to forecast
                forecast.color = colors["Regular"]
                forecast.predFlag && (forecast.color = colors["Regular predicted"])
                if (!forecast.vehicleId) {
                    forecast.vehicleId = "NullTruck"
                }
                forecast.vehicleId === "NoTruck" && (forecast.color = colors["No Truck"])
                //forecast.color === colors["No Truck"] && forecast.predFlag && (forecast.color = colors["No truck predicted"])
                if (forecast.vehicleStationId !== forecast.stationId && forecast.vehicleId !== "NoTruck") {
                    forecast.vehicleStationId === parseInt(stationsId) ? forecast.color = colors["Lent"] : forecast.color = colors["Borrowed"]
                }
                // if forecast truck's label is 空きトラック then color should be empty truck
                if (forecast.label.label[0] === "空きトラック" ) {
                    forecast.color = colors["Empty truck"]
                }
                // if forecast is NoTruck, add as separate truck
                if (forecast.vehicleId === "NoTruck") {
                    trucks["NoTruck" + noTruckCounter] = [forecast]
                    noTruckCounter++
                } else {
                    trucks[forecast.vehicleId] ? trucks[forecast.vehicleId].push(forecast) : trucks[forecast.vehicleId] = [forecast]
                }
            })
            const trucksArray = Object.entries(trucks).map(([key, value]) => {
                const truckObj = {}
                truckObj[key] = value
                return truckObj
            })
            stationTrucks[stationsId] = trucksArray
        })
        setStationTrucks(stationTrucks)
    }, [routesData, stations, detailFilter, date])

    const timeChunks = useMemo(() => {

        let open = new moment(date).startOf('day').toDate()

        let chunks = []
        for (let i = 0 ; i < 15 * 4 * 24; i += 15) {
            const m = moment(open).add(i, 'minutes')
            const time = m.toDate()
            chunks.push(time)
        }
        return chunks
    }, [date])

    const filteredFreightPostings = useMemo(() => {
        if (!freightPostingData) {
            return []
        }
        const filtered = freightPostingData.freightPostings.edges.filter(edge => edge.node.vehicleId)
        return filtered.map(edge => {
            const node = edge.node
            // get number of 15 minute intervals between the start of the time_chunks and the pickup time
            const time_diff = (new Date(node.pickupDate).getTime() - new Date(timeChunks[0]).getTime())/60000
            const time_diff_units = time_diff/15
            const length_minutes = ( new Date(node.deliveryDate).getTime() - new Date(node.pickupDate).getTime())/60000 // convert to minutes
            const length_units = length_minutes / 15
            return {...node, time_diff_units, length_units}
        })
    }, [freightPostingData, timeChunks])

    const vehicleNames = {}
    let noTruckCounter = 1
    routesData && routesData.heatmapForecasts.forEach(forecast => {
        let noTruck = false
        if (forecast.vehicleId === "NoTruck") {
            noTruck = true
        }
        if (vehicleNames[forecast.vehicleId]) {
            return
        }
        const vehicleId = forecast.vehicleId
        const vehicleName = `${vehicleId} ${noTruck ? '- ' + noTruckCounter: ''}` +
                    `(${forecast.vehicleType} ${getVehicleName(forecast.driverLicenseClass)})`;

        if (noTruck) {
            vehicleNames[forecast.vehicleId + `${noTruckCounter}`] = vehicleName
            noTruck && noTruckCounter++
        } else {
            vehicleNames[forecast.vehicleId] = vehicleName
        }

    })
    vehicleNames['NullTruck'] = "NoTruck - リクエスト"

    const rowHeight = 1.5 // rem
    const columnWidth = 0.75 // rem

    if (loading) {
        return <Spin />
    }
    if (!stationTrucks) {
        return <></>
    }

    return <div id='pdf-export' >
        { Object.entries(stationTrucks).map(([stationId, trucks]) => {
            return (
            <div style={{ marginTop: "1rem", marginBottom: "1rem"}} key={stationId}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{display: "flex", flexDirection: "column", marginTop: rowHeight * 2 + "rem"}}>
                        {trucks.map(truck => {
                            const id = Object.keys(truck)[0]
                            return <div key={id} style={{ fontSize: rowHeight/2 + "rem", minHeight: rowHeight + "rem", width: "10rem", boxSizing: "border-box", border: "1px solid lightgrey",
                                     zIndex: 1, display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "center"}}>{vehicleNames[id]}</div>
                        })}
                    </div>
                    <div style={{ overflow: 'scroll' }}>
                        <div style={{display: "flex", flexDirection: "column", minHeight: rowHeight + "rem"}}>
                            <div style={{display: "flex", flexDirection: "row", minHeight: rowHeight + "rem"}}>
                                {timeChunks.reduce((acc, curr) => {
                                    const time = curr.getHours()
                                    const minutes = curr.getMinutes()
                                    const stationName = stationNames.find(s => s.node.id === stationId)?.node.officialName
                                    const dateStr = `${curr.getFullYear()}年${curr.getMonth() + 1}月${curr.getDate()}日(${dowToString(curr.getDay())[0]})`
                                    const value = time === 0  & minutes === 0 ? <div key={curr.getTime()} style={{fontSize: '16px', textAlign: "center", width: columnWidth * 4 * 24 + "rem", borderLeft: "2px solid lightgrey"}}>{stationName}{"  "}{dateStr}</div> : null
                                    return [...acc, value]
                                }, [])}
                            </div>
                            <div style={{display: "flex", flexDirection: "row", minHeight: rowHeight + "rem"}}>
                                {timeChunks.reduce((acc, curr) => {
                                    const minutes = curr.getMinutes()
                                    const hour = curr.getHours()
                                    const value = minutes === 0 ? <div key={curr.getTime()} style={{textAlign: "center", width: '40px', borderLeft: minutes === 0 && hour === 0 ? "2px solid lightgrey" : "1px solid lightgrey", borderBottom: "1px solid lightgray"}}>{hour}</div> : null
                                    return [...acc, value]
                                }, [])}
                            </div>
                        </div>
                    {trucks.map(truck => {
                        const id = Object.keys(truck)[0]
                        const freightPostings = filteredFreightPostings.filter(posting => posting.vehicleId === parseInt(id))
                        const routes = Object.values(truck)[0].map(route => {
                            const length_minutes = ( new Date(route.arrivalTime).getTime() - new Date(route.departureTime).getTime())/60000 // convert to minutes
                            const length_units = length_minutes / 15
                            // get closest 15 minute chunk to departure time
                            const closest_chunk = timeChunks.reduce((prev, curr) => {
                                return (Math.abs(new Date(curr).getTime() - new Date(route.departureTime).getTime()) < Math.abs(new Date(prev).getTime() - new Date(route.departureTime).getTime()) ? curr : prev)
                            })
                            // get time difference between closest chunk and departure time
                            const time_diff = (new Date(route.departureTime).getTime() - new Date(closest_chunk).getTime())/60000
                            return { ...route, length_minutes, length_units, closest_chunk, time_diff_units: time_diff/15 }
                        })
                        const timeColumns = timeChunks.map((time, index)=> {
                            const route = routes.find(route => route.closest_chunk.getTime() === time.getTime())
                            const timeColumn =
                                <div
                                    className='hover-columns'
                                    key={time.toISOString()}
                                    id={time.toString + "-" + id + "-" + stationId}
                                    style={{
                                        width: '10px',
                                        boxSizing: "border-box",
                                        borderBottom: "1px solid lightgrey",
                                        borderLeft: time.getHours() === 0 && time.getMinutes() === 0
                                                        ? "2px solid lightgrey"
                                                        : time.getMinutes() === 0
                                                        ? "1px solid lightgrey"
                                                        : "1px dashed lightgrey",
                                        display: "flex",
                                        position: "relative"
                                    }}
                                >
                                    { route &&
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginLeft: columnWidth * route.time_diff_units + "rem"}}>
                                            <div
                                                style={{
                                                    backgroundColor: route.color,
                                                    height: route.pending ? "50%" : "80%",
                                                    alignSelf: "center",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: columnWidth * (route.length_units) + "rem",
                                                    position: "relative",
                                                    zIndex: 1,
                                                    cursor: "move"
                                                }}
                                                className={route.pending && 'pending-stripes'}
                                                id={ route.id + "-" + id + "-" + stationId}
                                            >
                                                <div style={{marginRight: 'auto', fontWeight: 'bold' }}>{route.label.label || ''}</div>
                                            </div>
                                        </div>
                                    }
                                    {index === 0 && freightPostings.length > 0 && freightPostings.map(posting => {
                                        return <div
                                                className='freight-stripes'
                                                key={posting.id}
                                                style={{
                                                    height: rowHeight*.8 + "rem",
                                                    position: "absolute",
                                                    alignSelf: "center",
                                                    marginLeft: columnWidth * (posting.time_diff_units) + "rem",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: columnWidth * (posting.length_units) + "rem",
                                                    zIndex: 2
                                                }}
                                            />
                                    })
                                    }
                                </div>
                            return timeColumn
                        })
                        return (<div key={id} style={{display: "flex", flexDirection: "row", minHeight: rowHeight + "rem"}}>{timeColumns}</div>)
                    })}
                </div>
            </div>
        </div>)})
    }
    <div style={{display: "flex", justifyContent: "flex-end", margin: "1rem"}}>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#FAA64D"}}></div>
            <Typography>固定ルート</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#439FF8"}}></div>
            <Typography>割付無しルート</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#97F688"}}></div>
            <Typography>予測ルート</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#8CD5FB"}}></div>
            <Typography>空車トラック</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#722ED1"}}></div>
            <Typography>予測ルート（割付未完了）</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#595959"}}></div>
            <Typography>応援トラック（借り）</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#EB2F96"}}></div>
            <Typography>応援トラック（貸し）</Typography>
        </div>
    </div>
    </div>
};
export default PdfPreview;
