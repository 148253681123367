import React, { useState, useEffect } from 'react';
import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { toFullWidthKatakana } from '../../Utilities/katakanaWidthConversion';

const EditableTagGroup = ({ setRouteName }) => {
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState(-1);
    const [error, setError] = useState(null);

    useEffect(() => {
        setRouteName(tags);
    }, [tags, setRouteName])

    const handleClose = removedTag => {
        let newTags = tags.filter(tag => tag !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
        if (!isValidInput(e.target.value)) {
            setError('ルート名に記号を使用することはできません。');
        } else if ([' ', '｡', '。', '､', '、'].includes(e.target.value.slice(-1))) {
            handleInputConfirm()
            showInput()
        } else {
            setError(null);
        }
    };

    const isValidInput = (inputValue) => {
        let specialChars = new Set('・&＆%％$＄#＃@＠!！|｜?？￥/／^＾-~〜.,\'"”’*＊ 　'.split(''))
        let isValid = true
        inputValue.split('').forEach(char => {
            if (specialChars.has(char)) {
                isValid = false
            }
        })
        return isValid
    };

    const handleInputConfirm = () => {
        let newTags = tags;
        if (inputValue && isValidInput(inputValue)) {
            newTags = [...tags, toFullWidthKatakana(inputValue.trim())];
            setTags(newTags);
            setInputVisible(false);
            setInputValue('');
        }
    };

    const handleEditInputChange = e => {
        setEditInputValue(e.target.value);
        if (!isValidInput(e.target.value)) {
            setError('ルート名に記号を使用することはできません。');
        } else {
            setError(null);
        }
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        if (editInputValue && isValidInput(editInputValue)) {
            newTags[editInputIndex] = toFullWidthKatakana(editInputValue.trim());
            setTags(newTags);
            setEditInputIndex(-1);
            setEditInputValue('');
        }
    };

    return <>
        {tags.map((tag, index) => {
            if (editInputIndex === index) {
                return (
                    <Input
                        key={tag}
                        size="small"
                        className="tag-input"
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                    />
                );
            }

            const isLongTag = tag.length > 20;

            const tagElem = (
                <Tag
                    className="edit-tag"
                    key={tag}
                    style={{ border: tag.includes('｜') ? "1px solid red" : null }}
                    closable
                    onClose={() => handleClose(tag)}
                >
                    <span
                        onDoubleClick={e => {
                            if (index !== 0) {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                            }
                        }}
                    >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </span>
                </Tag>
            );
            return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                    {tagElem}
                </Tooltip>
            ) : (
            tagElem
            );
        })}
        {inputVisible && (
            <Input
                type="text"
                size="small"
                className="tag-input"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
            />
        )}
        {!inputVisible && (
            <Tag className="site-tag-plus" onClick={showInput}>
                <PlusOutlined />
            </Tag>
        )}
        {error ? <span style={{ color: 'red' }}>{error}</span> : null}
    </>
};

export default EditableTagGroup;
