import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Divider, message, Spin, Tooltip, Typography, Popconfirm, Alert, Modal, Button, Form, Select, Radio } from 'antd';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import 'moment/locale/ja';
import React, { useEffect, useMemo, useState } from 'react';
import { CREATE_POSTING, UPDATE_ROUTES_FORECAST, ARCHIVE_ROUTES_FORECAST, UPDATE_FREIGHT_POSTING } from '../../mutations';
import { GET_HEATMAP_FORECASTS, GET_FREIGHT_POSTINGS } from '../../queries';
import "./HeatMap.css";
import HeatmapModal from './HeatmapModal';
import HeatmapRequestModal from './HeatmapRequestModal';
import { authenticationService } from '../../Utilities/authenticationService';
import { dowToString }  from '../../Utilities/dayOfWeek';
import PdfExportButton from './PdfExportButton';
import { DatePickerWithTimeInForm } from '../../Utilities/datepickerWithTimeInForm';
moment.locale('ja');

export const getRanges = (date, heatmapDates) => {
    const rangeEnd = moment.min(moment(date).startOf('day').add(1, 'days'), heatmapDates[heatmapDates.length - 1])
    const rangeStart = moment(rangeEnd).subtract(24, 'hours') // if a date is chosen that ends before the full 24 hours, use the last 24 hours
    return [rangeStart, rangeEnd]
}

export const getVehicleName = (driverLicenseClass) =>  {
    switch(driverLicenseClass) {
        case 2 : return '大型'
        case 1 : return '中型'
        case 0 : return '小型'
        default : return ''
    }
};

export const RFC_3339 = "YYYY-MM-DDTHH:mm:ssZ"

export const colors = {
    "No Truck": "rgb(0, 159, 255)",
    "No Truck predicted": "rgb(148, 55, 255)",
    "Regular": "rgb(255, 147, 0)",
    "Regular predicted": "rgb(115, 250, 121)",
    "Empty truck": "rgb(118, 214, 255)",
    "Borrowed": "rgb(89, 89, 89)",
    "Lent": "rgb(235, 47, 150)"
}

const HeatmapSchedule = ({ stationsData, stations = [], stationNames, date, detailFilter, dateRange, filterAvailable, transferData, setTransferData }) => {
    const [getRoutesData, { data: routesData, loading, refetch }] = useLazyQuery(GET_HEATMAP_FORECASTS, { fetchPolicy: 'no-cache' });
    const [modalVisible, setModalVisible] = useState(false);
    const [heatmapModalVisible, setHeatmapModalVisible] = useState(false);
    const [heatmapModalData, setHeatmapModalData] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [stationTrucks, setStationTrucks] = useState(null);
    const [routesForecastLoading, setRoutesForecastLoading] = useState(false);
    const [openRequestDrawer, setOpenRequestDrawer] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [showTooltip, setShowTooltip] = useState(true);
    const [routeUpdated, setRouteUpdated] = useState(false);
    const [updateProcessing, setUpdateProcessing] = useState(false);
    const [updatedWithWarning, setUpdatedWithWarning] = useState('');
    const [freightModalVisible, setFreightModalVisible] = useState(false);
    const [freightModalVehicleId, setFreightModalVehicleId] = useState(null);
    const [showTime, setShowTime] = useState(false);
    const [latestForecastEndTime, setLatestForecastEndTime] = useState(null);
    const [view, setView] = useState('TruckView');
    const options = [
        { label: '運行スケジュール', value: 'TruckView' },
        { label: '従業員スケジュール', value: 'DriverView' },
      ];
    
    const history = useHistory();
    const [form] = Form.useForm();
    const [updateRoutesForecast] = useMutation(UPDATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            if (data.updateRoutesForecast.routesForecast) {
                message.success('ルートを変更しました。', 5);
                refetch()
            } else if (data.updateRoutesForecast.errors[0].includes('Departure time must be before arrival time')) {
                message.error('出発時刻は到着時刻より前に設定してください。', 5);
            } else if (data.updateRoutesForecast.overlaps) {
                message.error('ルートが重複しています。', 10);
            } else {
                message.error('ルートを変更できませんでした。');
                refetch()
            }
        },
        onError: () => {
            message.error('ルートを変更できませんでした。', 5);
            refetch()
        }
    });
    const [createPosting] = useMutation(CREATE_POSTING, {
        onCompleted: () => {
            message.success('空きトラック掲示板に投稿しました。');
            refetch()
        },
        onError: (error) => {
            if (error?.networkError?.result?.errors[0]?.message?.includes('overlaps with another forecast')) {
                message.error('選択したトラックは、指定時間に運行スケジュールがあるため、掲示板に投稿できません。');
            } else if (error?.networkError?.result?.errors[0]?.message?.includes('overlaps with another posting')) {
                message.error('その車両は既にポストされています');
            } else {
                message.error('空きトラック掲示板に投稿できませんでした。');
            }
        }
    });
    const { data: freightPostingData, refetch: refetchFreightPostings } = useQuery(GET_FREIGHT_POSTINGS)

    const [updateFreightPosting] = useMutation(UPDATE_FREIGHT_POSTING, {
        onCompleted: (data) => {
            if (data.updateFreightPosting.freightPosting) {
                message.success('運行スケジュールに追加しました。', 5);
                setFreightModalVisible(false)
                refetchFreightPostings()
            }
        },
        onError: () => {
            message.error('運行スケジュールに追加できませんでした。');
        }
    });

    const currentUser = authenticationService.currentUserValue;

    const [archiveRoutesForecast] = useMutation(ARCHIVE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            if (data.archiveRoutesForecast.routesForecast) {
                message.success('ルートを削除しました。');
                refetch()
            }
        }
    });

    useEffect(() => {
        if (!heatmapModalVisible) {
            return
        }
        setHeatmapModalData(false)
    }, [heatmapModalVisible])

    useEffect(() => {
        if (stations.length > 0 && date !== '') {
            getRoutesData({
                variables:  {
                    fromDate: moment().startOf('day').format('YYYY-MM-DD') + 'T00:00:00+09:00',
                    toDate: moment().add(5, 'days').format('YYYY-MM-DD') + 'T00:00:00+09:00',
                    stationIds: stations,
                    filterAvailable: filterAvailable || false,
                    dateRange: dateRange || null,
                }
            });
        }
    }, [getRoutesData, stations, date, dateRange, filterAvailable])
    
    useEffect(() => {
        if (!routesData) {
            return
        }

        const stationTrucks = {} // { stationId: [truck1, truck2, ...], stationId2: [truck1, truck2, ...] } trucks are the objects above
        let noTruckCounter = 1
        let latestEndTime = null
        stations.forEach(stationsId => {
            const trucks = {} // { truckId: [route1, route2, ...], truckId2: [route1, route2, ...] }
            let filteredData = routesData.heatmapForecasts.filter(route => {
                // Filter by station IDs
                const isStation = route.stationId === parseInt(stationsId) || route.vehicleStationId === parseInt(stationsId) || parseInt(route.request[0]?.from?.id) === parseInt(stationsId) || parseInt(route.request[0]?.to.id) === parseInt(stationsId);

                // Filter by opsDate within the next 7 days
                const opsDate = new Date(route.opsDate);
                const currentDate = new Date();
                const sevenDaysFromNow = new Date(currentDate.setDate(currentDate.getDate() + 7));

                return isStation && opsDate < sevenDaysFromNow;
            })


            if (detailFilter?.vehicleType) {
                filteredData = filteredData.filter(route => route.vehicleType === detailFilter.vehicleType)
            }
            if (detailFilter?.maxTruckCapacityStart || detailFilter?.maxTruckCapacityEnd) {
                const start = detailFilter?.maxTruckCapacityStart || 0
                const end = detailFilter?.maxTruckCapacityEnd || 100000
                filteredData = filteredData.filter(route => route.maxTruckCapacity >= start && route.maxTruckCapacity <= end)
            }
            if (detailFilter?.labels?.length > 0) {
                filteredData = filteredData.filter(route => route.label.label.some(label => detailFilter.labels.includes(label)))
            }
            if (detailFilter?.parkedLoad) {
                filteredData = filteredData.filter(route => route.parkedLoad === detailFilter.parkedLoad)
            }
            if (detailFilter?.truckSize?.length > 0) {
                filteredData = filteredData.filter(route => detailFilter.truckSize.includes(getVehicleName(route.driverLicenseClass)))
            }

            let nullTruckCounter = 0
            filteredData.forEach(f => {
                const forecast = {...f}
               
                // if forecast has a rejected request and forecast is pending, skip
                if (forecast.request && forecast.request[0]?.status === "rejected" && forecast.pending) {
                    return
                }

                // add color property to forecast
                forecast.color = colors["Regular"]
                forecast.predFlag && (forecast.color = colors["Regular predicted"])
                if (!forecast.vehicleId) {
                    nullTruckCounter += 1
                    forecast.vehicleId = "NullTruck "+ nullTruckCounter
                }
                forecast.vehicleId === "NoTruck" && (forecast.color = colors["No Truck"])
                //forecast.color === colors["No Truck"] && forecast.predFlag && (forecast.color = colors["No truck predicted"])
                if (forecast.vehicleStationId !== forecast.stationId && forecast.vehicleId !== "NoTruck") {
                    forecast.vehicleStationId === parseInt(stationsId) ? forecast.color = colors["Lent"] : forecast.color = colors["Borrowed"]
                }
                // if forecast truck's label is 空きトラック then color should be empty truck
                if (forecast.label.label[0] === "空きトラック" ) {
                    forecast.color = colors["Empty truck"]
                }

                if (!latestEndTime || new Date(forecast.arrivalTime).getTime() > new Date(latestEndTime).getTime()) {
                    latestEndTime = forecast.arrivalTime
                }
                
                if (forecast.vehicleStationId !== parseInt(stationsId) && forecast.stationId !== parseInt(stationsId) && forecast.request && forecast.request[0]?.status === "sent") {
                    forecast.pending = true
                    forecast.vehicleId = forecast.request[0].truck.vehicleId
                }
                if (forecast.request && forecast.request[0]?.status === "accepted") {
                    const toId = parseInt(forecast.request[0].to.id)
                    const fromId = parseInt(forecast.request[0].from.id)
                    if (toId !== parseInt(stationsId)) {
                        forecast.color = colors["Borrowed"]
                    }
                    if (fromId !== parseInt(stationsId)) {
                        forecast.color = colors["Lent"]
                    }
                }

                if (forecast.request[0]?.truck?.vehicleId && forecast.vehicleId === "NoTruck") {
                    const requestForecast = {...forecast, requestForecast: true}
                    requestForecast.vehicleId = forecast.request[0].truck.vehicleId
                    trucks[requestForecast.vehicleId] ? trucks[requestForecast.vehicleId].push(requestForecast) : trucks[requestForecast.vehicleId] = [requestForecast]
                }
                
               if (view === 'DriverView') {
                    if (forecast.vehicleId === "NoTruck") {    
                        trucks["NoTruck" + noTruckCounter] = [forecast]
                        noTruckCounter++
                    }
                    if (forecast.employeeId) {
                        let key = forecast.employeeId + "=" + forecast.employeeName
                        trucks[key] ? trucks[key].push(forecast) : trucks[key] = [forecast]
                    } else {
                        let key = forecast.vehicleId + '(ドライバー未定)'
                        trucks[key] ? trucks[key].push(forecast) : trucks[key] = [forecast]
                    }
                } else if (view === 'TruckView') {
                    // if forecast is NoTruck, add as separate truck
                    if (forecast.vehicleId === "NoTruck") {    
                        trucks["NoTruck" + noTruckCounter] = [forecast]
                        noTruckCounter++
                    } else {
                        trucks[forecast.vehicleId] ? trucks[forecast.vehicleId].push(forecast) : trucks[forecast.vehicleId] = [forecast]
                    }
                }

                // if truck is borrowed, add borrowed: true to truck object
                if (forecast.color === colors["Borrowed"] && !forecast.pending) {
                    trucks[forecast.vehicleId].borrowed = true
                }
            })

            // remove empty truck routes that overlap with other routes on the same vehicle that aren't pending
            Object.entries(trucks).forEach(([truckId, routes]) => {
                const borrowed = routes.borrowed
                if (truckId.includes('NoTruck')) {
                    return
                }
                const filteredRoutes = routes.filter(route => {
                    if (route.color !== colors["Empty truck"]) {
                        return true
                    }
                    const overlaps = routes.filter(r => {
                        return r.id !== route.id && new Date(r.departureTime).getTime() < new Date(route.arrivalTime).getTime() && new Date(r.arrivalTime).getTime() > new Date(route.departureTime).getTime() && !r.pending
                    })
                    return overlaps.length === 0
                })
                trucks[truckId] = filteredRoutes
                trucks[truckId].borrowed = borrowed
            })

            const trucksArray = Object.entries(trucks).map(([key, value]) => {
                const truckObj = {}
                truckObj[key] = value
                truckObj.borrowed = value.borrowed
                return truckObj
            })
            stationTrucks[stationsId] = trucksArray
        })
        setStationTrucks(stationTrucks)
        setLatestForecastEndTime(latestEndTime)
        setRouteUpdated(false)
    }, [routesData, stations, routeUpdated, detailFilter, view])

    const timeChunks = useMemo(() => {

        let open = new moment().startOf('day').toDate()
        let end = new moment(latestForecastEndTime).toDate()

        if (dateRange) {
            open = new moment(dateRange[0]).toDate()
            end = new moment(dateRange[1]).toDate()
        }
        let diff = moment(end).diff(open, 'days') + 1

        let chunks = []
        for (let i = 0 ; i < 15 * 4 * 24 * diff; i += 15) {
            const m = moment(open).add(i, 'minutes')
            const time = m.toDate()
            chunks.push(time)
        }
        return chunks
    }, [latestForecastEndTime, dateRange])

    const filteredFreightPostings = useMemo(() => {
        if (!freightPostingData) {
            return []
        }
        const filtered = freightPostingData.freightPostings.edges.filter(edge => edge.node.vehicleId && edge.node.status !== "archived")
        return filtered.map(edge => {
            const node = edge.node
            // get number of 15 minute intervals between the start of the time_chunks and the pickup time
            const time_diff = (new Date(node.pickupDate).getTime() - new Date(timeChunks[0]).getTime())/60000
            const time_diff_units = time_diff/15
            const length_minutes = ( new Date(node.deliveryDate).getTime() - new Date(node.pickupDate).getTime())/60000 // convert to minutes
            const length_units = length_minutes / 15
            return {...node, time_diff_units, length_units}
        })
    }, [freightPostingData, timeChunks])

    const vehicleNames = {}
    let noTruckCounter = 1
    let noDriverCounter = 1
    routesData && routesData.heatmapForecasts.forEach(forecast => {
        let noTruck = false
        let noDriver = false
        if (forecast.vehicleId === "NoTruck") {
            noTruck = true
        }
        if (!forecast.employeeId) {
            noDriver = true
        }
        if (noDriver && !noTruck && vehicleNames[forecast.vehicleId]) {
            let vehicleId = forecast.vehicleId
            let vehicleName = `${vehicleId} - ${noDriverCounter} (${forecast.vehicleType} ${getVehicleName(forecast.driverLicenseClass)})`
            vehicleNames[forecast.vehicleId + `${noDriverCounter}`] = vehicleName
            noDriverCounter++
            return
        }
        if (vehicleNames[forecast.vehicleId]) {
            return
        }
        const vehicleId = forecast.vehicleId
        const vehicleName = `${vehicleId} ${noTruck ? '- ' + noTruckCounter: ''}` +
                    `(${forecast.vehicleType} ${getVehicleName(forecast.driverLicenseClass)})`;

        if (noTruck) {
            vehicleNames[forecast.vehicleId + `${noTruckCounter}`] = vehicleName
            noTruck && noTruckCounter++
        } else {
            vehicleNames[forecast.vehicleId] = vehicleName
        }

    })
    vehicleNames['NullTruck'] = "NoTruck - リクエスト"

    const rowHeight = 1.5 // rem
    const columnWidth = 0.75 // rem

    const handleDragStart = (e) => {
        if (!currentUser.admin && currentUser.contractor) return
        setShowTooltip(false)
        setShowTime(true)
        const [routeId, truckId, sourceStationId] = e.target.id.split('-')
        const payload = {
            truckId,
            routeId,
            startX: e.nativeEvent.screenX,
            sourceStationId
        }
        e.dataTransfer.setData("text", JSON.stringify(payload));
        e.dataTransfer.effectAllowed = "move";
    }

    const handleDragOver = (e) => {
        if (!currentUser.admin && currentUser.contractor) return
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowTime(false)
        if (!currentUser.admin && currentUser.contractor) return
        if (e.dataTransfer?.getData("text").trim().length === 0) {
            message.error("システムエラーが発生しました。再度お試しください。", 5)
            setRouteUpdated(true)
            return 'error'
        }
        let { truckId, routeId, startX, sourceStationId } = JSON.parse(e.dataTransfer.getData("text"));
        let [, targetTruckId, targetStationId] = e.target.id.split('-')
        let truckData = stationTrucks[sourceStationId]
        let targetTruckData = stationTrucks[targetStationId]
        const x_diff = e.nativeEvent.screenX - parseInt(startX)
        const widthPx = 16 * columnWidth
        const unit_diff = Math.round(x_diff / widthPx)
        if (view === 'DriverView') {
            if (!targetTruckId || targetTruckId?.includes('ドライバー未定') || targetTruckId?.includes('NoTruck')) {
                setRouteUpdated(true)
                message.error("ドライバーを変更できませんでした。もう一度お試しください。", 10)
                return 'error'
            }
            truckData = stationTrucks[sourceStationId].find(truckObj => Object.keys(truckObj)[0] === truckId)
            targetTruckData = stationTrucks[targetStationId].find(truckObj => Object.keys(truckObj)[0] === targetTruckId)
            const source = truckData[truckId].find(route => route.id === routeId)
            const targetDeparture = moment(source.departureTime).add(unit_diff * 15, 'minutes').format(RFC_3339)
            const targetArrival = moment(source.arrivalTime).add(unit_diff * 15, 'minutes').format(RFC_3339)
            const inputToSend = {
                id: source.id,
                employeeId: targetTruckId.split('=')[0],
                employeeName: targetTruckId.split('=')[1],
                departureTime: targetDeparture,
                arrivalTime: targetArrival
            }
            updateRoutesForecast({
                variables: {
                    input: inputToSend
                }
            }).then((res) => {
                if (!res?.data?.updateRoutesForecast?.overlaps) {
                    truckData[truckId] = truckData[truckId].filter(route => route.id !== routeId)
                    targetTruckData[targetTruckId].push(source)
                    setStationTrucks({...stationTrucks, [sourceStationId]: stationTrucks[sourceStationId], [targetStationId]: stationTrucks[targetStationId]})
                } else {
                    setRouteUpdated(true)
                }
                setUpdateProcessing(false)
                setShowTooltip(true)
            })
            return
        }
        if (!targetTruckData) return 'error'
        const targetTruck = targetTruckData.find(truck => Object.keys(truck)[0] === targetTruckId)
        const sourceTruck = truckData.find(truck => Object.keys(truck)[0] === truckId)
        const sourceRoute = sourceTruck[truckId].find(route => route.id === routeId)
        const targetDepartureTime = moment(sourceRoute.departureTime).add(unit_diff * 15, 'minutes').format('YYYY-MM-DD H:mm')
        const targetArrivalTime = moment(sourceRoute.arrivalTime).add(unit_diff * 15, 'minutes').format('YYYY-MM-DD H:mm')
        const sourceDepartureTime = moment(sourceRoute.departureTime).format('YYYY-MM-DD H:mm')
        const sourceArrivalTime = moment(sourceRoute.arrivalTime).format('YYYY-MM-DD H:mm')
        sourceRoute.arrivalTime = moment(sourceRoute.arrivalTime).add(unit_diff * 15, 'minutes').format(RFC_3339)
        sourceRoute.departureTime = moment(sourceRoute.departureTime).add(unit_diff * 15, 'minutes').format(RFC_3339)
        let sourceCopy = [...truckData]
        let targetCopy = sourceStationId === targetStationId ? [...truckData] : [...stationTrucks[targetStationId]]
        const sourceIndex = String(sourceCopy.indexOf(sourceTruck))
        const targetIndex = String(targetCopy.indexOf(targetTruck))
        if (!targetIndex || !targetTruckId) {
            setRouteUpdated(true)
            message.error("ルートが重複しています。")
            return 'error'
        }

        const sourceRouteTransfer = sourceRoute.stationId !== sourceRoute.vehicleStationId

        const input = {
            id: sourceRoute?.id,
            vehicleId: targetTruckId.includes("NoTruck") ? "NoTruck" : targetTruckId,
            departureTime: sourceRoute?.departureTime,
            arrivalTime: sourceRoute?.arrivalTime
        }
        if (!sourceRouteTransfer) {
            input.stationId = targetStationId
        }

        updateRoutesForecast({
            variables: {
                input
            }
        }).then((res) => {
            if (!res?.data?.updateRoutesForecast?.overlaps) {
                sourceCopy[sourceIndex][truckId] = sourceCopy[sourceIndex][truckId].filter(route => route.id !== routeId)
                targetCopy[targetIndex][targetTruckId].push(sourceRoute)
                setStationTrucks({...stationTrucks, [sourceStationId]: sourceCopy, [targetStationId]: targetCopy})
            } else {
                setRouteUpdated(true)
            }
            setUpdateProcessing(false)
            setShowTooltip(true)
            if (truckId.includes('NoTruck') && (targetArrivalTime !== sourceArrivalTime || targetDepartureTime !== sourceDepartureTime)) {
                setUpdatedWithWarning(`運行時間が変更されました。出発時間： ${sourceDepartureTime} → ${targetDepartureTime}、到着時間： ${sourceArrivalTime} → ${targetArrivalTime}`)
            }
        })
    }

    const handleUpdateRoutesForecast = ({departureTime, arrivalTime}) => {
        setUpdateProcessing(true)
        setModalVisible(false)
        updateRoutesForecast({
            variables: {
                input: {
                    id: modalData.id,
                    departureTime: departureTime.format(RFC_3339),
                    arrivalTime: arrivalTime.format(RFC_3339),
                }
            }
        }).then((res) => {
            setRouteUpdated(true)
            setUpdateProcessing(false)
        })
    }

    useEffect(() => {
        if (history?.location?.posting_id) {
            setFreightModalVisible(true)
        }
    }, [history])

    const handleCreateFreightPostingPosting = () => {
        updateFreightPosting({
            variables: {
                input: {
                    id: history.location.posting_id,
                    vehicleId: freightModalVehicleId
                }
            }
        })
    }

    if (loading || !stationTrucks) {
        return <Spin />
    }

    if (stationTrucks && transferData.truckData) {
        const idx = stationTrucks[transferData.stationId].findIndex(truck => Object.keys(truck)[0] === transferData.vehicleId)
        stationTrucks[transferData.stationId][idx] = transferData.truckData
    }

    const sortedStationTrucks = Object.entries(stationTrucks).map(([stationId, trucks]) => {
        trucks.sort((a, b) => {
            const aKey = Object.keys(a)[0]
            const bKey = Object.keys(b)[0]
            if (aKey.includes('NoTruck')) {
                return 1
            }
            if (bKey.includes('NoTruck')) {
                return -1
            }
            if (aKey.includes('NullTruck')) {
                return 1
            }
            if (bKey.includes('NullTruck')) {
                return -1
            }
            if (aKey.includes('ドライバー未定')) {
                return 1
            }
            if (bKey.includes('ドライバー未定')) {
                return -1
            }
            if (aKey.includes('=') && bKey.includes('=')) {
                return aKey.split('=')[1].localeCompare(bKey.split('=')[1])
            }
            return 0
        })
        return [stationId, trucks]
    })

    return <>
        {sortedStationTrucks.map(([stationId, trucks]) => {
            return (
            <div style={{ marginTop: "1rem", marginBottom: "1rem"}} key={stationId} >
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Typography.Title level={3}>{stationNames.find(s => s.node.id === stationId)?.node.officialName}</Typography.Title>
                    <div>
                        <Radio.Group options={options} onChange={(e) => setView(e.target.value)} value={view} optionType="button" buttonStyle="solid" style={{marginBottom: '1rem', marginRight: '1rem'}}/>
                        <PdfExportButton stationsData={stationsData} stations={[stationId]} stationNames={Object.values(stationsData.stations.edges).filter(data => data.node.id === String(stationId))} date={date} detailFilter={detailFilter}/>
                    </div>
                </div>
            {updateProcessing && <>
                <Alert message="ルートを変更中です。しばらくお待ちください。" type="info"  />
                <Spin size='large' style={{ zIndex: 1, position: 'absolute', marginTop: '30rem', width: '-webkit-fill-available' }}/>
            </>}
            {updatedWithWarning.length > 0 && <Alert message={updatedWithWarning} type="warning" closable onClose={() => setUpdatedWithWarning('')}/>}
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{display: "flex", flexDirection: "column", marginTop: rowHeight * 2 + "rem", padding: '1rem 0rem 0rem 0rem'}}>
                        {trucks.map(truck => {
                            const id = Object.keys(truck)[0]
                            const chartLabel = id.includes('NullTruck') ? vehicleNames["NullTruck"] : id.includes('=') ? id.split('=')[1] : id.includes('ドライバー未定') ? id : vehicleNames[id]
                            return <div key={id} style={{ fontSize: rowHeight/2 + "rem", minHeight: rowHeight + "rem", minWidth: "10rem", boxSizing: "border-box", border: "1px solid lightgrey",
                            backgroundColor: openRequestDrawer && drawerData?.truckId === id && 'yellow', zIndex: 1, display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "center"}}>{chartLabel}</div>
                        })}
                    </div>
                    <div className='routes-schedule' style={{ overflowX: 'scroll', transform: 'rotateX(180deg)', padding: '0rem' }}>
                        <div style={{ transform: 'rotateX(180deg)' }}>
                            <div style={{display: "flex", flexDirection: "column", minHeight: rowHeight + "rem"}}>
                                <div style={{display: "flex", flexDirection: "row", minHeight: rowHeight + "rem"}}>
                                    {timeChunks.reduce((acc, curr, id) => {
                                        const time = curr.getHours()
                                        const minutes = curr.getMinutes()
                                        const dateStr = `${curr.getFullYear()}年${curr.getMonth() + 1}月${curr.getDate()}日(${dowToString(curr.getDay())[0]})`
                                        const value = (time === 0  && minutes === 0) || id === 0 ? <div key={curr.getTime()} style={{fontSize: '16px', textAlign: "center", minWidth: columnWidth * 4 * 24 + "rem", borderLeft: "2px solid lightgrey"}}>{dateStr}</div> : null
                                        return [...acc, value]
                                    }, [])}
                                </div>
                                <div style={{display: "flex", flexDirection: "row", minHeight: rowHeight + "rem"}}>
                                    {timeChunks.reduce((acc, curr) => {
                                        const minutes = curr.getMinutes()
                                        const hour = curr.getHours()
                                        const value = minutes === 0 ? <div key={curr.getTime()} style={{textAlign: "center", minWidth: columnWidth * 4 + "rem", borderLeft: minutes === 0 && hour === 0 ? "2px solid lightgrey" : "1px solid lightgrey", borderBottom: "1px solid lightgray"}}>{hour}</div> : null
                                        return [...acc, value]
                                    }, [])}
                                </div>
                            </div>
                            {trucks.map(truck => {
                                const id = Object.keys(truck)[0] // vehicleId
                                const freightPostings = filteredFreightPostings.filter(posting => posting.vehicleId === parseInt(id))
                                const routes = truck[id].map(route => {
                                    const length_minutes = ( new Date(route.arrivalTime).getTime() - new Date(route.departureTime).getTime())/60000 // convert to minutes
                                    const length_units = length_minutes / 15
                                    // get closest 15 minute chunk to departure time
                                    const closest_chunk = timeChunks.reduce((prev, curr) => {
                                        return (Math.abs(new Date(curr).getTime() - new Date(route.departureTime).getTime()) < Math.abs(new Date(prev).getTime() - new Date(route.departureTime).getTime()) ? curr : prev)
                                    })
                                    // get time difference between closest chunk and departure time
                                    const time_diff = (new Date(route.departureTime).getTime() - new Date(closest_chunk).getTime())/60000
                                    return { ...route, length_minutes, length_units, closest_chunk, time_diff_units: time_diff/15 }
                                })
                                const timeColumns = timeChunks.map((time, index)=> {
                                    const tcRoutes = routes.filter(route => route.closest_chunk.getTime() === time.getTime())
                                    tcRoutes.forEach(route => {
                                        const vehicleName = `${route.vehicleId} (${route.vehicleType} ${getVehicleName(route.driverLicenseClass)})`;
                                        const stationName = stationNames.find(s => s.node.id === String(route.stationId))?.node.officialName
                                        route.vehicleName = vehicleName
                                        route.text = <p style={{marginBottom: "0"}}>
                                            <i>{vehicleName}</i>
                                            <br/><b>ルート名</b>: {route.label.label || ''}
                                            <br/><b>営業所</b>: {stationName}
                                            <br/><b>夜間荷積</b>: {route.parkedLoad}
                                            <br/><b>出発時刻</b>: {route.departureTime.replace('T', ' ').replace('+09:00', '')}
                                            <br/><b>到着時刻</b>: {route.arrivalTime.replace('T', ' ').replace('+09:00', '')}
                                            <br/><b>配送者</b>: {route.employeeName || '未定'}{route.employeeId ? ` (${route.employeeId})` : ''}
                                        </p>
                                    })
                                    const timeColumn =
                                        <div
                                            className='hover-columns'
                                            key={time.toISOString()}
                                            id={time.toString + "-" + id + "-" + stationId}
                                            style={{
                                                minWidth: columnWidth + "rem",
                                                boxSizing: "border-box",
                                                borderBottom: "1px solid lightgrey",
                                                borderLeft: time.getHours() === 0 && time.getMinutes() === 0
                                                                ? "2px solid lightgrey"
                                                                : time.getMinutes() === 0
                                                                ? "1px solid lightgrey"
                                                                : "1px dashed lightgrey",
                                                display: "flex",
                                                position: "relative",
                                                backgroundColor: truck.borrowed && 'darkgrey'
                                            }}
                                            onClick={e => {
                                                if (tcRoutes && tcRoutes.length > 0) {
                                                    return
                                                } else if (view === 'DriverView') {
                                                    let truckId = id.includes('(') ? id.split('(')[0] : null
                                                    let driverId = id.includes('=') ? id.split('=')[0] : null
                                                    let driverName = id.includes('=') ? id.split('=')[1] : null
                                                    setDrawerData({driverId, driverName, stationId, truckId, truck, vehicleType: truck[id][0]?.vehicleType, maxTruckCapacity: truck[id][0]?.maxTruckCapacity, driverLicenseClass: truck[id][0]?.driverLicenseClass, route: null, time})
                                                    setOpenRequestDrawer(true)

                                                } else {
                                                    setDrawerData({stationId, truckId: id, truck, vehicleType: truck[id][0]?.vehicleType, maxTruckCapacity: truck[id][0]?.maxTruckCapacity, driverLicenseClass: truck[id][0]?.driverLicenseClass, route: null, time})
                                                    setOpenRequestDrawer(true)
                                                }
                                            }}
                                            onDrop={e =>{
                                                setUpdateProcessing(true);
                                                const returned = handleDrop(e);
                                                returned === 'error' && setUpdateProcessing(false)

                                            }}
                                            onDragOver={handleDragOver}
                                        >
                                            {showTime && time.getMinutes() === 0 &&
                                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "100%"}}>
                                                    <div style={{fontSize: '12px', color: 'lightgray'}}>{time.getHours()}</div>
                                                </div>
                                            }
                                            {tcRoutes.map(route => {
                                                return <Tooltip title={showTooltip && route.text} placement='bottom' mouseEnterDelay={0} mouseLeaveDelay={0}>
                                                    <div style={{zIndex: route.pending ? 101 : 100, display: "flex", flexDirection: "row", alignItems: "center", marginLeft: columnWidth * route.time_diff_units + "rem", position: "absolute", height: route.pending ? "50%" : "80%", transform: "translateY(-50%)", top: "50%"}}>
                                                        <div
                                                            style={{
                                                                backgroundColor: route.pending ? 'rgba(0,0,0,0)': route.color,
                                                                height: "80%",
                                                                alignSelf: "center",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                minWidth: columnWidth * (route.length_units) + "rem",
                                                                position: "relative",
                                                                zIndex: 1,
                                                                cursor: "move"
                                                            }}
                                                            className={route.pending && 'pending-stripes'}
                                                            draggable
                                                            onDragStart={handleDragStart}
                                                            onDrop={e => {
                                                                setUpdateProcessing(true);
                                                                const returned = handleDrop(e);
                                                                returned === 'error' && setUpdateProcessing(false)
                                                            }}
                                                            onDragOver={handleDragOver}
                                                            onClick={() => {setModalData({...route}); setModalVisible(true); form.setFieldsValue({departureTime: moment(route.departureTime), arrivalTime: moment(route.arrivalTime)})}}
                                                            id={ route.id + "-" + id + "-" + stationId}
                                                        >
                                                            { routesForecastLoading === route.id && <Spin/> }
                                                            <div style={{marginRight: 'auto', fontWeight: 'bold' }}>{`${route.label.label ? view === 'DriverView' ? `${route.vehicleId} ${route.label.label}`: route.label.label : ''}${route.postingId ? '(ポスト中)' : ''}`}</div>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            })}
                                            {index === 0 && freightPostings.length > 0 && freightPostings.map(posting => {
                                                const tooltip = <p style={{marginBottom: "0"}}>
                                                    <b>営業所</b>: {posting.station.officialName}
                                                    <br/><b>伝票番号</b>: {posting.slipNumber}
                                                    <br/><b>集荷日時</b>: {posting.pickupDate.replace('T', ' ').replace('+09:00', '')}
                                                    <br/><b>納品日時</b>: {posting.deliveryDate.replace('T', ' ').replace('+09:00', '')}
                                                    <br/><b>最大積載量</b>: {posting.maxTruckCapacity}
                                                    <br/><b>車種</b>: {posting.vehicleType}
                                                    <br/><b>運賃</b>: {posting.price}
                                                    <br/><b>投稿者</b>: {posting.user.lastName + " " + posting.user.firstName}
                                                    <br/><b>備考</b>: {posting.memo}
                                                </p>
                                                return <Tooltip title={tooltip}>
                                                    <div
                                                        className='freight-stripes'
                                                        key={posting.id}
                                                        style={{
                                                            height: rowHeight*.8 + "rem",
                                                            position: "absolute",
                                                            alignSelf: "center",
                                                            marginLeft: columnWidth * (posting.time_diff_units) + "rem",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            minWidth: columnWidth * (posting.length_units) + "rem",
                                                            zIndex: 102
                                                        }}
                                                    />
                                                </Tooltip>
                                            })}
                                        </div>
                                    return timeColumn
                                })
                                return (<div key={id} style={{display: "flex", flexDirection: "row", height: rowHeight + "rem"}}>{timeColumns}</div>)
                            })}
                        </div>
                    </div>
                </div>
            <Divider/>
        </div>)
    })}
    {modalVisible &&
        <Modal
            title="ルート詳細"
            open={modalVisible}
            onCancel={() => {setModalVisible(false);}}
            width={500}
            footer={<>
                <Button 
                    disabled={modalData.label.label[0] !== "空きトラック"}
                    onClick={() => {
                        createPosting({ variables: { input: {
                            vehicleId: modalData.vehicleId,
                            stationId: modalData.stationId,
                            opsDate: moment(modalData.departureTime).format('YYYY-MM-DD'),
                            startDate: moment(modalData.departureTime).format('YYYY-MM-DDTHH:mm:00.000Z'),
                            endDate: moment(modalData.arrivalTime).format('YYYY-MM-DDTHH:mm:00.000Z'),
                            routesForecastId: modalData.id,
                            status: "available"
                        }}}).then(( )=> setModalVisible(false))
                    }}
                >
                        空き掲示板にポストする
                </Button>
                <Button onClick={() => {
                    setTransferData({labelId: modalData.label.id, departureTime: modalData.departureTime, arrivalTime: modalData.arrivalTime, filterAvailable: true, stationId: modalData.stationId, vehicleId: modalData.vehicleId,
                        truckData: stationTrucks[modalData.stationId].find(truck => Object.keys(truck)[0] === modalData.vehicleId)
                     })
                    setModalVisible(false)
                }}>
                        この時間のトラックを検索する
                </Button>
                <PopconfirmWrapper
                    message='本当に削除しますか？'
                    onConfirm={() => {
                        setRoutesForecastLoading(modalData.id)
                        archiveRoutesForecast({ variables: {input: { id: modalData.id }}})
                        setModalVisible(false)
                    }}
                >
                    <Button type='text' danger>このルートを削除する</Button>
                </PopconfirmWrapper>
                
            </>}
        >
            <Form
                form={form}
                onFinish={handleUpdateRoutesForecast}
            >
                <i>{modalData.vehicleName}</i>
                <br/><div>ルート名: {modalData.label.label || ''}</div>
                <div>夜間荷積: {modalData.parkedLoad}</div>
                    <DatePickerWithTimeInForm 
                        label="出発時刻" 
                        name='departureTime' 
                        initialValue={moment(modalData.departureTime)} 
                        disableDate={(current) => { return current?.isBefore(moment().add(-5, 'days')) || current?.isAfter(moment().add(5, 'days'))}}
                        disabled={modalData.vehicleId === "NoTruck"}
                    />
                    <DatePickerWithTimeInForm
                        style={{display: 'inline-block'}}
                        label="到着時刻"
                        name='arrivalTime'
                        initialValue={moment(modalData.arrivalTime)}
                        disableDate={(current) => { return current?.isBefore(moment().add(-5, 'days')) || current?.isAfter(moment().add(5, 'days'))}}
                        disabled={modalData.vehicleId === "NoTruck"}
                    />
                    <Form.Item style={{display: 'inline-block', marginLeft: '1rem'}} shouldUpdate>
                        { () => (
                            <Button
                                disabled={moment(modalData.departureTime).isSame(form.getFieldValue('departureTime')) && moment(modalData.arrivalTime).isSame(form.getFieldValue('arrivalTime'))}
                                type="primary"
                                htmlType='submit'
                            >
                                時間を変更する
                            </Button>
                        )}
                    </Form.Item>
            </Form>
        </Modal>
    }


    <div style={{display: "flex", justifyContent: "flex-end", margin: "1rem"}}>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#FAA64D"}}></div>
            <Typography>固定ルート</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#439FF8"}}></div>
            <Typography>割付無しルート</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#97F688"}}></div>
            <Typography>予測ルート</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#8CD5FB"}}></div>
            <Typography>空車トラック</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#722ED1"}}></div>
            <Typography>予測ルート（割付未完了）</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#595959"}}></div>
            <Typography>応援トラック（借り）</Typography>
        </div>
        <div className='legend'>
            <div className='circle' style={{backgroundColor: "#EB2F96"}}></div>
            <Typography>応援トラック（貸し）</Typography>
        </div>
    </div>
        {heatmapModalData && <HeatmapModal visible={heatmapModalVisible} fromDate={heatmapModalData.fromDate} toDate={heatmapModalData.toDate} station={stations} vehicle={heatmapModalData.vehicle} setVisible={setHeatmapModalVisible}/>}

        {openRequestDrawer &&
            <HeatmapRequestModal
                visible={openRequestDrawer}
                setVisible={setOpenRequestDrawer}
                truck={drawerData.truck}
                vehicleId={drawerData.truckId}
                vehicleType={drawerData.vehicleType}
                employeeId={drawerData.driverId}
                employeeName={drawerData.driverName}
                maxTruckCapacity={drawerData.maxTruckCapacity}
                driverLicenseClass={drawerData.driverLicenseClass}
                route={transferData.labelId || drawerData?.route}
                paramStationId={transferData.stationId || drawerData.stationId}
                stationsData={stationsData}
                showPostingTab={drawerData.truckId !== "NoTruck"}
                startTime={transferData.departureTime || drawerData.time}
                endTime={transferData.arrivalTime}
                refetch={refetch}
            />
        }
        <Modal open={freightModalVisible} title="運行スケジュールに追加します。" onOk={handleCreateFreightPostingPosting} onClose={() => setFreightModalVisible(false)} onCancel={() => setFreightModalVisible(false)}>
            <Form.Item label="車両番号">
                <Select onChange={e => setFreightModalVehicleId(e)}>
                    { Object.values(stationTrucks)[0].map(truck => {
                        const id = Object.keys(truck)[0]
                        return <Select.Option key={id}>{id}</Select.Option>
                    }) }
                </Select>
            </Form.Item>
        </Modal>
    </>
};

const PopconfirmWrapper = ({children, message, onConfirm}) => {
    const currentUser = authenticationService.currentUserValue;
    const [visible, setVisible] = useState(false);
    if (!currentUser.admin && currentUser.contractor) return null
    return <Popconfirm
        placement='top'
        open={visible}
        onContextMenu={e => {
            e.preventDefault()
            setVisible(true)
        }}
        title={message}
        onConfirm={onConfirm}
        onOpenChange={() => setVisible(prev => !prev)}
        >
        {children}
    </Popconfirm>
}

export default HeatmapSchedule;
