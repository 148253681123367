import React, { useState, useEffect } from 'react';
import { Alert, Button, Typography, Row, Col, Space, Input, Table, Skeleton, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { GET_COMPANY } from '../../queries';
import { UPDATE_COMPANY } from '../../mutations'
import { authenticationService } from '../../Utilities/authenticationService';
import history from '../../Utilities/history';
import AddCompanyModal from './AddCompanyModal';
import NewCompanyModal from './NewCompanyModal';
import DeleteCompaniesButton from './DeleteCompaniesButton';

const CompanyManagement = () => {
    const [currentModal, setCurrentModal] = useState('table');
    const [newCompanyCreated, setNewCompanyCreated] = useState(false);
    const [companiesDeleted, setCompaniesDeleted] = useState(false);
    const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
    const [searchText, setSearchText] = useState('');
    const { data, error, loading, refetch } = useQuery(GET_COMPANY);
    const [updateCompany] = useMutation(UPDATE_COMPANY, {
        onCompleted: (data) => {
            refetch();
        }
    });

    const [rows, setRows] = useState([]);

    const columns = [
        {
            title: <Typography.Text strong>会社名</Typography.Text>,
            dataIndex: 'companyName'
        },
        {
            title: <Typography.Text strong>請負業者である</Typography.Text>,
            dataIndex: 'contractor',
            render: (text, record) => {
                const companyName = record.companyName
                const id = record.key
                const handleContractorSelect = (value) => {
                    updateCompany({ variables: { input: { id, companyName, contractor: value.target.checked }}})
                }
                return (
                    <Checkbox onChange={handleContractorSelect} checked={record.contractor}></Checkbox>
                )
            }
        },
        {
            title: <Typography.Text strong>備考</Typography.Text>,
            dataIndex: 'memo',
            width: 650
        },
    ];


    useEffect(() => {
        if (!loading) {
            setRows(
                data.companies.edges.map(
                    company => {
                        if (company.node.companyName.includes(searchText)) {
                            return {
                                key: company.node.id,
                                companyName: company.node.companyName,
                                contractor: company.node.contractor,
                                memo: company.node.memo,
                            }
                        } else {
                            return false;
                        }
                    }
                ).filter(Boolean).sort((a, b) => a.key - b.key)
            )
        }
    }, [searchText, data, loading])


    if (loading) { return <Skeleton />; }

    if (error) {
        authenticationService.logout();
        history.push('/login');
        return <Skeleton />
    }

    const handleSearch = searchField => {
        setSearchText(searchField.target.value);
    };

    const TableHeader = <React.Fragment>
        <Row>
            <Col align='left' style={{ padding: '0px', background: '#fff' }}>
                <Input.Search onChange={handleSearch} allowClear placeholder="検索" />
            </Col>
            {  JSON.parse(localStorage.currentUser).admin && <Col style={{flex: 1}} align='right'>
                <Space>
                    <Button onClick={ () => setCurrentModal('addCompany') } icon={<PlusOutlined/>}>会社名追加</Button>
                    <DeleteCompaniesButton
                        selectedCompanyIds={selectedCompanyIds}
                        handleCompleted={ (companyInfo) => { setCurrentModal('table'); setCompaniesDeleted(companyInfo); refetch(); }} />
                </Space>
            </Col>}
        </Row>
        <Row>
            { newCompanyCreated &&
                <>
                    <Col span={24}>
                        <Alert message="会社を追加しました。" type="success" showIcon closable/>
                    </Col>
                    <NewCompanyModal companyInfo={newCompanyCreated} />
                </>
            }
            { companiesDeleted &&
                <>
                    <Col span={24}>
                        <Alert
                            message={`"${companiesDeleted.map(c => c.companyName)}"を削除しました。`}
                            type="success"
                            showIcon
                            closable
                        />
                    </Col>
                </>
            }
        </Row>
    </React.Fragment>;

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedCompanyIds(selectedRows.map(row => row.key));
        }
    };

    const returnToTable = () => {
        setCurrentModal('table');
    };

    return <React.Fragment>
        { currentModal === 'table' &&
            <Table
                columns={columns}
                dataSource={rows}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                title={() => TableHeader}
            />
        }
        { currentModal === 'addCompany' &&
            <AddCompanyModal
                handleCompleted={ (companyInfo) => { setCurrentModal('table'); setNewCompanyCreated(companyInfo); refetch(); } }
                returnToTable={returnToTable}
            />
        }
    </React.Fragment>;
};

export default CompanyManagement;
