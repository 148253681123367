import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    Popconfirm,
    Button,
    message
} from 'antd';
import { UPDATE_ROUTE } from '../../mutations';
import {
    CheckCircleOutlined
} from '@ant-design/icons';

const ConfirmLabelingButton = ({ selectedRoutes, rows, refetch }) => {
    const [visible, setVisible] = useState(false);
    const [labelRoute] = useMutation(UPDATE_ROUTE, {
        onCompleted: (data) => {
            message.success('ラベル付けが完了しました。')
            setVisible(false);
            refetch();
        },
        onError: (error) => { console.log(error); }
    });

    const onFinish = () => {
        rows.filter(row => selectedRoutes.includes(row.id)).forEach(
            row => {
                labelRoute({ variables: { input: { id: row.id, labelId: row.label.id }}});
            }
        );
    };

    return <Popconfirm
        title='ラベル付けを完了してもよろしいですか。'
        onConfirm={onFinish}
        onCancel={ () => setVisible(false) }
        okText='はい'
        visible={visible}
        cancelText='いいえ'
    >
        <Button disabled={selectedRoutes.length === 0} onClick={() => setVisible(true)} icon={<CheckCircleOutlined/>}>ラベル付け完了</Button>
    </Popconfirm>
};

export default ConfirmLabelingButton;
