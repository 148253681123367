import React, { useState } from 'react';
import { Alert, Typography, Form, Input, Button, Col } from 'antd';
import { authenticationService } from '../../Utilities/authenticationService';
import './login.less';
import { useParams } from 'react-router-dom';

const CreateNewPassword = ({ history, location }) => {
    const { Title } = Typography;
    const { token } = useParams()
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const onFinish = ({ newPassword, confirmation }) => {
        if (newPassword !== confirmation) {
            setErrorText("パスワードが一致しません。");
            setError(true);
            return;
        }
        setError(false)
        authenticationService.reset_password(newPassword, confirmation, token).then(
            (res) => {
               history.push('/login')
            },
            (err) => {
                console.log(`Reset password error: ${err}`);
                setError(true);
            }
        );
    }

    const [form] = Form.useForm();

    return (
        <div className='login-layout'>
            <Col span={4} className='login-wrapper'>
                <Title level={4}>パスワード再設定</Title>
                <Form form={form} name="newPassword" onFinish={onFinish} initialValues={{ remember: false }}>
                    { error && <Form.Item>
                        <Alert description={errorText} type='error' showIcon closable onClose={() => setError(false)} />
                    </Form.Item> }
                    <Form.Item
                        name="newPassword"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Input placeholder="パスワード" />
                    </Form.Item>
                    
                    <Form.Item
                        name="confirmation"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Input placeholder="パスワード" />
                    </Form.Item>

                    <Form.Item shouldUpdate>
                        { () => (
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                disabled={
                                    !form.isFieldTouched('newPassword') || !form.isFieldTouched('confirmation')}
                            >
                                        送信する
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Col>
        </div>
    );
};



export default CreateNewPassword;
