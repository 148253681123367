import React, { useEffect, useState }from 'react';
import {
    Drawer,
    Form,
    Typography,
    List,
    Comment,
    Input,
    Button,
    Avatar,
    Popconfirm,
    message,
    Select,
    Tag
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { taggify } from '../../Utilities/taggify';
import {
    CREATE_FREIGHT_POSTING_COMMENT,
    UPDATE_FREIGHT_POSTING
} from '../../mutations';
import { GET_FREIGHT_POSTING_COMMENT, GET_FREIGHT_POSTING, GET_USERS } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { authenticationService } from '../../Utilities/authenticationService'
import CommentDrawer from './CommentDrawer';
import CommentList from './CommentList';

const MobileDetailDrawer = ({
    setDetailDrawerOpen,
    detailDrawerOpen,
    postingId,
    refetchList,
    history
}) => {
    const currentUser = authenticationService.currentUserValue;
    const [detailForm] = Form.useForm();
    const [comments, setComments] = useState([]);
    const [memo, setMemo] = useState('');
    const [deliverer, setDeliverer] = useState(null);
    const [posting, setPosting] = useState({});
    const [commenterIds, setCommenterIds] = useState([]);
    const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
    const [selectedCommenterId, setSelectedCommenterId] = useState(null);
    const { loading: userListLoading, error: userListError, data: userListData } = useQuery(GET_USERS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });
    const { refetch } = useQuery(GET_FREIGHT_POSTING, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(postingId) },
        onCompleted: (data) => {
            if (data.freightPosting) {
                setPosting(
                    {
                        key: data.freightPosting.id,
                        id: data.freightPosting.id,
                        slipNumber: data.freightPosting.slipNumber,
                        deliveryDate: moment(data.freightPosting.deliveryDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                        pickupDate: moment(data.freightPosting.pickupDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                        stationName: data.freightPosting.station?.officialName,
                        stationId: String(data.freightPosting.stationId),
                        maxTruckCapacity: data.freightPosting.maxTruckCapacity,
                        vehicleType: data.freightPosting.vehicleType,
                        price: data.freightPosting.price,
                        postedBy: {
                            id: data.freightPosting.user?.id,
                            name: `${data.freightPosting.user?.lastName} ${data.freightPosting.user?.firstName}`,
                            user: data.freightPosting.user,
                        },
                        userId: data.freightPosting.user?.id,
                        companyName: data.freightPosting.company?.companyName,
                        postedDate: data.freightPosting.createdAt,
                        remarks: data.freightPosting.memo,
                        comments: data.freightPosting.freightPostingComments,
                        status: data.freightPosting.status,
                        traboxStatus: data.freightPosting.traboxStatus,
                        deliverer: data.freightPosting.deliverer,
                    }
                )
                setMemo(data.freightPosting.memo);
                setDeliverer(data.freightPosting.deliverer?.id || null);
            }
        }
    });

    const { refetch: refetchComment } = useQuery(GET_FREIGHT_POSTING_COMMENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(postingId) },
        onCompleted: (data) => {
            const fromUserIds = data.freightPosting.freightPostingComments.map(comment => comment.fromUser.id);
            const toUserIds = data.freightPosting.freightPostingComments.map(comment => comment.toUser?.id);
            const commenters = [...new Set([...fromUserIds, ...toUserIds])];
            setCommenterIds(commenters.filter(commenter => parseInt(commenter) !== parseInt(posting?.postedBy?.id)));
            
            const commentArr = data.freightPosting.freightPostingComments.map(comment => ({
                id: comment.id,
                postingId: postingId,
                fromId: comment.fromUser.id,
                toId: comment.toUser?.id,
                author: <Typography.Text strong>{`${comment.fromUser.lastName} ${comment.fromUser.firstName}`}</Typography.Text>,
                avatar: <Avatar>{comment.fromUser.lastName[0]}</Avatar>,
                content: (
                    <div key={comment.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>{comment.commentText}</p>
                        <span style={{ fontSize: '0.8em', color: '#888', alignSelf: parseInt(comment.fromUser.id) !== parseInt(currentUser.id) ? 'flex-start' : 'flex-end' }}>
                            {moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm')}
                        </span>
                    </div>
                ),
                datetime: moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm'),
            }));
            setComments(commentArr);
        }
    });
    const [createFreightPostingComment] = useMutation(CREATE_FREIGHT_POSTING_COMMENT, {
        onCompleted: () => {
            refetch();
            refetchComment();
        }
    });
    const [archiveFreightPosting] = useMutation(UPDATE_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            refetchList();
            setDetailDrawerOpen(false);
            message.success('リクエストを削除しました。')
        }
    });
    const [updateFreightPosting] = useMutation(UPDATE_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            refetchList();
            message.success('リクエストを更新しました。')
        }
    })

    const handlePostingNav = () => {
        const location = {
            pathname: '/truckSchedule',
            posting_id: posting.id,
            station_id: posting.deliverer?.station?.id,
            pickup_date: posting.pickupDate,
            delivery_date: posting.deliveryDate,
        }
        history.push(location)
    }

    const readOnlyStyle = {border: 'none', borderBottom: '1px solid lightgray', width: '100%'}
    const labelStyle = {
        padding: '0'
    };
    useEffect(() => {
        if (posting) {
            detailForm.setFieldsValue({
                id: posting.id,
                stationName: posting.stationName,
                slipNumber: posting.slipNumber || '-',
                pickupDate: moment(posting.pickupDate).locale("ja").format("YYYY年M月D日 H:mm"),
                deliveryDate: moment(posting.deliveryDate).locale("ja").format("YYYY年M月D日 H:mm"),
                maxTruckCapacity: posting.maxTruckCapacity,
                vehicleType: posting.vehicleType,
                price: posting.price,
                memo: posting.remarks,
                postedBy: posting.postedBy?.name,
                deliverer: posting.deliverer?.lastName + ' ' + posting.deliverer?.firstName,
            });
        }
    }
    , [posting, detailForm])

    return (
        <Drawer
            open={detailDrawerOpen}
            width='100vw'
            onClose={() => setDetailDrawerOpen(false)}
            title={
                parseInt(currentUser.id) ===
                    parseInt(posting.deliverer?.id) &&
                        !posting.deliverer?.company?.contractor && (
                    <Button onClick={handlePostingNav} type="primary">
                        運行スケジュールに追加
                    </Button>
                )
            }
            style={{ zIndex: 1000 }}
            extra={
                <>
                    <Tag color='volcano'>ID {posting.id}</Tag>
                    {taggify(posting.status)}
                    {!currentUser.contractor &&
                        (posting.status !== "archived" ? (
                            <Popconfirm
                                title="リクエストを削除しますか？"
                                okText="削除"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    archiveFreightPosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "archived",
                                            },
                                        },
                                    });
                                }}
                            >
                                <DeleteOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        ) : (
                            <Popconfirm
                                title="リクエストを復元しますか？"
                                okText="復元"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    updateFreightPosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "posted",
                                            },
                                        },
                                    });
                                }}
                            >
                                <EditOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        ))}
                </>
            }
        >
            <CommentList 
                posting={posting}
                commenterIds={commenterIds}
                setSelectedCommenterId={setSelectedCommenterId}
                setOpenCommentDrawer={setOpenCommentDrawer}
                userListData={userListData}
            />
            <CommentDrawer
                open={openCommentDrawer}
                setOpen={setOpenCommentDrawer}
                comments={comments.filter(comment => comment.toId === selectedCommenterId || comment.fromId === selectedCommenterId )}
                selectedCommenterId={selectedCommenterId}
                toUser={userListData?.users?.edges?.find(user => user.node.id === selectedCommenterId)?.node}
                postingId={postingId}
                createComment={createFreightPostingComment}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    height: "100%",
                    width: "100%",
                }}
            >
                <Form
                    style={{width: "100%"}}
                    form={detailForm}
                >
                    <Form.Item label="営業所" name="stationName" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="伝票番号" name="slipNumber" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="納品日時" name="pickupDate" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="集荷日時" name="deliveryDate" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="積載重量" name="maxTruckCapacity" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="車種" name="vehicleType" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="運賃" name="price" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    {
                        // If the posting is a draft and the user is the poster, show the memo input field.
                        posting.status === "draft" ? (
                            parseInt(posting.postedBy?.id) ===
                                parseInt(currentUser.id) && (
                                <Form.Item label="備考" name="memo" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                                    <Input.TextArea
                                        placeholder="備考を入力してください。"
                                        minRows = {10}
                                        style = {{height: 180}}
                                        value={memo}
                                        disabled={posting.status !== "draft"}
                                        onChange={(e) => {
                                            setMemo(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            )
                        ) : (
                            // if its not a draft, show the memo.
                            <Form.Item label="備考" name="memo" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                                <Input readOnly style={readOnlyStyle} value={memo}/>
                            </Form.Item>
                        )
                    }
                    <Form.Item label="投稿者" name="postedBy" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    {posting.deliverer && (
                        <Form.Item label="配送者" name="deliverer" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                            <Input readOnly style={readOnlyStyle}/>
                        </Form.Item>
                    )}
                    {posting.status === "draft" && (
                        <Button
                            type="primary"
                            onClick={() => {
                                updateFreightPosting({
                                    variables: {
                                        input: {
                                            id: posting.id,
                                            status: "posted",
                                            memo: memo,
                                        },
                                    },
                                });
                            }}
                        >
                            投稿する
                        </Button>
                    )}
                    {posting.status === "posted" &&
                        // If the user is the poster, show the deliverer selection field
                        parseInt(posting.postedBy?.id) ===
                            parseInt(currentUser.id) && (
                            <div style={{ marginTop: '1rem'}}>
                                <Select
                                    showSearch
                                    placeholder="配送者を入力してください。"
                                    optionFilterProp="label"
                                    onChange={(e) => {
                                        setDeliverer(parseInt(e));
                                    }}
                                >
                                    {!userListLoading && !userListError &&
                                        userListData?.users?.edges?.map((user) => (
                                            <Select.Option
                                                key={user.node.id}
                                                value={user.node.id}
                                                label={`${user.node.lastName} ${user.node.firstName}`}
                                            >
                                                {`${user.node.lastName} ${user.node.firstName}`}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        updateFreightPosting({
                                            variables: {
                                                input: {
                                                    id: posting.id,
                                                    status: "requested",
                                                    delivererId: deliverer,
                                                },
                                            },
                                        });
                                    }}
                                >
                                    配送者決定
                                </Button>
                            </div>
                        )}
                    {posting.status === "requested" && (currentUser.id === parseInt(deliverer) || currentUser.id === parseInt(posting.postedBy.id)) &&(
                        <Button
                            type="primary"
                            style={{ margin: '1rem'}}
                            onClick={() => {
                                updateFreightPosting({
                                    variables: {
                                        input: {
                                            id: posting.id,
                                            status: "delivered",
                                        },
                                    },
                                });
                            }}
                        >
                            配達完了
                        </Button>
                    )}
                </Form>
            </div>
        </Drawer>
    );
}

export const GetCommentList = ({ posting, currentUserId, comments, deleteComment, updateComment }) => {
    const [editComment, setEditComment] = useState(false);
    const [editCommentId, setEditCommentId] = useState(null);
    const [editInput, setEditInput] = useState('');
    
    return <List
        dataSource={comments}
        itemLayout="horizontal"
        style={{ maxHeight: 500, overflow: 'auto' }}
        renderItem={props =>
            <>
                { props.userId === String(currentUserId) &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '15px 10px -32px',
                    }}>
                        {posting.status !== 'archived' &&
                        <>
                            <EditOutlined
                                key={"edit" + props.id}
                                onClick={() => {
                                    setEditComment(true);
                                    setEditCommentId(props.id);
                                    setEditInput(props.content.props.children);
                                }}
                                style={{ marginRight: 10, fontSize: 16, zIndex: 1}}
                                />
                            <Popconfirm
                                title="コメントを削除しますか？"
                                okText="削除"
                                cancelText="キャンセル"
                                onConfirm={() => {
                                    deleteComment({
                                        variables: {
                                            input: {
                                                id: props.id
                                            }
                                        }
                                    })
                                }}
                                >
                                <DeleteOutlined style={{ marginRight: 5, fontSize: 16, zIndex: 1}}/>
                            </Popconfirm>
                        </>}
                    </div>
                }
                <Comment
                    {...props}
                    content={
                        editComment && props.id === editCommentId ?
                        <Form onFinish={
                            ({ editComment }) => {
                                if (editComment && editComment !== editInput) {
                                    updateComment({
                                        variables: {
                                            input: {
                                                id: editCommentId,
                                                commentText: editComment
                                            }
                                        }
                                    })
                                }
                                setEditComment(false);
                                }
                            }
                            style={{ width: '100%'}}
                        >
                            <Form.Item name='editComment'>
                                <div style={{ width: '100%' }}>
                                    <Input.TextArea
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        defaultValue={editInput}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <Button htmlType="button" onClick={() => setEditComment(false)} style={{ marginRight: '1rem'}}>
                                        キャンセル
                                    </Button>
                                    <Button htmlType="submit" type="primary">
                                        保存
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        :
                        props.content
                    }
                    />
                </>
            }
    />
}

export default MobileDetailDrawer;