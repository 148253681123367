import React, { useState, useEffect } from 'react';
import {
    Input,
    Typography,
    Table,
    Col,
    Menu,
    Dropdown,
    Modal,
    DatePicker,
    message,
    Button
} from 'antd';
import { EllipsisOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { toFullWidthKatakana, toHalfWidthKatakana } from '../../Utilities/katakanaWidthConversion';
import { DELETE_SCENARIO, CREATE_SCENARIO, UPDATE_SCENARIO } from '../../mutations';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { localeInDatepicker } from '../../Utilities/datepickerWithTimeInForm';

const ScenariosTable = ({ scenariosData, setScenarioId, setScenarioName, refetch, station }) => {
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [editing, setEditing] = useState(false);
    const [creating, setCreating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        startDate: null,
        endDate: null,
    });
    const rowSelection = {
        selectedRowKeys,
        hideSelectAll: true,
        onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys),
    };

    const [deleteScenario] = useMutation(DELETE_SCENARIO, {
        onCompleted: () => {   
            refetch();
            setModalVisible(false);
        },
        onError: error => {
            console.log('deleteScenario mutation error: ', error);
            message.error('削除に失敗しました');
        }
    });

    const [createScenario, { loading: createScenarioLoading }] = useMutation(CREATE_SCENARIO, {
        onCompleted: () => {
            refetch();
            setModalVisible(false);
            message.success('シナリオを作成しました');
        },
        onError: error => {
            refetch()
            console.log('createScenario mutation error: ', error);
            message.error('シナリオの作成に失敗しました');
        }
    });

    const [updateScenario] = useMutation(UPDATE_SCENARIO, {
        onCompleted: () => {
            refetch();
            setModalVisible(false);
        },
        onError: error => {
            console.log('updateScenario mutation error: ', error);
            message.error('シナリオの更新に失敗しました');
        }
    });

    const handleSearchChange = e => {
        const { value } = e.target;

        setSearchText(value);

        if (value !== '') {
            const filteredRows = originalRows.filter(row => {
                return row.name.toLowerCase().includes(value.toLowerCase())
                    || toFullWidthKatakana(row.name.toLowerCase()).includes(value.toLowerCase())
                        || toHalfWidthKatakana(row.name.toLowerCase()).includes(value.toLowerCase());
            });
            if (selectedRowKeys.length >= 1 && filteredRows.filter(row => selectedRowKeys.includes(row.id)).length === 0) {
                setSelectedRowKeys([]);
            }
            setRows(filteredRows);
        } else {
            setRows(originalRows);
        }
    };

    const openModal = (scenarioId) => {
        const scenario = scenariosData.scenarios.edges.find(scenario => scenario.node.id === scenarioId);
        setSelectedScenario(scenario);
        setModalVisible(true);
    }

    const resetModal = () => {
        setScenarioId(null);
        setScenarioName(null);
        setSelectedScenario(null);
        setFormValues({
            name: '',
            startDate: null,
            endDate: null
        })
        setEditing(false);
        setCreating(false);
        setDeleting(false);
        setDuplicating(false)
    }

    const closeModal = () => {
        setModalVisible(false);
    }

    const handleModalSubmit = () => {
        const { name, startDate, endDate } = formValues;
        if (deleting) {
            deleteScenario({ variables: { input: { id: selectedScenario.node.id } } });
        }
        if (creating || duplicating) {
            createScenario({
                variables: {
                    input: {
                        name,
                        startDate: startDate?.format('YYYY-MM-DD'),
                        endDate: endDate?.format('YYYY-MM-DD'),
                        stationId: station,
                        duplicatedScenarioId: duplicating ? selectedScenario?.node.id : null
                    }
                }
            });
        }
        if (editing) {
            updateScenario({
                variables: {
                    input: {
                        id: selectedScenario.node.id,
                        name,
                        startDate: startDate?.format('YYYY-MM-DD'),
                        endDate: endDate?.format('YYYY-MM-DD')
                    }
                }
            });
        }
    }

    const handleNameChange = e => {
        setFormValues({ ...formValues, name: e.target.value });
    };

    const handleDateRangeChange = (dates) => {
        setFormValues({ ...formValues, startDate: dates[0], endDate: dates[1] });
    };

    const handleEdit = (scenarioId) => {
        setEditing(true);
        openModal(scenarioId);
    }

    const handleDelete = (scenarioId) => {
        setDeleting(true)
        openModal(scenarioId)
    }

    const handleDuplicate = (scenarioId) => {
        setDuplicating(true);
        openModal(scenarioId);
    }

    const handleMenuClick = ({key}, {id}) => {
        switch (key) {
            case 'edit':
                handleEdit(id);
                break;
            case 'delete':
                handleDelete(id);
                break;
            case 'duplicate':
                handleDuplicate(id);
                break;
            default:
                break;
        }
    };

    const DropMenu = (id) => (
        <Menu onClick={(key) => handleMenuClick(key, id)}>
            {id.id !== "1" &&
                <Menu.Item key="edit">シナリオを編集</Menu.Item>
            }
            <Menu.Item key="duplicate">シナリオを複製</Menu.Item>
            {id.id !== "1" &&
                <Menu.Item key="delete">シナリオを削除</Menu.Item>
            }
        </Menu>
    );

    useEffect(() => {
        if (scenariosData) {
            const parsedRows = scenariosData.scenarios.edges.map(scenario => ({
                key: scenario.node.id,
                id: scenario.node.id,
                name: scenario.node.name,
                startDate: scenario.node.startDate,
                endDate: scenario.node.endDate,
                stationId: scenario.node.station?.id,
                dateRange: `${moment(scenario.node.startDate).format('YYYY-MM-DD')} - ${moment(scenario.node.endDate).format('YYYY-MM-DD')}`,
            }));
            const filtered = parsedRows.filter(scenario => (scenario.stationId === station) || scenario.stationId === undefined)
            setRows(filtered);
            setOriginalRows(filtered);
        }
    }, [scenariosData, station]);

    useEffect(() => {
        if (selectedScenario) {
            const { name, startDate, endDate } = selectedScenario.node;
    
            // Set the name based on whether it's a duplicate
            const editingName = duplicating ? name + 'の複製' : name;
    
            setFormValues({
                name: editingName,
                startDate: moment(startDate),
                endDate: moment(endDate),
            });
        }
    }, [selectedScenario, duplicating]);

    const handleRowClick = (row) => {
        setScenarioId(row.id);
        setScenarioName(row.name);
    }

    const routeColumns = [
        {
            title: <Typography.Text strong>シナリオ名</Typography.Text>,
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name, 'ja'),
            render: (text, record) => (
                <Typography.Text style={{ cursor: 'pointer' }} onClick={() => handleRowClick(record)}>
                    {record.name}
                </Typography.Text>
            ),
            width: '30%'
        }, {
            title: <Typography.Text strong>期間</Typography.Text>,
            dataIndex: 'dateRange',
            width: '60%'
        }, {
            title: '',
            dataIndex: 'editDeleteDuplicate',
            render: (text, record) => (
                <Dropdown overlay={<DropMenu id={record.id}/>} trigger={['click']}>
                    <EllipsisOutlined />
                </Dropdown>
            ),
            width: '10%'
        }
    ].filter(Boolean);

    return <>
        <Col span={24} style={{ padding: '10px 24px 0px', background: '#fff' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input.Search placeholder="検索" value={searchText} onChange={handleSearchChange} style={{ width: '20%' }}/>
                <Button icon={<PlusCircleOutlined />} onClick={() => {
                    setCreating(true);
                    openModal();
                }}>シナリオ追加</Button>
            </div>
        </Col>
        <Table
            rowSelection={rowSelection}
            columns={routeColumns}
            style={{ padding: '24px', background: '#fff' }}
            sticky
            scroll={{ y: 700 }}
            pagination={false}
            dataSource={rows}
        />
        <Modal
            title={duplicating ? "シナリオの複製": editing ? "シナリオの編集": creating ? "シナリオの追加" : "シナリオの削除"}
            visible={modalVisible}
            onOk={handleModalSubmit}
            onCancel={closeModal}
            afterClose={resetModal}
            okText={duplicating ? "複製する": editing ? "編集する": creating ? "追加する" : "削除する"}
            okButtonProps={{ danger: deleting, loading: createScenarioLoading }}
            cancelText="キャンセル"
        >
            {deleting && <Typography.Text>シナリオを削除します。よろしいですか？</Typography.Text>}
            {!deleting &&
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                        <Typography.Text strong>シナリオ名:</Typography.Text>
                        <Input value ={formValues.name} onChange={handleNameChange} style={{ width: '80%' }}/>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography.Text strong>期間:</Typography.Text>
                        <DatePicker.RangePicker style={{ width: '80%' }} value={[formValues.startDate, formValues.endDate]} onChange={handleDateRangeChange} locale={localeInDatepicker}/>
                    </div>
                </div>
            }
        </Modal>
    </>
};

export default ScenariosTable;
