import React, { useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import html2canvas from 'html2canvas'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Modal, Button, DatePicker, Typography } from 'antd'
import locale from 'antd/lib/date-picker/locale/ja_JP';
import PdfPreview from './PdfPreview';
import moment from 'moment';

const PdfExportButton = ({stationsData, stations=[], stationNames, detailFilter}) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const [date, setDate] = useState()
    const [showModal, setShowModal] = useState(false)
    const [pdfExporting, setPdfExporting] = useState(false)
    const handlePdfExport = async () => {
        const element = document.getElementById("pdf-export");
        if (!element) {
            return;
        }
        const canvas = await html2canvas(element, {
            scale: 2,
            allowTaint: true,
            useCORS: true,
        })
        const data = canvas.toDataURL();
        const exportSettings = {
            content: [
                {
                    image: data,
                    width: 770,
                    height: 560,
                    margin: [3, -15, 3, 0]
                },
            ],
            pageSize: 'A4',
            pageOrientation: 'landscape'
        };
        pdfMake.createPdf(exportSettings).download(`運行スケジュール.pdf`);
        setShowModal(false);
        setPdfExporting(false);
    }
    const handleDisabledDate = current => {
        return current <= moment().startOf('day') || current > moment().add(5, 'days')
    };
    return (
        <>
            <Button icon={<DownloadOutlined />} onClick={() => setShowModal(true)} loading={pdfExporting}>出力する</Button>
            <Modal
                title="運行スケジュールを出力"
                open={showModal}
                onOk={() => {handlePdfExport(); setPdfExporting(true)}}
                onCancel={() => setShowModal(false)}
                okButtonProps={{ disabled:  pdfExporting  }}
                width={1200}
                height={900}
                bodyStyle={{
                    width: '1200px',
                    height: '900px',
                    overflow: 'auto',
                    padding: '0.5rem'
                }}
                
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography>出力する日付を選択:</Typography>
                    <DatePicker style={{ marginLeft: '.5rem' }} onChange={(date) => setDate(date)} locale={locale} disabledDate={handleDisabledDate}/>
                </div>
                {date && <PdfPreview stationsData={stationsData} stations={stations} stationNames={stationNames} date={date} detailFilter={detailFilter} />}
            </Modal>
        </>
    )
}

export default PdfExportButton