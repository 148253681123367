import React, { useState, useEffect, useCallback } from 'react';
import {
    Input,
    Typography,
    Table,
    Space,
    Row,
    Col,
    Button,
    Select
} from 'antd';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { DowRender } from '../../Utilities/dayOfWeek';
import AddRouteBase from './AddRouteBase';
import EditRouteBase from './EditRouteBase';
import DeleteRouteBase from './DeleteRouteBase';
import * as moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_ROUTE_BASE } from '../../mutations';
import { filterRoutesByDowAndSearchText } from '../../Utilities/searchUtils';

const dowOptions = [{label: '月', value: 0}, {label: '火', value: 1}, {label: '水', value: 2}, {label: '木', value: 3}, {label: '金', value: 4}, {label: '土', value: 5}, {label: '日', value: 6}];

const hoursSort = (a, b)  => {
    return new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b);
};

const RoutesTable = ({ stationId, refetch, routesData, stationsData, scenarioId, scenarioName, setScenarioId }) => {
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [dow, setDow] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [updateRouteBase] = useMutation(UPDATE_ROUTE_BASE, {
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
            refetch();
            console.log(error);
        }
    });

    const rowSelection = {
        selectedRowKeys,
        hideSelectAll: true,
        onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys),
    };

    useEffect(() => {
        const filteredRows = filterRoutesByDowAndSearchText(originalRows, dow, searchText);

        if (selectedRowKeys.length >= 1 && filteredRows.filter(row => selectedRowKeys.includes(row.id)).length === 0) {
            setSelectedRowKeys([]);
        }

        setRows(filteredRows);
    }, [dow, searchText, originalRows, selectedRowKeys]);


    const handleDowSelection = (dow) => {
        setDow(dow)
    }

    const handleSearchChange = value => {
        setSearchText(value);
    };

    const handleSupportOpsToggle = useCallback(
        (selectedRoute) => {
            updateRouteBase(
                {
                    variables: {
                        input: {
                            id: selectedRoute?.node.id,
                            supportOps: !selectedRoute?.node.supportOps
                        }
                    }
                }
            );
        },
        [updateRouteBase]
    );

    const supportOpsToggle = useCallback(
        (route) => {
            return route.node.supportOps ? (
                <CheckCircleFilled 
                    style={{color: "#52C41A", fontSize: "20px"}} 
                    onClick={ () => handleSupportOpsToggle(route) }
                /> 
            ) : (
                <CheckCircleOutlined
                    style={{fontSize: "20px"}} 
                    onClick={ () => handleSupportOpsToggle(route) }
                /> 
            )
        }, 
        [handleSupportOpsToggle]
    )

    useEffect(() => {
        if (routesData) {
            let parsedRows = routesData.routesBases.edges.map(route => ({
                key: route.node.id,
                id: route.node.id,
                opsDow: route.node.opsDow,
                minTruckLoad: route.node.minTruckLoad,
                driveId: route.node.driveId,
                vehicleId: route.node.vehicleId,
                vehicleType: route.node.vehicleType,
                departureDow: route.node.departureDow,
                departureTime: moment(route.node.departureTime).format("HH:mm"),
                arrivalDow: route.node.arrivalDow,
                stationId: stationId,
                arrivalTime: moment(route.node.arrivalTime).format("HH:mm"),
                label: route.node.label.label.join('|'),
                labelId: route.node.label.id,
                parkedLoad: route.node.parkedLoad,
                scenarioId: route.node.scenarioId,
                supportOps: supportOpsToggle(route),
                supportOpsFlag: route.node.supportOps,
                updatedBy: route.node.updatedBy
            }));
            if (scenarioId) {
                parsedRows = parsedRows.filter(row => row.scenarioId === scenarioId);
            }
            parsedRows.sort((a, b) => {
                if (a.opsDow === b.opsDow) {
                    if (a.departureTime === b.departureTime) {
                        return a.arrivalTime.localeCompare(b.arrivalTime, 'ja');
                    }
                    return a.departureTime.localeCompare(b.departureTime, 'ja');
                }
                return a.opsDow - b.opsDow;
            });
            setRows(parsedRows);
            setOriginalRows(parsedRows);
        }
    }, [routesData, stationId, scenarioId, supportOpsToggle])

    const routeColumns = [
        {
            title: <Typography.Text strong>曜日</Typography.Text>,
            dataIndex: 'opsDow',
            sorter: (a, b) => a.opsDow - b.opsDow,
            render: rowContent => <DowRender dayOfWeek={rowContent} />
        }, {
            title: <Typography.Text strong>ルート名</Typography.Text>,
            dataIndex: 'label' ,
            sorter: (a, b) => a.label.localeCompare(b.label, 'ja'),
        }, {
            title: <Typography.Text strong>出発曜日</Typography.Text>,
            dataIndex: 'departureDow',
            sorter: (a, b) => a.departureDow - b.departureDow,
            render: rowContent => <DowRender dayOfWeek={rowContent} />
        }, {
            title: <Typography.Text strong>出発時刻</Typography.Text>,
            dataIndex: 'departureTime',
            sorter: (a, b) => hoursSort(a.departureTime, b.departureTime)
        }, {
            title: <Typography.Text strong>到着曜日</Typography.Text>,
            dataIndex: 'arrivalDow',
            sorter: (a, b) => a.arrivalDow - b.arrivalDow,
            render: rowContent => <DowRender dayOfWeek={rowContent} />
        }, {
            title: <Typography.Text strong>到着時間</Typography.Text>,
            dataIndex: 'arrivalTime',
            sorter: (a, b) => hoursSort(a.arrivalTime, b.arrivalTime)
        }, {
            title: <Typography.Text strong>重量（キロ）</Typography.Text>,
            dataIndex: 'minTruckLoad',
            sorter: (a, b) => a.minTruckLoad - b.minTruckLoad,
        }, {
            title: <Typography.Text strong>車種</Typography.Text>,
            dataIndex: 'vehicleType',
            sorter: (a, b) => a.vehicleType.localeCompare(b.vehicleType, 'ja'),
        }, {
            title: <Typography.Text strong>荷有り</Typography.Text>,
            dataIndex: 'parkedLoad',
            sorter: (a, b) => a.parkedLoad.localeCompare(b.parkedLoad, 'ja'),
        }, {
            title: <Typography.Text strong>応援運行フラグ</Typography.Text>,
            dataIndex: 'supportOps',
            sorter: (a, b) => a.supportOpsFlag === b.supportOpsFlag ? 0 : a.supportOpsFlag ? -1 : 1,
        },{
            title: <Typography.Text strong>編集者</Typography.Text>,
            dataIndex: 'updatedBy',
            width: 150
        }
    ].filter(Boolean);

    return <>
        <Col span={5} style={{ padding: '10px 24px 0px', background: '#fff' }}>
            <Input.Search placeholder="検索" value={searchText} onSearch={(value) => handleSearchChange(value)} onChange={(e) => handleSearchChange(e.target.value)} />
        </Col>
        <Col span={19}>
            <Row justify='end' style={{ padding: '10px 24px 0px', background: '#fff' }}>
                <Space>
                    <Typography.Text>シナリオ名：{scenarioName}</Typography.Text>
                    <Button type="normal" onClick={() => setScenarioId(null)}>シナリオ選択に戻る</Button>
                    <div id="dow-select-wrapper">
                        <Select placeholder="曜日を選択する" id="dow-selector" onChange={handleDowSelection} options={dowOptions} allowClear/>
                    </div>
                    <AddRouteBase refetch={refetch} stationsData={stationsData} scenarioId={scenarioId} selectedStation={stationId}/>
                    <EditRouteBase
                        refetch={refetch}
                        disabled={selectedRowKeys.length !== 1}
                        selectedRoute={selectedRowKeys.length === 1 && rows.filter(row => row.id === selectedRowKeys[0])[0]}
                        stationsData={stationsData}
                    />
                    <DeleteRouteBase refetch={refetch} selectedRoutes={selectedRowKeys} />
                </Space>
            </Row>
        </Col>
        <Table
            rowSelection={rowSelection}
            columns={routeColumns}
            style={{ padding: '24px', background: '#fff' }}
            sticky
            scroll={{ y: 700 }}
            pagination={false}
            dataSource={rows}
        />
    </>
};

export default RoutesTable;
