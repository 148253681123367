import React, { useState, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
    Select,
    Button,
    Modal,
    Form,
    Popover,
    message,
    Input
} from 'antd';
import { CREATE_LABEL } from '../../mutations';
import {
    PlusCircleOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import EditableTagGroup from './EditableTagGroup';

const AddRouteName = ({ stationsData, refetch, selectedStation }) => {
    const [label, setLabel] = useState('');
    const [memo, setMemo] = useState('');
    const [createLabel] = useMutation(CREATE_LABEL, {
        onCompleted: (data) => {
            message.success('ルート名を送信しました。');
            setVisible(false);
            form.resetFields();
            refetch();
        },
        onError: (error) => {
            message.error('同じルート名が既に存在します。');
            console.log(error);
        }
    });

    const onFinish = ({ stationId = selectedStation }) => {
        createLabel({ variables: { input: { label, stationId, memo }}});
    };

    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    const handleClose = () => {
        setVisible(false);
        setLabel('');
    }

    useEffect(() => {
        forceUpdate({});
    }, []);

    const labelError = useMemo(() => {
        return label.includes('｜')
    }, [label])

    const helpContent = <>
        <p>
            以下の基本ルールに従ってルート名を追加してください。
        </p>
        <ol>
            <li>ルート名は荷卸の地点をもとに作成</li>
            <li>後日トラック番号が変更になる可能性があるためルート名にはトラックの番号を使わない</li>
            <li>業者から特定の番号が割り振られているルートはその番号を使用（例：循環１０５便）</li>
            <li>同じ目的地で違う出発時間の便も同じルート名をつける</li>
            <li>ルート名には「出入庫」は付けない（ルートデータから判別ができないため）</li>
            <li>循環ルートを分割してカバーしているルートは（前、中、後）を使用（例：循環１０５便前）</li>
            <li>荷卸地点の工場は第２工場から番号（埼玉１は「埼玉」埼玉２は「埼玉２」)</li>
            <li>武蔵野：工場に立ち寄るばあいはアルファベット＋工場番号を使う（例：S1武蔵野 = 埼玉第一工場積み、武蔵野ルート) </li>
            <li>帰便のみのカバーの場合はルート名＋帰便と記入（例：春古河帰便）</li>
            <li>ルート名には記号を使用しない（・&%$#@!?|^-全角半角スペースなど。自動入力禁止設定予定）</li>
            <li>ルート名は省略しない（例：横浜１と２をカバーする場合は横浜１,横浜２と記入) </li>
        </ol>
    </>;

    return <>
        <Button onClick={() => setVisible(true)} icon={<PlusCircleOutlined/>}>ルート名追加</Button>
        <Modal
            title="ルート名の追加"
            open={visible}
            onOk={handleClose}
            onCancel={handleClose}
            destroyOnClose
            footer={null}
        >
            <Form
                name='request'
                form={form}
                layout='horizontal'
                onFinish={onFinish}
            >
                <Form.Item
                    colon={false}
                    label={<>
                        必ず基本ルールに従ってルート名を追加してください。
                        <Button icon={<InfoCircleOutlined />} type='link'>
                            <Popover title='基本ルール' trigger='click' content={helpContent}>
                                基本ルール
                            </Popover>
                        </Button>
                    </>}
                >
                </Form.Item>
                <Form.Item
                    label="ルート名"
                    name='label'
                >
                    <EditableTagGroup setRouteName={(tags) => setLabel(tags.join(','))}/>
                </Form.Item>
                <Form.Item
                    label="メモ"
                    name='memo'
                    >
                    <Input.TextArea placeholder="記入してください"
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="営業所"
                    name='stationId'
                    rules={[{ message: '営業所を入力してください。' }]}
                >
                    <Select
                        disabled={true}
                        defaultValue={selectedStation}
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 200 }}
                    >
                        {
                            stationsData.stations.edges.map(
                                station => (
                                    <Select.Option key={station.node.id} value={station.node.id}>
                                        {station.node.officialName}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item shouldUpdate>
                    { () => (
                        <Button
                            disabled={ label.length === 0 ||
                                (!form.isFieldTouched('stationId') && !selectedStation) ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length || labelError}
                            type="primary"
                            htmlType='submit'
                        >
                            追加する
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    </>

};

export default AddRouteName;
