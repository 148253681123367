import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Spin, Tooltip, Radio, Typography } from 'antd';
import { ArrowLeftOutlined, ScheduleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { dowToString } from '../../Utilities/dayOfWeek';
import { getVehicleName, colors } from '../TruckSchedule/NewHeatmapSchedule'
import SimulationStats from './SimulationStats';
import { useLazyQuery } from '@apollo/client';
import { GET_DAILY_EMPTY_TRUCKS } from '../../queries';

const SimulationDetail = ({ setShowSimulationDetail, presignedDownloadUrl, stationsData, selectedSimulation, scenariosData }) => {
    const [stationTrucks, setStationTrucks] = useState(null);
    const [earliestAndLatestForecastEndTime, setEarliestAndLatestForecastEndTime] = useState([]);
    const [vehicleNames, setVehicleNames] = useState({})
    const [activeTab, setActiveTab] = useState('detail')
    const [stats, setStats] = useState(null)
    const [stationIds, setStationIds] = useState([])
    const [getDailyEmptyTrucks, { data: dailyEmptyTrucks }] = useLazyQuery(GET_DAILY_EMPTY_TRUCKS, {
        fetchPolicy: 'network-only'
    })
    const [titleString, setTitleString] = useState('')
    
    const timeChunks = useMemo(() => {
        let open = new moment(earliestAndLatestForecastEndTime[0]).toDate()
        let end = new moment(earliestAndLatestForecastEndTime[1]).endOf('day').toDate()
        let diff = moment(end).diff(open, 'days') + 1

        let chunks = []
        for (let i = 0 ; i < 15 * 4 * 24 * diff; i += 15) {
            const m = moment(open).add(i, 'minutes')
            const time = m.toDate()
            chunks.push(time)
        }
        return chunks
    }, [earliestAndLatestForecastEndTime])

    const getEarliestAndLatestEndTime = (jsonObjects) => {
        let latestEndTime = [null, null];
        jsonObjects.forEach((object) => {
            if (!latestEndTime[0] || new Date(object.departure_time).getTime() < new Date(latestEndTime[0]).getTime()) {
                latestEndTime[0] = new Date(object.departure_time);
            }
            if (!latestEndTime[1] || new Date(object.arrival_time).getTime() > new Date(latestEndTime[1]).getTime()) {
                latestEndTime[1] = new Date(object.arrival_time);
            }
        });
        return latestEndTime
    }

    const downloadAndParseJson = useCallback(async () => {
        await axios({ url: presignedDownloadUrl, method: 'GET', responseType: 'blob' })
            .then((response) => {
                const reader = new FileReader();
                reader.readAsText(response.data);
                reader.onload = async () => {
                    const data = reader.result;
                    const jsonStrings = data.split('\n');
                    const jsonObjects = jsonStrings.map(jsonString => {
                        try {
                            return JSON.parse(jsonString);
                        } catch (error) {
                            console.error('Error parsing JSON string:', jsonString, error);
                            return null;
                        }
                    }).filter(jsonObject => jsonObject !== null); // Remove nulls if any parse errors occurred
                    if (jsonObjects.length > 0) {
                        let parsedData = {};
                        let vehicleNames = {}
                        let noTruckCounter = 1
                        let nullTruckCounter = 1
                        
                        setEarliestAndLatestForecastEndTime(getEarliestAndLatestEndTime(jsonObjects))

                        await jsonObjects.forEach((forecast) => {
                            const stationId = forecast.station_id;
                            let vehicleId = forecast.vehicle_id;

                            if (parsedData[stationId] === undefined) {
                                parsedData[stationId] = [];
                            }
                            const stationData = parsedData[stationId];
                            const stationDataIndex = stationData.findIndex((data) => data[vehicleId] !== undefined);
                            if (stationDataIndex > -1) {
                                stationData[stationDataIndex][vehicleId].push(forecast);
                            } else if (vehicleId !== "NoTruck") {
                                stationData.push({ [vehicleId]: [forecast] });
                            }

                            // color 
                            let color = colors['Regular']
                            if (forecast.label?.join('') === '空きトラック') {
                                color = colors['Empty truck']
                            } 
                            if (forecast.pred_flag) {
                                color = colors['Regular predicted']
                            }
                            forecast.color = colors["Regular"]
                            forecast.predFlag && (forecast.color = colors["Regular predicted"])
                            if (!forecast.vehicle_id) {
                                nullTruckCounter += 1
                                forecast.vehicle_id = "NullTruck "+ nullTruckCounter
                            }
                            forecast.vehicle_id === "NoTruck" && (forecast.color = colors["No Truck"])
                            //forecast.color === colors["No Truck"] && forecast.predFlag && (forecast.color = colors["No truck predicted"])
                            if (forecast.vehicle_station_id !== forecast.station_id && forecast.vehicle_id !== "NoTruck") {
                                forecast.vehicle_station_id === parseInt(forecast.station_id) ? forecast.color = colors["Lent"] : forecast.color = colors["Borrowed"]
                            }

                            forecast.color = color

                            // convert to Japan time
                            forecast.arrival_time = new Date(forecast.arrival_time).toLocaleDateString('ja-JP', {hour: '2-digit', minute: '2-digit'})
                            forecast.departure_time = new Date(forecast.departure_time).toLocaleDateString('ja-JP', {hour: '2-digit', minute: '2-digit'})
                            
                            let noTruck = false
                            if (vehicleId === "NoTruck") {
                                noTruck = true
                            }
                            if (vehicleNames[forecast.vehicle_id]) {
                                return
                            }
                            const vehicleType = forecast.vehicle_type
                            const vehicleSize = getVehicleName(forecast.driver_license_class)
                            const vehicleDetail = `${vehicleType} ${vehicleSize}` === '- -' ? '(タイプ登録なし)' : `(${vehicleType} ${vehicleSize})`
                            const vehicleName = `${vehicleId} ${noTruck ? '- ' + noTruckCounter: ''}` + vehicleDetail
                            forecast.vehicleName = vehicleName

                            if (noTruck) {
                                vehicleId = forecast.vehicle_id + `${noTruckCounter}`
                                vehicleNames[vehicleId] = vehicleName
                                stationData.push({ [vehicleId]: [forecast] });
                                noTruckCounter++
                            } else {
                                vehicleNames[forecast.vehicle_id] = vehicleName
                            }

                        });

                        // sort 
                        Object.entries(parsedData).forEach(([stationId, trucks]) => {
                            parsedData[stationId] = trucks.sort((a, b) => {
                                const aId = Object.keys(a)[0]
                                const bId = Object.keys(b)[0]
                                if (aId.includes('NoTruck')) {
                                    return 1
                                } else if (bId.includes('NoTruck')) {
                                    return -1
                                } else {
                                    return 0
                                }
                            })
                        })
                        setStationTrucks(parsedData)
                        setStationIds(Object.keys(parsedData))
                        setVehicleNames(vehicleNames)
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    const reader = new FileReader();
                    reader.readAsText(error.response.data);
                    reader.onload = () => {
                      console.log(reader.result);
                    };
                }
            });
        }, [presignedDownloadUrl])

        useEffect(() => {
            downloadAndParseJson();
        }, [downloadAndParseJson])

        useEffect(() => {
            if (earliestAndLatestForecastEndTime.length > 0 && stationIds.length > 0) {
                getDailyEmptyTrucks({
                    variables: {
                        fromDate: earliestAndLatestForecastEndTime[0],
                        toDate: earliestAndLatestForecastEndTime[1],
                        stationIds
                    }
                })
            }
        }, [earliestAndLatestForecastEndTime, stationIds, getDailyEmptyTrucks])
        useEffect(() => {
            if (stationTrucks && dailyEmptyTrucks) {
                const stats = Object.entries(stationTrucks).reduce((acc, [stationId, trucks]) => {
                    const normalRoutes = {}
                    const lendRoutes = {}
                    const emptyTruckSimulated = {}
                    const emptyTruckScheduled = {}
                    trucks.forEach(truck => {
                        const id = Object.keys(truck)[0]
                        truck[id].forEach(route => {
                            const curr = new moment(route.departure_time).toDate()
                            const date = `${curr.getFullYear()}年${curr.getMonth() + 1}月${curr.getDate()}日(${dowToString(curr.getDay())[0]})`
                            if (route.label?.join('') === '空きトラック') {
                                emptyTruckSimulated[date] ? emptyTruckSimulated[date] += 1 : emptyTruckSimulated[date] = 1
                            }
                            
                            if (route.vehicle_station_id !== route.station_id) {
                                lendRoutes[date] ? lendRoutes[date] += 1 : lendRoutes[date] = 1
                            } else {
                                normalRoutes[date] ? normalRoutes[date] += 1 : normalRoutes[date] = 1
                            }
                        })
                    })
                    acc[stationId] = { normalRoutes, lendRoutes, emptyTruckSimulated, emptyTruckScheduled }
                    return acc
                }, {})
                const parsedEmptyTruck = JSON.parse(dailyEmptyTrucks.dailyEmptyTrucks)
                Object.entries(parsedEmptyTruck).forEach(([stationId, dates]) => {
                    Object.entries(dates).forEach(([date, count]) => {
                        const curr = new moment(date).toDate()
                        date = `${curr.getFullYear()}年${curr.getMonth() + 1}月${curr.getDate()}日(${dowToString(curr.getDay())[0]})`
                        stats[stationId].emptyTruckScheduled[date] = count
                    })
                })
               
                let statsObjects = {}
                stats && Object.entries(stats).forEach(([stationId, columns]) => {
                    const dates = Object.keys(columns.normalRoutes)
                    dates.forEach(date => {
                        const normalRoutes = columns.normalRoutes[date]
                        const lendRoutes = columns.lendRoutes[date]
                        const emptyTruckSimulated = columns.emptyTruckSimulated[date]
                        const emptyTruckScheduled = columns.emptyTruckScheduled[date]
                        const statsObj = { normalRoutes, lendRoutes, emptyTruckSimulated, emptyTruckScheduled, date }
                        const stationName = stationsData?.stations?.edges?.find(s => s.node.id === stationId)?.node.officialName
                        const selectedStationIndex = selectedSimulation.station.indexOf(stationId)
                        const scenarioName = scenariosData?.scenarios?.edges?.find(s => s.node.id === selectedSimulation.scenario[selectedStationIndex])?.node.name
                        const titleStr = `営業所：${stationName}-シナリオ：${scenarioName}`
                        if (statsObjects[titleStr]) {
                            statsObjects[titleStr].push(statsObj)
                        } else {
                            statsObjects[titleStr] = [statsObj]
                        }
                    })
                })
                setStats(statsObjects)
            }
        }, [stationTrucks, dailyEmptyTrucks, stationsData, scenariosData, selectedSimulation])

        useEffect(() => {
            if (selectedSimulation && stationIds) {
                const scenarioIds = selectedSimulation.scenario
                const scenarioNames = scenarioIds.map(id => scenariosData?.scenarios?.edges?.find(s => s.node.id === id)?.node.name).join('/')
                const stationNames = stationIds.map(id => stationsData?.stations?.edges?.find(s => s.node.id === id)?.node.officialName).join('/')

                setTitleString(`営業所：${stationNames}-シナリオ：${scenarioNames}`)
            }
        }, [selectedSimulation, stationIds, scenariosData, stationsData])

        const rowHeight = 1.5 // rem
        const columnWidth = 0.75 // rem
        const colStyle = { fontSize: rowHeight/2 + "rem", minHeight: rowHeight + "rem", minWidth: "5rem", boxSizing: "border-box", border: "1px solid lightgrey",
                            zIndex: 1, display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "center" }
        const SimulatedTruckSchedule = () => {
            return (<>
                <Typography.Title style={{marginTop: '1rem'}} level={5}>{titleString.split('-')[0]}　{titleString.split('-')[1]}</Typography.Title>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{marginTop: rowHeight * 2 + 'rem'}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={colStyle}>営業所</div>
                            <div style={colStyle}>車両営業所</div>
                            <div style={{...colStyle, minWidth: '11rem'}}>車両</div>
                        </div>
                        { Object.entries(stationTrucks).map(([stationId, trucks], idx) => {
                            return <div key={idx} style={{display: "flex", flexDirection: "column"}}>
                                {trucks.map((truck, i) => {
                                    const id = Object.keys(truck)[0]
                                    const vehicleStationId = Object.entries(truck)[0][1][0].vehicle_station_id
                                    const chartLabel = id.includes('NullTruck') ? vehicleNames["NullTruck"] : vehicleNames[id]
                                    const isNoTruck = chartLabel && id.includes('NoTruck')
                                    const stationName = stationsData?.stations?.edges?.find(s => s.node.id === stationId)?.node.officialName.slice(0, -6)
                                    const vehicleStationName = isNoTruck ? '-' : stationsData?.stations?.edges?.find(s => s.node.id === String(vehicleStationId))?.node.officialName.slice(0, -6)
                                    return (<div key={`${idx}-${i}-${id}`} style={{display: "flex", flexDirection: "row"}} >
                                        <div key={`${id}-stationName`} style={colStyle}>{stationName}</div>
                                        <div key={`${id}-vehicleStationName`} style={colStyle}>{vehicleStationName || stationName}</div>
                                        <div key={`${id}-chartLabel`} style={{...colStyle, minWidth: '11rem'}}>{chartLabel}</div>
                                    </div>)
                                })}
                            </div>
                        })}
                    </div>
                    <div className='routes-schedule' style={{ overflowX: 'scroll', transform: 'rotateX(180deg)', padding: '0rem' }}>
                        <div style={{ transform: 'rotateX(180deg)' }}>
                            <div style={{display: "flex", flexDirection: "column", minHeight: rowHeight + "rem"}}>
                                <div style={{display: "flex", flexDirection: "row", minHeight: rowHeight + "rem"}}>
                                    {timeChunks.reduce((acc, curr) => {
                                        const time = curr.getHours()
                                        const minutes = curr.getMinutes()
                                        const dateStr = `${curr.getFullYear()}年${curr.getMonth() + 1}月${curr.getDate()}日(${dowToString(curr.getDay())[0]})`
                                        const value = time === 0  & minutes === 0 ? <div key={curr.getTime()} style={{fontSize: '16px', textAlign: "center", minWidth: columnWidth * 4 * 24 + "rem", borderLeft: "2px solid lightgrey"}}>{dateStr}</div> : null
                                        return [...acc, value]
                                    }, [])}
                                </div>
                                <div style={{display: "flex", flexDirection: "row", minHeight: rowHeight + "rem"}}>
                                    {timeChunks.reduce((acc, curr) => {
                                        const minutes = curr.getMinutes()
                                        const hour = curr.getHours()
                                        const value = minutes === 0 ? <div key={curr.getTime()} style={{textAlign: "center", minWidth: columnWidth * 4 + "rem", borderLeft: minutes === 0 && hour === 0 ? "2px solid lightgrey" : "1px solid lightgrey", borderBottom: "1px solid lightgray"}}>{hour}</div> : null
                                        return [...acc, value]
                                    }, [])}
                                </div>
                            </div>
                            { Object.entries(stationTrucks).map(([stationId, trucks]) => {
                                return (<>
                                    {trucks.map(truck => {
                                        const id = Object.keys(truck)[0]
                                        const routes = truck[id].map(route => {
                                            const length_minutes = ( new Date(route.arrival_time).getTime() - new Date(route.departure_time).getTime())/60000 // convert to minutes
                                            const length_units = length_minutes / 15
                                            // get closest 15 minute chunk to departure time
                                            const closest_chunk = timeChunks.reduce((prev, curr) => {
                                                return (Math.abs(new Date(curr).getTime() - new Date(route.departure_time).getTime()) < Math.abs(new Date(prev).getTime() - new Date(route.departure_time).getTime()) ? curr : prev)
                                            })
                                            // get time difference between closest chunk and departure time
                                            const time_diff = (new Date(route.departure_time).getTime() - new Date(closest_chunk).getTime())/60000
                                            return { ...route, length_minutes, length_units, closest_chunk, time_diff_units: time_diff/15 }
                                        })
                                        const timeColumns = timeChunks.map((time, index)=> {
                                            const route = routes.find(route => route.closest_chunk.getTime() === time.getTime())
                                            const chartLabel = id.includes('NullTruck') ? vehicleNames["NullTruck"] : vehicleNames[id]
                                            if (route) {
                                                const stationName = stationsData?.stations?.edges?.find(s => s.node.id === String(route.station_id))?.node.officialName
                                                route.text = <p style={{marginBottom: "0"}}>
                                                    <i>{chartLabel}</i>
                                                    <br/><b>ルート名</b>: {route.label?.join('') || ''}
                                                    <br/><b>営業所</b>: {stationName}
                                                    <br/><b>夜間荷積</b>: {route.parked_load}
                                                    <br/><b>出発時刻</b>: {route.departure_time}
                                                    <br/><b>到着時刻</b>: {route.arrival_time}
                                                </p>
                                            }
                                            const timeColumn =
                                                <div
                                                    className='hover-columns'
                                                    key={time.toISOString()}
                                                    id={time.toString + "-" + id + "-" + stationId}
                                                    style={{
                                                        minWidth: columnWidth + "rem",
                                                        boxSizing: "border-box",
                                                        borderBottom: "1px solid lightgrey",
                                                        borderLeft: time.getHours() === 0 && time.getMinutes() === 0
                                                                        ? "2px solid lightgrey"
                                                                        : time.getMinutes() === 0
                                                                        ? "1px solid lightgrey"
                                                                        : "1px dashed lightgrey",
                                                        display: "flex",
                                                        position: "relative"
                                                    }}
                                                >
                                                    { route &&
                                                        <Tooltip title={route.text} placement='bottom' mouseEnterDelay={0} mouseLeaveDelay={0}>
                                                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginLeft: columnWidth * route.time_diff_units + "rem"}}>
                                                                <div
                                                                    style={{
                                                                        backgroundColor: route.color,
                                                                        height: route.pending ? "50%" : "80%",
                                                                        alignSelf: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        minWidth: columnWidth * (route.length_units) + "rem",
                                                                        position: "relative",
                                                                        zIndex: 1,
                                                                        cursor: "move"
                                                                    }}
                                                                    className={route.pending ? 'pending-stripes' : ''}
                                                                    id={ route.id + "-" + id + "-" + stationId}
                                                                >
                                                                    <div style={{marginRight: 'auto', fontWeight: 'bold' }}>{`${route.label?.join('') || ''}${route.postingId ? '(ポスト中)' : ''}`}</div>
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            return timeColumn
                                        })
                                        return (<div key={id} style={{display: "flex", flexDirection: "row", height: rowHeight + "rem"}}>{timeColumns}</div>)
                                    })}
                                </>)
                            })}
                        </div>
                    </div>
                </div>
            </>)
        }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    onClick={() => setShowSimulationDetail(false)}
                    icon={<ArrowLeftOutlined />}
                >
                    戻る
                </Button>
                <Radio.Group
                    style={{marginLeft: '2rem'}}
                    defaultValue={activeTab}
                    buttonStyle="solid"
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <Radio.Button value="detail"><ScheduleOutlined />{' '}詳細スケジュール</Radio.Button>
                    <Radio.Button value="stats"><UnorderedListOutlined />{' '}表データ</Radio.Button>
                </Radio.Group>
            </div>

            {activeTab === 'stats' ?
                <SimulationStats stats={stats} stationsData={stationsData} selectedSimulation={selectedSimulation} scenariosData={scenariosData}/>
                : stationTrucks ? <SimulatedTruckSchedule /> 
                : <Spin style={{marginLeft: '2rem'}} />}
        </>
    )
}

export default SimulationDetail;