import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
    Select,
    Space,
    TimePicker,
    Button,
    Modal,
    Form,
    message
} from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { GET_LABELS, GET_FORECAST_AVAILABLE_TRUCKS } from '../../queries';
import { UPDATE_ROUTE_BASE, UPDATE_ROUTES_FORECAST } from '../../mutations';
import { useLazyQuery } from '@apollo/client';
import { dowDictionary } from '../../Utilities/dayOfWeek';
import { minTruckLoadDictionary } from '../../Utilities/minTruckLoad';
import {
    EditOutlined
} from '@ant-design/icons';
import * as moment from 'moment';

const EditRouteBase = ({ disabled, stationsData, selectedRoute, refetch }) => {
    const [getLabelsData, { data: labelsData }] = useLazyQuery(GET_LABELS);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.
    const [updateRouteBase] = useMutation(UPDATE_ROUTE_BASE, {
        onCompleted: (data) => {
            if (data.updateRouteBase?.forecast?.vehicleId === "NoTruck") {
                setForecast(data.updateRouteBase?.forecast)
                setOpenReplacementTruckModal(true)
            }
            message.success('ルートを変更しました。');
            setVisible(false);
            form.resetFields();
            refetch();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [updateRoutesForecast] = useMutation(UPDATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            message.success('ルートを変更しました。');
            setOpenReplacementTruckModal(false);
            setForecast(undefined);
        }
    });


    const [stationId, setStationId] = useState(selectedRoute?.stationId);
    const [opsDow, setOpsDow] = useState(selectedRoute?.opsDow);
    const [labelId, setLabelId] = useState(selectedRoute?.labelId);
    const [departureDow, setDepartureDow] = useState(selectedRoute?.departureDow);
    const [departureTime, setDepartureTime] = useState(selectedRoute?.departureTime);
    const [arrivalDow, setArrivalDow] = useState(selectedRoute?.arrivalDow);
    const [arrivalTime, setArrivalTime] = useState(selectedRoute?.arrivalTime);
    const [vehicleType, setVehicleType] = useState(selectedRoute?.vehicleType);
    const [minTruckLoad, setMinTruckLoad] = useState(selectedRoute?.minTruckLoad);
    const [parkedLoad, setParkedLoad] = useState(selectedRoute?.parkedLoad);
    const [forecast, setForecast] = useState()
    const [openReplacementTruckModal, setOpenReplacementTruckModal] = useState(false)
    const [replacementTruck, setReplacementTruck] = useState()

    const handleReplacementTruckModalOk = () => {
        console.log(replacementTruck)
        if (replacementTruck && replacementTruck !== "NoTruck") { 
            updateRoutesForecast({
            variables: {
                input: {
                        id: forecast?.id,
                        vehicleId: replacementTruck
                    }
                }
            })
        } else {
            setOpenReplacementTruckModal(false)
            setForecast(undefined);
        }
    }

    const [getAvailableTrucks, { data: trucksData }] = useLazyQuery(GET_FORECAST_AVAILABLE_TRUCKS, { fetchPolicy: 'no-cache' });

    useEffect(() => {
        forecast && getAvailableTrucks({
            variables: {
                stationId: stationId,
                fromDate: forecast.departureTime,
                toDate: forecast.arrivalTime
            }
        })
    }, [forecast, getAvailableTrucks, stationId])

    const handleClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (selectedRoute) {
            getLabelsData({ variables: { stationId: selectedRoute?.stationId } })
        }
    }, [getLabelsData, selectedRoute])

    const handleOk = () => {
        updateRouteBase(
            {
                variables: {
                    input: {
                        id: selectedRoute?.id,
                        stationId: stationId || selectedRoute?.stationId,
                        opsDow: opsDow || opsDow === 0 ? opsDow : selectedRoute?.opsDow,
                        labelId: labelId || selectedRoute?.labelId,
                        departureDow: departureDow || departureDow === 0 ? departureDow : selectedRoute?.departureDow,
                        departureTime: departureTime || moment(`01 Jan 2000 ${selectedRoute?.departureTime}`).format(),
                        arrivalDow: arrivalDow || arrivalDow === 0 ? arrivalDow : selectedRoute?.arrivalDow,
                        arrivalTime: arrivalTime || moment(`01 Jan 2000 ${selectedRoute?.arrivalTime}`).format(),
                        vehicleType: vehicleType || selectedRoute?.vehicleType,
                        minTruckLoad: minTruckLoad !== undefined ? minTruckLoad : selectedRoute?.minTruckLoad, // one of the options is 0, so it will be evaluated as falsey and trigger the default value
                        parkedLoad: parkedLoad || selectedRoute?.parkedLoad
                    }
                }
            }
        );
        setVisible(false)
    };

    const handleButtonClicked = () => {
        setVisible(true);
        setStationId(selectedRoute?.stationId);
        setOpsDow(selectedRoute?.opsDow);
        setLabelId(selectedRoute?.labelId);
        setDepartureDow(selectedRoute?.departureDow);
        setDepartureTime(moment(selectedRoute?.departureTime, 'HH:mm'));
        setArrivalDow(selectedRoute?.arrivalDow);
        setArrivalTime(moment(selectedRoute?.arrivalTime, 'HH:mm'));
        setVehicleType(selectedRoute?.vehicleType);
        setMinTruckLoad(selectedRoute?.minTruckLoad);
        setParkedLoad(selectedRoute?.parkedLoad);
    };

    const initialValues = selectedRoute;

    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [form, initialValues])

    return <>
        <Button disabled={disabled} onClick={handleButtonClicked} icon={<EditOutlined/>}>編集</Button>
        <Modal
            title="固定ルートの編集"
            visible={visible}
            onOk={handleOk}
            onCancel={handleClose}
            destroyOnClose
            footer={null}
        >
            <Form
                name='request'
                form={form}
                initialValues={initialValues}
                onFinish={handleOk}
                layout='horizontal'
            >
                <Form.Item
                    label="営業所"
                    name='stationId'
                    rules={[{  message: '営業所を入力してください。' }]}
                >
                    <Select
                        showSearch
                        placeholder="選択してください"
                        defaultValue={initialValues?.stationId}
                        style={{ width: 200 }}
                        onChange={
                            (selectedId) => {
                                getLabelsData({ variables: { stationId: selectedId } })
                                setStationId(selectedId)
                            }
                        }
                    >
                        {
                            stationsData.stations.edges.map(
                                station => (
                                    <Select.Option key={station.node.id} value={station.node.id}>
                                        {station.node.officialName}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="曜日"
                    name='opsDow'
                >
                    <Select
                        defaultValue={initialValues?.opsDow}
                        onChange={setOpsDow}
                    >
                        {
                            Object.entries(dowDictionary).map(
                                entry => (
                                    <Select.Option key={entry[0]} value={Number(entry[0])}>
                                        {entry[1]}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="ルート名"
                    name='label'
                >
                    <Select
                        showSearch
						onChange={setLabelId}
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								|| option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
                    >
                    {
                        labelsData && labelsData.mostFrequentlyUsedLabels.map(
                            label => (
                                label.enabled &&
                                    <Select.Option value={label.id}>{label.label.join('|')}</Select.Option>
                            )
                        )
                    }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="出発時刻"
                    name='departure'
                >
                    <Space>
                        <Select
                            onChange={setDepartureDow}
                            defaultValue={initialValues?.departureDow}
                        >
                            {
                                Object.entries(dowDictionary).map(
                                    entry => (
                                        <Select.Option key={entry[0]} value={Number(entry[0])}>
                                            {entry[1]}
                                        </Select.Option>
                                    )
                                )
                            }
                        </Select>
                        <TimePicker onChange={setDepartureTime}
                            defaultValue={moment(initialValues?.departureTime, 'HH:mm')}
                            showTime={{ format: 'HH:mm' }} locale={locale}/>
                    </Space>
                </Form.Item>
                <Form.Item
                    label="到着時刻"
                    name='arrival'
                >
                    <Space>
                        <Select
                            defaultValue={initialValues?.arrivalDow}
                            onChange={setArrivalDow}
                        >
                            {
                                Object.entries(dowDictionary).map(
                                    entry => (
                                        <Select.Option key={entry[0]} value={Number(entry[0])}>
                                            {entry[1]}
                                        </Select.Option>
                                    )
                                )
                            }
                        </Select>
                        <TimePicker onChange={setArrivalTime}
                            defaultValue={moment(initialValues?.arrivalTime, 'HH:mm')}
                            showTime={{ format: 'HH:mm' }} locale={locale}/>
                    </Space>
                </Form.Item>
                <Form.Item
                    label="車種"
                    name='vehicleType'
                >
                    <Select onChange={setVehicleType}>
                        {
                            ['冷凍車', '冷蔵車'].map(
                                vehicleType => (
                                    <Select.Option key={vehicleType} value={vehicleType}>
                                        {vehicleType}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="重量（キロ）"
                    name='minTruckLoad'
                >
                    <Select onChange={setMinTruckLoad} >
                        {
                            Object.entries(minTruckLoadDictionary).map(
                                minTruckLoad => (
                                    <Select.Option key={minTruckLoad[1]} value={Number(minTruckLoad[1])}>
                                        {minTruckLoad[0]}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="荷有り"
                    name='parkedLoad'
                >
                    <Select onChange={setParkedLoad}>
                        {
                            ["空", "荷あり"].map(
                                parkedLoadType => (
                                    <Select.Option key={parkedLoadType} value={parkedLoadType}>
                                        {parkedLoadType}
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item shouldUpdate>
                    { () => (
                        <Button
                            type="primary"
                            htmlType='submit'
                        >
                            変更する
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Modal>
        <Modal // replacement truck modal
            title="代行車両"
            visible={openReplacementTruckModal}
            onOk={handleReplacementTruckModalOk}
            onCancel={e => setOpenReplacementTruckModal(false)}
            destroyOnClose
        >
            <Select style={{width: "10rem"}} onChange={v => setReplacementTruck(v)} defaultValue={"NoTruck"}>
                <Select.Option value={"NoTruck"}>NoTruck</Select.Option>
                {trucksData && trucksData.forecastAvailableTrucks?.map(truck => {
                    return <Select.Option value={truck.vehicleId}>{truck.vehicleId}</Select.Option>
                })}
            </Select>
        </Modal>
    </>

};

export default EditRouteBase;
