import encoding from 'encoding-japanese';

export const toHalfWidthKatakana = str => {
    return encoding.toHankakuCase(str)
};

export const toFullWidthKatakana = str => {
    return encoding.toZenkakuCase(encoding.toZenkanaCase(str))
};

export const compareFullHalfWidth = (abc, bc) => {
    return abc.toLowerCase().includes(bc.toLowerCase()) || 
            toHalfWidthKatakana(abc).toLowerCase().includes(bc.toLowerCase()) ||
            toFullWidthKatakana(abc).toLowerCase().includes(bc.toLowerCase());
};